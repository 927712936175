import { Component, OnInit } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService  } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-menu',
  templateUrl: './customMenu.component.html',

})
export class MenuComponent {
  panels: object;
  actionListItems: any;
  withdrawlTypes = ["PARTIAL_SURRENDER","FULL_SURRENDER","SYSTEMATIC_WITHDRAWL"];
  policyDetails: any;
  partialEnabled: any;
  fullEnabled: any;
  isSystematicWithdrawlAllowed: any = true;

  constructor() {
    this.panels = {
      "policyHistoryActions": [
        {
          "id": "createWorkRequest",
          "label": "History Option 1"
        },
        {
          "id": "editWorkRequest",
          "label": "History Option 2"
        },
      ],
      "workRequestHistoryActions": [
        {
          "id": "createWorkRequest",
          "label": "Work Request Option 1"
        },
        {
          "id": "editWorkRequest",
          "label": "Work Request Option 2"
        },
      ],
      "claimsActions": [
        {
          "id": "createDeathClaim",
          "label": "Create Death Claim",
          "enable": this.enabled
        }
      ],
      "policyDetailsActions": [
        {
          "id": "PARTIAL_SURRENDER",
          "label": "Partial Surrender",
          "enable": this.partialEnabled
        },
        {
          "id": "FULL_SURRENDER",
          "label": "Full Surrender",
          "enable":this.fullEnabled
        },
        {
          "id": "SYSTEMATIC_WITHDRAWL",
          "label": "Systematic Withdrawal",
          "enable":this.isSystematicWithdrawlAllowed
        }
      ]

    }
  }
  public enabled: boolean;
  onActionItemClick(action) {
    Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
      if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'createClaim') {
        headerObj.headers.role = null;
      }
  });
    if(action.id === "createDeathClaim"){
      OcInfraModule.AppInjector.get(CommonService).createClaim = false;
      OcInfraModule.AppInjector.get(CommonService).claimAlert = true;
      let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
      OcInfraModule.AppInjector.get(CommonService).optionCallback(policyDetails._links.claims.href, Configuration.config.headers.defaultHeader).subscribe(response => {
        OcInfraModule.AppInjector.get(CommonService).deathClaimProperties = response._options.properties;
        OcInfraModule.AppInjector.get(CommonService).deathClaimRequiredProperties = response._options.required;
        document.getElementById("create-claim-create-claim-item-anchor").click();
      });
    }
    if (this.withdrawlTypes.includes(action.id)) {
       OcInfraModule.AppInjector.get(CommonService).selectedWithdrawlSurrenderType = this.withdrawlTypes.find(actionType => actionType == action.id)
       document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
    }
  }

  onActionClick(event) {
    this.enabled = OcInfraModule.AppInjector.get(CommonService).disableTermandFia ? false : (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess);
    let selectedActionId = event.currentTarget.parentElement.id;
    this.actionListItems = this.panels[selectedActionId];
    if (selectedActionId == "policyDetailsActions") {
      this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails') ? OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails').response : null;
      let policyLinks = this.policyDetails._links;
      this.partialEnabled = this.fullEnabled = this.isSystematicWithdrawlAllowed = false;
      if (this.policyDetails && policyLinks && this.enabled) {
        this.partialEnabled = policyLinks.partialSurrender ? true : false;
        this.fullEnabled = policyLinks.fullSurrender ? true : false;
        this.isSystematicWithdrawlAllowed = policyLinks.systematicWithdrawal ? true : false;
      }
      this.actionListItems[0].enable = this.partialEnabled;
      this.actionListItems[1].enable = this.fullEnabled;
      this.actionListItems[2].enable = this.isSystematicWithdrawlAllowed;
    } else {
      for (let i = 0; i < this.actionListItems.length; i++) {
        this.actionListItems[i].enable = this.enabled;
      }
    }
  }
}
