import { Component, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { CommonService } from '../../services/common.service';
import { CommonFactory } from './../../factories/commonFactory';
import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { Router } from '@angular/router';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'abob-tabe',
  styleUrls: ['./agentBookOfBusinessComponent.scss'],
  templateUrl: './agentBookOfBusinessComponent.html',
})
export class AgentBookOfBusinessTableComponent extends CommonFactory {
  displayedColumnsAnnuity = ['ownerName', 'policyNumber', 'productCode', 'policyStatus'];
  displayedColumnsLifeDetails = ['ownerName', 'policyNumber', 'productCode', 'policyStatus'];
  aobAnnuityDataSource: MatTableDataSource<AobAnnuity>;
  aobAnnuityTotalCount: number = 0;
  aobIulTotalCount: number = 0;
  aobAnnuityDataSourceTotalCount: number = 0;
  aobAnnuityPaginationAPIs: string[]= [];
  aobLifeDetailsDataSource: MatTableDataSource<AobLifeDetails>;
  aobLifeDetailsDataSourceTotalCount: number = 0;
  aobLifeDetailsPaginationAPIs: string[] = [];
  aobAnnuityCount: any = 0;
  aobLifeDetailsCount: any = 0;
  activeTabIndex: any = 0;
  public aobAnnuityFilter: string = null;
  public aobLifeDetailsFilter: string = null;
  lifeFilterValue: any;
  lifeFilterValueSearch: any;
  annuityFilterValue: any;
  annuityFilterValueSearch: any;
  annuityData: any = {};
  lifeData: any = {};
  lodash: any;
  disableAobAnnuityNext: boolean = true;
  disableAobAnnuityPrev: boolean = true;
  disableAobLifeDetailsNext: boolean = true;
  disableAobLifeDetailsPrev: boolean = true;
  headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
  agent_details: boolean = false;
  policy_details: boolean = false;
  annuityPaginator :MatPaginator
  lifePaginator: MatPaginator
  bobAlertMsg = [];
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.annuityPaginator = mp;
    this.lifePaginator = mp;
    this.setDataSourceAttributes();
  }

  // @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //   this.lifePaginator = mp;
  //   this.setDataSourceAttributes();
  // }
  // @ViewChild('annuityPaginator') annuityPaginator: MatPaginator;
  // @ViewChild('lifePaginator') lifePaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public commonService: CommonService, public elementRef: ElementRef, private router: Router) {
    super();
   }
  totalLength: number = 0;
  lifeTotalLength: number = 0;
  pageLimit: number[] = [5, 10, 15, 20, 25];
  lifePageLimit: number[] = [5, 10, 15, 20, 25];
  annuityPageSize: number = 5;
 

  ngOnInit() {
    OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = false;
    this.lodash = _;
    if(this.commonService && this.commonService.selectedAgentSummary &&this.commonService.selectedAgentSummary.links){
    let aobAnnuityUrl = _.filter(this.commonService.selectedAgentSummary.links, function (o) { return o.rel == "agent_bookofbusiness_annuity" });
    let aobLifeUrl = _.filter(this.commonService.selectedAgentSummary.links, function (o) { return o.rel == "agent_bookofbusiness_iul" });
    this.getAobLifeDetails(aobLifeUrl[0].href, true);
    this.getAobAnnuity(aobAnnuityUrl[0].href, true);
    }
    this.agent_details = this.elementRef.nativeElement.id == 'callBoard'?true:false;
    this.policy_details = this.elementRef.nativeElement.id == 'aob'?true:false;
    if(this.policy_details){
      this.displayedColumnsAnnuity = ['ownerName', 'policyNumber', 'productCode'];
      this.displayedColumnsLifeDetails = ['ownerName', 'policyNumber', 'productCode'];
    }
  }

  ngAfterViewInit() {
  // !this.aobAnnuityDataSource.paginator ? this.aobAnnuityDataSource.paginator = this.annuityPaginator : null;
  //  !this.aobLifeDetailsDataSource.paginator ? this.aobLifeDetailsDataSource.paginator = this.lifePaginator : null;
    // this.aobAnnuityDataSource.paginator = this.annuityPaginator;
    // this.aobLifeDetailsDataSource.paginator = this.lifePaginator;
  }

  updateFilterValue(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    if (this.activeTabIndex == 0) {
      this.annuityFilterValue = filterValue;
    } else {
      this.lifeFilterValue = filterValue;
    }
  }

  getSearchQueryUrl(url, filterValue) {
    const completeURL = new URL(url);
    const urlParams = new URLSearchParams(completeURL.search);
    urlParams.set('_start', '0');
    urlParams.set('_query',filterValue)
    return `${completeURL.origin}${completeURL.pathname}?${urlParams.toString()}`;
  }

  applyFilter(){
    this.activeTabIndex == 0
      ? this.getAobAnnuity(this.getSearchQueryUrl(this.annuityData._links.self.href, this.annuityFilterValue.trim()), true)
      : this.getAobLifeDetails(this.getSearchQueryUrl(this.lifeData._links.self.href, this.lifeFilterValue.trim()), true);
    this.aobAnnuityPaginationAPIs.length = 0;
    this.aobLifeDetailsPaginationAPIs.length = 0;
  }

  selectedIndexChange(indexNumber) {
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          !this.aobAnnuityDataSource.paginator ? this.aobAnnuityDataSource.paginator = this.annuityPaginator : null;
          break;
        case 1:
          !this.aobLifeDetailsDataSource.paginator ? this.aobLifeDetailsDataSource.paginator = this.lifePaginator : null;
      }
    });
    this.activeTabIndex = indexNumber;
    this.activeTabIndex ? this.aobLifeDetailsFilter = this.aobLifeDetailsFilter : this.aobAnnuityFilter = this.annuityFilterValue

  }

  getWorkRequestHistoryAgent(workHref) {
    let optionParams = new OptionParams();
    optionParams.alias = 'workRequestHistory';
    let url = workHref.split('?')[0];
    let paramsObj = [];
    let params = workHref.split('?')[1].split('&');
    for (let i = 0; params.length > i; i++) {
      paramsObj.push({ 'key': params[i].split('=')[0], 'value': params[i].split('=')[1] })
    }
    OcInfraModule.AppInjector.get(CommonService).instanceParams = paramsObj;
    optionParams.params = paramsObj;
    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("reqDetails");
    OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, 'Reset').subscribe(response => {
    }, error => {
      console.log("error", error)
    });
  }

  getAobAnnuity(url, onInit) {
    OcInfraModule.AppInjector.get(CommonService).isOlsAnnuity = true;
    OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(aobAnnuityResponse => {
      this.annuityData = aobAnnuityResponse;
      let annuityDataSource = [];
      onInit && this.getWorkRequestHistoryAgent(aobAnnuityResponse._links.instances.href);
      this.aobAnnuityTotalCount = aobAnnuityResponse._embedded.total_count;
      for (let i = 0; i < aobAnnuityResponse._links.item.length; i++) {
        annuityDataSource.push(aobAnnuityResponse._links.item[i].summary)
      }
      if(onInit) {
        this.aobAnnuityDataSource = new MatTableDataSource(aobAnnuityResponse._links.item);
        this.annuityFilterValueSearch = this.annuityFilterValue;
        this.aobAnnuityDataSourceTotalCount = aobAnnuityResponse._links.item.length;
      } else {
        this.aobAnnuityDataSource = new MatTableDataSource([...this.aobAnnuityDataSource.data, ...aobAnnuityResponse._links.item]);
        this.aobAnnuityDataSourceTotalCount += aobAnnuityResponse._links.item.length;
        this.aobAnnuityDataSource._updateChangeSubscription();
      }
      this.aobAnnuityCount = aobAnnuityResponse._links.item.length; 
      // this.disableAobAnnuityNext = aobAnnuityResponse && aobAnnuityResponse._links && aobAnnuityResponse._links.next ? !Object.keys(aobAnnuityResponse._links.next) : true;
      // this.disableAobAnnuityPrev = aobAnnuityResponse && aobAnnuityResponse._links && aobAnnuityResponse._links.prev ? !Object.keys(aobAnnuityResponse._links.prev) : true;
      this.totalLength = this.aobAnnuityCount;
      //this.setDataSourceAttributes();
      //this.aobAnnuityDataSource.paginator = this.annuityPaginator;
     this.aobAnnuityDataSource.sort = this.sort;
      this.aobAnnuityDataSource.filterPredicate = this.filterPredicate;
    })
  }

  getAobLifeDetails(url, onInit) {
    OcInfraModule.AppInjector.get(CommonService).isOlsIul = true;
    OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(aobLifeDetailsResponse => {
      this.lifeData = aobLifeDetailsResponse;
      this.aobIulTotalCount= aobLifeDetailsResponse._embedded.total_count;
      this.aobLifeDetailsCount = aobLifeDetailsResponse._links.item.length;
      let lifeDetails = [];
      for (let i = 0; i < aobLifeDetailsResponse._links.item.length; i++) {
        lifeDetails.push(aobLifeDetailsResponse._links.item[i].summary);
      }
      if(onInit) {
        this.aobLifeDetailsDataSource = new MatTableDataSource(aobLifeDetailsResponse._links.item);
        this.lifeFilterValueSearch = this.lifeFilterValue;
        this.aobLifeDetailsDataSourceTotalCount = aobLifeDetailsResponse._links.item.length;
      } else {
        this.aobLifeDetailsDataSource = new MatTableDataSource([...this.aobLifeDetailsDataSource.data, ...aobLifeDetailsResponse._links.item]);
        this.aobLifeDetailsDataSourceTotalCount += aobLifeDetailsResponse._links.item.length;
        this.aobLifeDetailsDataSource._updateChangeSubscription();
      }
      // this.disableAobLifeDetailsNext = this.lifeData && this.lifeData._links && this.lifeData._links.next ? !Object.keys(aobLifeDetailsResponse._links.next) : true;
      // this.disableAobLifeDetailsPrev = this.lifeData && this.lifeData._links && this.lifeData._links.prev ? !Object.keys(aobLifeDetailsResponse._links.prev) : true;
      this.lifeTotalLength = this.aobLifeDetailsCount;
    //  this.aobLifeDetailsDataSource.paginator = this.lifePaginator;
     this.aobLifeDetailsDataSource.sort = this.sort;
     this.aobLifeDetailsDataSource.filterPredicate = this.filterPredicate;
    })
  }

  filterPredicate (data: any, filter: string) {
    let totalVal = '';
    Object.keys(data.summary).forEach((item)=>{
      if (typeof data.summary[item] === 'string') {
        totalVal += data.summary[item].toLowerCase();
      }
    });
    return !filter || !!~totalVal.indexOf(filter.toLowerCase());
  }

  openOlsPolicy(params) {
    //OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
    //OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();

    OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
    OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
    
    params.summary && params.summary.notSupportedProductMSg && params.summary.notSupportedProductMSg != '' ? this.agentAlertbanner(params.summary.notSupportedProductMSg): '';
    
    document.getElementById("modal__ok") && document.getElementById("modal__ok").click();     
    OcInfraModule.AppInjector.get(CommonService).selectedBOBPolicy = params;
    const optionParams = new OptionParams();
    optionParams.alias = "policyDetails";
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const resourceSchemaService = OcInfraModule.AppInjector.get(ResourceSchemaService);
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'Reset').subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).agentBanner = false;
      (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) ? document.getElementById("bob-open-modal-icon-open-bob-agent-banner-anchor").click() : document.getElementById("bob-open-modal-icon-open-bob-agent-anchor").click();
      this.expandOnauth();
    }, error => (console.log("error",error)));
    const selectedBOBSchema = resourceService.getAliasByAliasName("policyDetails");
  }
  expandOnauth(){
    setTimeout(()=>{
      if((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)){
          var element =  document.getElementById('bob-open-modal-icon-open-bob-agent-anchor');
            if (typeof(element) != 'undefined' && element != null)
            {
              Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                if(!accordian["classList"].contains("mat-expanded")){
                    document.getElementById(accordian.id).click();
                }
            });
          }
        }
    },6000);
  }
  agentAlertbanner(msg) {
    let status_report = {
      "showAll":true,
      "consistent": true,
      "messages": [
        {
          "message": msg,
          "severity": "ALERT"
        }
      ],
      url: this.annuityData._links.instances.href,
      alias: "agentDetails",
    }

    let agentAlert = setInterval(function () {
            
      if (OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert) {
        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
          clearInterval(agentAlert);
      }
  }, 500);
    
  }

  setDataSourceAttributes(){
    // this.aobAnnuityDataSource.paginator = this.annuityPaginator;
  //  this.aobLifeDetailsDataSource.paginator = this.lifePaginator;

    // this.aobAnnuityDataSource.sort = this.sort;
    if (this.aobAnnuityDataSource) {
      this.aobAnnuityDataSource.filterPredicate = this.filterPredicate;
    }
    //this.aobLifeDetailsDataSource.sort = this.sort;
    // this.aobLifeDetailsDataSource.filterPredicate = this.filterPredicate;

  }

  onTableScroll(e, tableMode) {
    const tableViewHeight = e.target.offsetHeight
    const tableScrollHeight = e.target.scrollHeight
    const scrollLocation = e.target.scrollTop;
    const buffer = 150;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if(tableMode === 'annuity') {
      if (scrollLocation > limit && this.aobAnnuityDataSource.data.length <= this.aobAnnuityTotalCount) {
        if(!~this.aobAnnuityPaginationAPIs.indexOf(this.annuityData._links.next.href)) {
          this.aobAnnuityPaginationAPIs.push(this.annuityData._links.next.href);
          this.getAobAnnuity(this.annuityData._links.next.href, false);
        }
      }
    }
    if(tableMode === 'lifeinsurance') {
      if (scrollLocation > limit && this.aobLifeDetailsDataSource.data.length <= this.aobIulTotalCount) {
        if(!~this.aobLifeDetailsPaginationAPIs.indexOf(this.lifeData._links.next.href)) {
          this.aobLifeDetailsPaginationAPIs.push(this.lifeData._links.next.href);
          this.getAobLifeDetails(this.lifeData._links.next.href, false);
        }
      }
    }
    
  }
}

export interface AobAnnuity {
  ownerName: string;
  policyNumber: string;
  productCode: string;
  policyStatus: string;
}

export interface AobLifeDetails {
  ownerName: string;
  policyNumber: string;
  productCode: string;
  policyStatus: string;
}

