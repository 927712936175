import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/do';
import { map, tap, catchError, finalize } from "rxjs/operators";
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../services/common.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    private count = 0;
    private totalRequests = 0;
    private requests: HttpRequest<any>[] = [];
    constructor(private loaderService: CommonService) { }
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(request);
        next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                this.requests.push(request);
                if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {
                    event.body._embedded.status_report.url = event.url;
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);
                }
            }));
        return Observable.create(observer => {
            const subscription = next.handle(request)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {
                            event.body._embedded.status_report.url = event.url;
                            OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);
                        }
                        if (event instanceof HttpResponse) {
                            this.removeRequest(request);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(request);
                        observer.error(err);
                        err.error && err.error._embedded && err.error._embedded.status_report && err.error._embedded.status_report.messages.length && err.error._embedded.status_report.messages[0].severity == "ERROR" ? err.status == 404 ? null : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(err.error._embedded.status_report.messages[0].message, 'Error', 0) : null;
                    },
                    () => {
                        this.removeRequest(request);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(request);
                subscription.unsubscribe();
            };
        });
        // if(request.method=="GET"){
        //   OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false})
        // }
        // if(request.method=="POST" || request.method=="PUT"){
        //     OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true})
        //   }  
        //   return next.handle(request).pipe(
        //         tap((event: HttpEvent<any>) => {         
        //             this.requests.push(request);

        //             //this.loaderService.isLoading.next(true);
        //             if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {    
        //                 event.body._embedded.status_report.url = event.url;
        //     //            this.removeRequest(request);
        //              //   this.loaderService.isLoading.next(false);

        //              OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);                 

        //             }

        //         }),
        //         catchError(err => {
        //             if (err instanceof HttpErrorResponse) {
        //                 //console.log('Caught error', err);
        //             }         
        //             return throwError(err);
        //         }),finalize(() => {
        //             OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        //         })
        //     );
    }
    // private decreaseRequests() {
    //     this.totalRequests--;
    //     if (this.totalRequests === 0) {
    //       this.loaderService.isLoading.next(false);
    //     }
    //   }
}
