import { Directive, ElementRef, HostListener } from '@angular/core';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { DecimalPipe} from '@angular/common';

@Directive({
  selector: '[TwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirective {
 
  
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
   private value1 : any = true;
  constructor(private el: ElementRef , private decimalPipe : DecimalPipe) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent)
   {
    OcInfraModule.AppInjector.get(CommonService).otherKey = false;
    

    if (this.specialKeys.indexOf(event.key) !== -1 && this.specialKeys.indexOf(event.key) !== 1 )
       {
      OcInfraModule.AppInjector.get(CommonService).oAmount =  this.el.nativeElement.value.replace(/,/g,'');
      OcInfraModule.AppInjector.get(CommonService).otherKey = true;
      return;
      } 
    else 
     { if(this.specialKeys.indexOf(event.key) === 1 ){
        this.el.nativeElement.value  = this.el.nativeElement.value.replace(/,/g,'')
        
        this.tranformInput()
         }
      }
  }
  
   tranformInput()
   {
    this.value1=this.el.nativeElement.value;
    this.el.nativeElement.value =this.decimalPipe.transform(this.value1,'.2');
    OcInfraModule.AppInjector.get(CommonService).oAmount = this.el.nativeElement.value.replace(/,/g,'');
  }
}