import { MatSelect } from '@angular/material';
import { Select, OcInfraModule, ResourceService } from '@diaas/ux-web';

export class CustomSelect extends Select {
    viewVaule: any;

    constructor(input: Select) {
        super(input);
    }

    public selected(event: any): void {
        const resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName(this.alias);
        if (
          resource &&
          resource['_options'] &&
          resource['_options']['properties'] &&
          resource['_options']['properties'] [this.id] &&
          (resource['_options']['properties'] [this.id].type === 'integer' ||
            resource['_options']['properties'] [this.id].type === 'number')
        ) {
          this.value = Number(event.source.value);
        } else {
          this.value = event.source.value;
          //setting viewvalue in field
          this.viewValue = event.source.selected.viewValue;
        }

        if(this.class.includes("resetAfterSelect")){
            const matSelect: MatSelect = event.source;
            matSelect.writeValue(null);
        }
      }

}