import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'search-highlight',
  styleUrls: ['./searchHighlight.component.scss'],
  templateUrl: './searchHighlight.component.html'
})
export class SearchHighlightComponent implements OnChanges {
  @Input() query: string;
  @Input() renderValue: string;
  public searchedValue: boolean = false;
  public highlightList: string[] = [];

  ngOnChanges() {
    let resp = [];
    let searchQuery = this.query && this.query.trim().toLowerCase();
    const renderValue = this.renderValue && this.renderValue.toLowerCase();
    let multiSearch = this.query && this.query.trim().split(" ");
    if (multiSearch) {
    	for (let i=0; i<multiSearch.length; i++) {
      	let array = renderValue.split(" ");
      	let myString = '';
      	myString = multiSearch[i].indexOf('*') ? multiSearch[i].substr(0, multiSearch[i].length - 1) : multiSearch[i];
      	let val = [];
      	val = myString && myString !== '' && array.filter(e => {
        	return e.startsWith(myString);
      	})
	      	
	myString && myString !== '' ? searchQuery = val[0] : '';
      	this.searchedValue = searchQuery && searchQuery.length && !!~renderValue.indexOf(searchQuery);
      	this.searchedValue && renderValue.split(searchQuery).forEach((item, i) => {
        	resp.push(item);
        	resp.push(searchQuery);
      	});
      	
      	resp.pop();
      	this.highlightList = resp;
      	if (this.searchedValue == true) {
        	break;
      	}
      }
    }
  }
}