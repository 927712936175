import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import {Sort} from '@angular/material';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';
import * as _ from 'lodash';

export interface LoanData {
  effective_date:Date;
  transaction_value: number;
  loan_interest_rate:number;
}
@Component({
  selector: 'loan-history-table',
  templateUrl: './loanHistoryTable.component.html',
})

export class LoanHistoryTableComponent implements OnInit {
  displayedColumns: string[] = ['effective_date', 'loan_interest_rate', 'capitalization', 'initiation'];
  selectedDate: Object = {};
  noRecords: boolean = true;
  loanInterestRate: string = '';
  loanData: LoanData[];
  olsPolicyDetails: boolean = false;
  csrPolicyDetails: boolean = false;

  constructor(public elementRef: ElementRef){

  }

  ngOnInit() {
    this.olsPolicyDetails = this.elementRef.nativeElement.id == 'olsLoanHistoryTable'?true:false;
    this.csrPolicyDetails = this.elementRef.nativeElement.id == 'loanHistoryTable'?true:false;
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (loanHistoryURL["loans/loantransactionhistory"] && loanHistoryURL["loans/loantransactionhistory"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanTransactionHistory");
      OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["loans/loantransactionhistory"].href).subscribe(response => {
       OcInfraModule.AppInjector.get(CommonService).loanInterestRate = response.response.loan_interest_rate;
        if(response.response && response.response.loans_transaction_history){
          response.response.loans_transaction_history.forEach(headerObj => {
          headerObj.effective_date = new Date(headerObj.effective_date);
          let intreratRate = _.pickBy(response.response, function (value, key) {
            return key == 'loan_interest_rate';
          });
          Object.assign(headerObj, intreratRate)
        });
        this.loanData = response.response.loans_transaction_history;
        this.sortedData = response.response._embedded ? [] : response.response.loans_transaction_history;
        this.noRecords = !this.sortedData || !this.sortedData.length;
        this.sortData({ active: "effective_date", direction: "dsc" });
      }
      this.noRecords = !this.sortedData || !this.sortedData.length;
      });
    }
  }

  testChange(event, data, selectedValue, selectedKey, columnName) {
    let apiName = "loans/loantransactionhistory";
    let loanInfo: any;
    loanInfo = localStorage.getItem('loanInfo');
    loanInfo == null ? this.selectedDate = {} : this.selectedDate = JSON.parse(loanInfo);
    let date = new Date(data.effective_date);
    let objID = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + 'v';
    let effectiveDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    selectedValue = selectedKey == 'Capitalization' || selectedKey == 'Initiation' ? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
    if (event.checked) {
      let newObj: boolean = false;
      this.selectedDate[objID] ? this.selectedDate[objID][selectedKey] = selectedValue : newObj = true;;
      if (newObj) {
        this.selectedDate[objID] = JSON.parse("{}");
        this.selectedDate[objID]["Effective Date"] = effectiveDate;
        this.selectedDate[objID][selectedKey] = selectedValue ? selectedValue : 'No data in admin system.';
      }
    } else {
      delete this.selectedDate[objID][selectedKey]
      _.size(this.selectedDate[objID]) > 1 ? '' : delete this.selectedDate[objID];
    }
    let html = '';
    Object.keys(this.selectedDate).forEach(keys => {
      Object.keys(this.selectedDate[keys]).forEach(key => {
        html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectedDate[keys][key] + '</dd>';
      })
    })
    // OcInfraModule.AppInjector.get(CommonService).checkFormatCustomComponent(event, selectedKey, apiName, columnName);
    // let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    // if (this.selectedDate[effectiveDate]) {
    //   event.checked ? this.selectedDate[effectiveDate].push(selectedKey + ":" + selectedValue) : this.selectedDate[effectiveDate].splice(this.selectedDate[effectiveDate].indexOf(selectedKey + ':' + selectedValue), 1);
    // } else {
    //   this.selectedDate[effectiveDate] = new Array(selectedKey + ':' + selectedValue);
    // }
    // if (this.selectedDate[effectiveDate].length == 1 && this.selectedDate[effectiveDate][0].indexOf('Effective Date') !== -1) {
    //   delete this.selectedDate[effectiveDate];
    // } else {
    //   if (this.selectedDate[effectiveDate][0].indexOf('Effective Date') === -1) {
    //     let date = new Date(data.effective_date);
    //     this.selectedDate[effectiveDate].unshift("Effective Date:" + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear());
    //   }
    // }
    // let html = '';
    // Object.keys(this.selectedDate).forEach(key => {
    //   this.selectedDate[key].forEach(element => {
    //     if (currencyFields.includes(element.split(":")[0])) {
    //       html += '<dl><dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd>&nbsp;'+ OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd><dl>'
    //     } else {
    //       html += '<dl><dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd>&nbsp;'+element.split(":")[1] + '</dd><dl>'
    //     }
    //   });

    // });
    localStorage.setItem('loanInfo', JSON.stringify(this.selectedDate));
    OcInfraModule.AppInjector.get(CommonService).loanHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
  }

  sortedData: LoanData[];
  sortData(sort) {
    sort.direction == '' ? sort.direction = 'asc' :'';
    const data = this.loanData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return 0;
    }
    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'effective_date': return compare(a.effective_date, b.effective_date, isAsc);
        case 'transaction_value': return compare(a.transaction_value, b.transaction_value, isAsc);
        default: return sort.active;
      }
    });
  }
}

function compare(a: number | Date | string, b: number | Date | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
