import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService } from '@diaas/ux-web';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';


export interface dropdown {
  id: string;
  vewValue: string;
}


@Component({
  selector: 'app-wizard',
  templateUrl: './customWizard.component.html',
  styleUrls: ['customWizard.component.scss'],

})

export class WizardComponent implements OnInit,  AfterViewInit {
  elementData :any;
  spousevalue : string = "";
  isInsured : string = "";
  
  deathClaimRegistrationForm: FormGroup;
  authorizationCheck: boolean = false;
  isDisabled :boolean = true;
 constructor(private _formBuilder: FormBuilder, private element:ElementRef) {
   this.elementData = element.nativeElement;
   element.nativeElement.id == "openClaimDetailes" ? this.isDisabled = true : this.isDisabled = false;
  }
 
  isLinear = true;
  message:string;
  createDeathClaim: FormGroup;
  maxDate = new Date();

  get formArray(): AbstractControl | null { return this.createDeathClaim.get('formArray'); }

  public requiredBenficiary = false;
  public disableField :boolean = true;
  public childernRequired :boolean = true;
  
  public act = OcInfraModule.AppInjector.get(CommonService).deathClaimProperties;
  
  public requiredProperties = OcInfraModule.AppInjector.get(CommonService).deathClaimRequiredProperties;

  claimType: dropdown[] =  this.act.claimType.oneOf   ;
  notificationMethod: dropdown[] = this.act.notificationMethod.oneOf  ;
  deceasedInsuredType: dropdown[] =  this.act.deceasedInsuredType.oneOf  ;
  deceasedParty: dropdown[] =  this.act.deceasedParty ? this.act.deceasedParty.oneOf :'';
  mannerOfDeath: dropdown[] =  this.act.mannerOfDeath.oneOf  ;
  countryOfDeath: dropdown[] =  this.act.countryOfDeath.oneOf  ;
  notifierRelationship: dropdown[] =  this.act.notifierRelationship.oneOf ;
  notifierCountry: dropdown[] =  this.act.notifierCountry.oneOf  ;
  notifierCity: dropdown[] =  this.act.notifierCity.oneOf  ;
  notifierState: dropdown[] =  this.act.notifierState.oneOf  ;
  recipientRelationship: dropdown[] = this.act.recipientRelationship.oneOf  ;
  recipientCountry: dropdown[] = this.act.recipientCountry.oneOf  ;
  recipientCity: dropdown[] =  this.act.recipientCity.oneOf  ;
  recipientState: dropdown[] =  this.act.recipientState.oneOf  ;
  maritalStatus: dropdown[] =  this.act.maritalStatus.oneOf  ;
 // primaryInsured: dropdown[] =  this.act.PrimaryInsured.oneOf  ;
  beneficiaryKnown: dropdown[]=  this.act.beneficiaryKnown.oneOf  ;
  estateProbated:dropdown[]=  this.act.estateProbated.oneOf  ;
  spouseLiving:dropdown[]= this.act.spouseLiving.oneOf  ;
  poeClaim:dropdown[]=  this.act.poeClaim.oneOf  ;
  payload : any;
  roleInfo : any;

  maritalStatusChange(e){
    if(e.value == "Unknown" || e.value =="Single" || e.value == undefined){
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseLiving"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseName"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseDateOfDeath"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseLiving").disable();
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseName").disable();
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseDateOfDeath").disable();
      this.disableField=true;
      //this.childernRequired = true;
    }else{
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseLiving").enable();
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseName").enable();
      this.createDeathClaim.controls.formArray["controls"][4].get("spouseDateOfDeath").enable();
      this.disableField=false;
      //this.childernRequired = true;
    }
  }

  insuredInfo(e) {
    let role;
    let rolesArray = ["Owner", "Joint Owner", "Participant", "Joint Participant", "Insured", "Joint Insured", "Annuitant", "Joint Annuitant"]
   if(e == undefined){
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedFirstName"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedLastName"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedGovtID"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedDOB"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedInsuredType"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedInsuredType").enable();
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedFirstName").enable();
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedLastName").enable();
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedGovtID").enable();
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedDOB").enable();
      this.createDeathClaim.controls.formArray["controls"][1].get("deceasedInsuredType").enable();
   }
   else{
    this.roleInfo = _.filter(this.deceasedParty, function (o) { return o['enum'][0] == e });
    Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
      if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'person') {
        headerObj.headers.remote_user = null;
        headerObj.headers.role = null;
      }
    });
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'person' });
    OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let roles = resourceService.getResourceFromAliasName('partyRoles');
    let annuity = OcInfraModule.AppInjector.get(CommonService).isAnnuity;
    role = annuity ? this.roleInfo[0].tooltip == 'Annuitant' ? 'PAR' : this.roleInfo[0].tooltip : this.roleInfo[0].tooltip;
    let selectedRoleHref = _.filter(roles._links.item, function (o) { return o.summary.role_name_desc == role; });
    OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
    OcInfraModule.AppInjector.get(APICallerService).get(selectedRoleHref[0].href).subscribe(response => {
      OcInfraModule.AppInjector.get(APICallerService).get(response.response._links.person.href).subscribe(response => {
        if(rolesArray.includes(this.roleInfo[0].tooltip)){
          this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedInsuredType"].setValue("Base");
          this.createDeathClaim.controls.formArray["controls"][1].get("deceasedInsuredType").disable();
          
        }else{
          this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedInsuredType"].setValue("");
          this.createDeathClaim.controls.formArray["controls"][1].get("deceasedInsuredType").enable();
        }
        this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedFirstName"].setValue(response.response.first_name);
        this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedLastName"].setValue(response.response.last_name);
        this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedGovtID"].setValue(response.response.ssn.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3'));
        this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedDOB"].setValue(new Date(response.response.date_of_birth));
        this.createDeathClaim.controls.formArray["controls"][1].get("deceasedFirstName").disable();
        this.createDeathClaim.controls.formArray["controls"][1].get("deceasedLastName").disable();
      });
    }),
      error => {
        this.handleErrors(error);
      }
    // } else {  
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedFirstName"].setValue("");
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedLastName"].setValue("");
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedGovtID"].setValue("");
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedDOB"].setValue("");
    //   this.createDeathClaim.controls.formArray["controls"][1].get("deceasedFirstName").enable();
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedLastName"].enable();
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedGovtID"].enable();
    //   this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedDOB"].enable();
    // }
  }
  }

 chnageBenficiaryField(e){
if(e.checked == true){
this.requiredBenficiary = true;
}else{
  this.requiredBenficiary = false;
  this.createDeathClaim.controls.formArray["controls"][4].controls["beneficiaryName"].setValue("");
}

 }
  onSubmit() {
    let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'claimList' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const authResponse = resourceService.getResourceFromAliasName('authRes');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
    let arr = this.createDeathClaim.getRawValue().formArray;
    this.payload = arr.reduce(function (result, currentObject) {
      for (var key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          result[key] = currentObject[key];
        }
      }
      return result;
    }, {});
    Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
      if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'createClaim') {
          headerObj.headers.role = null;
      } 
  });
    this.payload["type"] = "Claims Notification";
    this.payload["hostKey"] = selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '';
    this.payload["sessionId"] = authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '';
    this.payload["callStart"] = authResponse && authResponse.data.callStart ? authResponse.data.callStart : '';
    this.payload["clientId"] = selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '';
    this.payload["userId"] = OcInfraModule.AppInjector.get(CommonService).incontactId;
    this.payload["remote_user"] = workRequestheaders[0].headers.remote_user;
    OcInfraModule.AppInjector.get(CommonService).postCallback(policyDetails._links.claims.href, this.payload, header[0].headers).subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).createClaim = true;
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Death claim created sucessfully', 'Success');
     // setTimeout(() => {
        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers }).subscribe(refreshResponse => { });
    //  }, 5000);
      document.getElementById("modal__cross").click();
      // setTimeout(function () {
      //   OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
      // }, 1000);
      OcInfraModule.AppInjector.get(APICallerService).refresh(policyDetails._links.claims.href, { 'alias': "claimList", 'headers':claimListHeader[0].headers}).subscribe(refreshResponse => {});
    },
      error => {
        this.handleErrors(error);
      });
  }
  handleErrors(error) {
    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error.message, 'Error', 0);
  }
  numberOnly(e): boolean {
     {
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();          
     }else{
         e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
        return true;
       }        
     }
  }
  
  ssnFormat(e): boolean {
    {
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();
      } else {
        let str = new String(e.target["value"]);
        str = str.replace(/[A-Za-z$-()\.]/g, '');
        str = str.replace(/[^0-9]/g, '');
        str = str.replace(/[^\d]/g, '');
        e.target["value"] = str.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3');
        return true;
      }
    }
  }

  zipFormat(e): boolean {
    {
     if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
       e.preventDefault();          
    }else{
        e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
       return true;
      }        
    }
 }
 
  testChange(event) {
    let notifierFields = this.createDeathClaim.value.formArray[2];
    if (event.checked) {
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientFirstName"].setValue(notifierFields.notifierFirstName);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLastName"].setValue(notifierFields.notifierLastName);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientPhone"].setValue(notifierFields.notifierPhone);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLine1"].setValue(notifierFields.notifierLine1);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCountry"].setValue(notifierFields.notifierCountry);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCity"].setValue(notifierFields.notifierCity);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientState"].setValue(notifierFields.notifierState);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientZip"].setValue(notifierFields.notifierZip);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientRelationship"].setValue(notifierFields.notifierRelationship);
    } else {
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientFirstName"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLastName"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientPhone"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLine1"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCountry"].setValue("USA");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCity"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientState"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientZip"].setValue("");
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientRelationship"].setValue("");
    }
  }
  
  ngOnInit() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    if(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess){
      this.message ="Enable button by completing required items."
       this.authorizationCheck = true;
    }else{
      this.authorizationCheck = false;
      this.message ="Caller not authorized."
    }
    this.createDeathClaim = this._formBuilder.group({
      formArray: this._formBuilder.array([
        this._formBuilder.group({
          notificationMethod: [{value:'',disabled: this.isDisabled}],
          claimType: [{value:'Death Claim',disabled: this.isDisabled}],
        }),
        this._formBuilder.group({
          deceasedParty: [{value:'',disabled: this.isDisabled}],
          deceasedFirstName: [{value:'',disabled: this.isDisabled}],
          deceasedMiddleName: [{value:'',disabled: this.isDisabled}],
          deceasedLastName: [{value:'',disabled: this.isDisabled}],
          deceasedInsuredType: [{value:'',disabled: this.isDisabled}],
          deceasedGovtID: [{value:'',disabled: this.isDisabled}],
          deceasedDOB: [{value:'',disabled: this.isDisabled}],
          dateOfDeath: [{value:'',disabled: this.isDisabled}],
          mannerOfDeath: [{value:'',disabled: this.isDisabled}],
          countryOfDeath: [{value:'USA',disabled: this.isDisabled}],
          deceasedAdditionalPolicies: [{value:'',disabled: this.isDisabled}],
        }),
        this._formBuilder.group({
          notifierFirstName: [{value:'',disabled: this.isDisabled}],
          notifierLastName: [{value:'',disabled: this.isDisabled}],
          notifierRelationship: [{value:'',disabled: this.isDisabled}],
          notifierOther: [{value:'',disabled: this.isDisabled}],
          notifierFuneralCompanyName: [{value:'',disabled: this.isDisabled}],
          notifierPhone: [{value:'',disabled: this.isDisabled}],
          notifierAltPhone: [{value:'',disabled: this.isDisabled}],
          notifierFax: [{value:'',disabled: this.isDisabled}],
          notifierLine1: [{value:'',disabled: this.isDisabled}],
          notifierLine2: [{value:'',disabled: this.isDisabled}],
          notifierLine3: [{value:'',disabled: this.isDisabled}],
          notifierCountry: [{value:'USA',disabled: this.isDisabled}],
          notifierCity: [{value:'',disabled: this.isDisabled}],
          notifierState: [{value:'',disabled: this.isDisabled}],
          notifierZip: [{value:'',disabled: this.isDisabled}],
        }),
        this._formBuilder.group({
          recipientDataSameAsNotifier:[{value:'',disabled: this.isDisabled}],
          recipientFirstName: [{value:'',disabled: this.isDisabled}],
          recipientLastName: [{value:'',disabled: this.isDisabled}],
          recipientRelationship: [{value:'',disabled: this.isDisabled}],
          recipientOther: [{value:'',disabled: this.isDisabled}],
          recipientFuneralCompanyName: [{value:'',disabled: this.isDisabled}],
          recipientPhone: [{value:'',disabled: this.isDisabled}],
          recipientAltPhone: [{value:'',disabled: this.isDisabled}],
          recipientFax: [{value:'',disabled: this.isDisabled}],
          recipientLine1: [{value:'',disabled: this.isDisabled}],
          recipientLine2: [{value:'',disabled: this.isDisabled}],
          recipientLine3: [{value:'',disabled: this.isDisabled}],
          recipientCountry: [{value:'USA',disabled: this.isDisabled}],
          recipientCity: [{value:'',disabled: this.isDisabled}],
          recipientState: [{value:'',disabled: this.isDisabled}],
          recipientZip: [{value:'',disabled: this.isDisabled}],
        }),
        this._formBuilder.group({
          beneficiaryKnown: [{value:'',disabled: this.isDisabled}],
          beneficiaryName: [{value:'',disabled: this.isDisabled}],
          estateProbated: [{value:'',disabled: this.isDisabled}],
          maritalStatus: [{value:'',disabled: this.isDisabled}],
          spouseLiving: [{value:'',disabled: this.isDisabled}],
          spouseName: [{value:'',disabled:true}],
          spouseDateOfDeath:[{value:'',disabled:true}],
          poeClaim: [{value:'',disabled: this.isDisabled}],
          childrenNames: [{value:'',disabled: this.isDisabled}],
          comments: [{value:'',disabled: this.isDisabled}],
        }),
      ])
    });
  }

  ngAfterViewInit() {
    let claimDetails = OcInfraModule.AppInjector.get(CommonService).claimDetails;
    if (this.element.nativeElement.id == "openClaimDetailes") {
      this.createDeathClaim.controls.formArray["controls"][0].controls["notificationMethod"].setValue(claimDetails.notificationMethod);
      this.createDeathClaim.controls.formArray["controls"][0].controls["claimType"].setValue(claimDetails.claimType);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedParty"].setValue(claimDetails.deceasedParty);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedFirstName"].setValue(claimDetails.deceasedFirstName);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedMiddleName"].setValue(claimDetails.deceasedMiddleName);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedLastName"].setValue(claimDetails.deceasedLastName);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedInsuredType"].setValue(claimDetails.deceasedInsuredType);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedGovtID"].setValue(claimDetails.deceasedGovtID);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedDOB"].setValue(new Date(claimDetails.deceasedDOB));
      this.createDeathClaim.controls.formArray["controls"][1].controls["dateOfDeath"].setValue(new Date(claimDetails.dateOfDeath));
      this.createDeathClaim.controls.formArray["controls"][1].controls["mannerOfDeath"].setValue(claimDetails.mannerOfDeath);
      this.createDeathClaim.controls.formArray["controls"][1].controls["countryOfDeath"].setValue(claimDetails.countryOfDeath);
      this.createDeathClaim.controls.formArray["controls"][1].controls["deceasedAdditionalPolicies"].setValue(claimDetails.deceasedAdditionalPolicies);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierFirstName"].setValue(claimDetails.notifierFirstName);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierLastName"].setValue(claimDetails.notifierLastName);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierRelationship"].setValue(claimDetails.notifierRelationship);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierOther"].setValue(claimDetails.notifierOther);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierFuneralCompanyName"].setValue(claimDetails.notifierFuneralCompanyName);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierPhone"].setValue(claimDetails.notifierPhone);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierAltPhone"].setValue(claimDetails.notifierAltPhone);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierFax"].setValue(claimDetails.notifierFax);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierLine1"].setValue(claimDetails.notifierLine1);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierLine2"].setValue(claimDetails.notifierLine2);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierLine3"].setValue(claimDetails.notifierLine3);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierCountry"].setValue(claimDetails.notifierCountry);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierCity"].setValue(claimDetails.notifierCity);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierState"].setValue(claimDetails.notifierState);
      this.createDeathClaim.controls.formArray["controls"][2].controls["notifierZip"].setValue(claimDetails.notifierZip);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientFirstName"].setValue(claimDetails.recipientFirstName);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLastName"].setValue(claimDetails.recipientLastName);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientRelationship"].setValue(claimDetails.recipientRelationship);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientOther"].setValue(claimDetails.recipientOther);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientFuneralCompanyName"].setValue(claimDetails.recipientFuneralCompanyName);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientPhone"].setValue(claimDetails.recipientPhone);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientAltPhone"].setValue(claimDetails.recipientAltPhone);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientFax"].setValue(claimDetails.recipientFax);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLine1"].setValue(claimDetails.recipientLine1);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLine2"].setValue(claimDetails.recipientLine2);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientLine3"].setValue(claimDetails.recipientLine3);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCountry"].setValue(claimDetails.recipientCountry);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientCity"].setValue(claimDetails.recipientCity);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientState"].setValue(claimDetails.recipientState);
      this.createDeathClaim.controls.formArray["controls"][3].controls["recipientZip"].setValue(claimDetails.recipientZip);
      this.createDeathClaim.controls.formArray["controls"][4].controls["beneficiaryKnown"].setValue(claimDetails.beneficiaryKnown == "No" ? false : true);
      this.createDeathClaim.controls.formArray["controls"][4].controls["beneficiaryName"].setValue(claimDetails.beneficiaryName);
      this.createDeathClaim.controls.formArray["controls"][4].controls["estateProbated"].setValue(claimDetails.estateProbated == "No" ? false : true);
      this.createDeathClaim.controls.formArray["controls"][4].controls["maritalStatus"].setValue(claimDetails.maritalStatus);
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseLiving"].setValue(claimDetails.spouseLiving);
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseName"].setValue(claimDetails.spouseName);
      this.createDeathClaim.controls.formArray["controls"][4].controls["spouseDateOfDeath"].setValue(new Date(claimDetails.spouseDateOfDeath));
      this.createDeathClaim.controls.formArray["controls"][4].controls["poeClaim"].setValue(claimDetails.poeClaim== "No" ? false : true);
      this.createDeathClaim.controls.formArray["controls"][4].controls["childrenNames"].setValue(claimDetails.childrenNames);
      this.createDeathClaim.controls.formArray["controls"][4].controls["comments"].setValue(claimDetails.comments);
    }
  }
}
