import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router/src/interfaces';
import { RendererComponent, OcInfraModule } from '@diaas/ux-web';
import { CommonService } from './common.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<RendererComponent> {
  constructor(private authenticationService: AuthenticationService) { }
  canDeactivate(component: RendererComponent): boolean {
    if ((component.metamodelName == "customerDetails" || component.metamodelName == "agentDetails" || component.metamodelName == "policyDetails") && OcInfraModule.AppInjector.get(CommonService).backButton) {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Are you sure you want to leave this page? You will be redirected to the Login screen.', 'No', 'yes', 'alert', '340px')
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          OcInfraModule.AppInjector.get(CommonService).backButton = false;
          this.authenticationService.executeLogout();
        } else {
          OcInfraModule.AppInjector.get(CommonService).backButton = false;
          return false;
        }
      })
    } else {
      return true;
    }
  }
}