import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, ResourceService } from '@diaas/ux-web';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter',
  templateUrl: './customFilter.component.html',

})

export class FilterComponent {

  constructor(private resourceService: ResourceService) {

  }

  selectedValues: any[];
  selectedAll: any[];
  values: any[];
  status: any;
  transactionType: any;
  query: any;
  selectedFilter: any;


  selectionChange(event) {
    let filterName = _.filter(event.path, function (o) { return o.id !== ""; });
    OcInfraModule.AppInjector.get(CommonService).selectdFilter = filterName[1].id;
    switch (filterName[1].id) {
      case 'historicalCoiTransactionTypeFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).historicalCoiTransactionType;
        break;
      case 'outGoingMoneyTransactionTypeFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).outGoingMoneyTransactionType;
        break;
      case 'outGoingMoneystatusFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).outGoingMoneyStatus;
        break;
      case 'fundsTypeFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).fundsType;
        break;
      case 'paymentHistoryTransactionTypeFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).paymentHistoryTransactionType;
        break;
      case 'paymentHistorystatusFilter':
        this.values = OcInfraModule.AppInjector.get(CommonService).paymentHistoryStatus;
        break;
    }
  }

  equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.enum === objTwo.enum;
    }
  }

  selectAll(checkAll, select: NgModel, values) {
    if (checkAll) {
      select.update.emit(values);
    }
    else {
      select.update.emit([]);
    }
  }

  getID(input, field) {
    let id = [];
    for (var i = 0; i < input.length; ++i)
      id.push(input[i][field][0]);
    return id;
  }

  cancelFilter() {
    this.selectedFilter = OcInfraModule.AppInjector.get(CommonService).selectdFilter;
    switch (this.selectedFilter) {
      case 'historicalCoiTransactionTypeFilter':
        OcInfraModule.AppInjector.get(CommonService).historicalCoiTransactionTypeFilter = '';
        this.filter('historicalcoi', 'transaction_type');
        break;
      case 'outGoingMoneyTransactionTypeFilter':
        OcInfraModule.AppInjector.get(CommonService).outGoingMoneyTransactionTypeFilter = '';
        this.filter('outgoingmoney', 'transaction_type');
        break;
      case 'outGoingMoneystatusFilter':
        OcInfraModule.AppInjector.get(CommonService).outGoingMoneystatusFilter = '';
        this.filter('outgoingmoney', 'status');
        break;
      case 'fundsTypeFilter':
          OcInfraModule.AppInjector.get(CommonService).fundsTypeFilter = '';
          this.filter('historyFunds', 'transaction_type');
          break;
      case 'paymentHistoryTransactionTypeFilter':
        OcInfraModule.AppInjector.get(CommonService).paymentHistoryTransactionTypeFilter = '';
        this.filter('paymenthistory', 'transaction_type');
        break;
      case 'paymentHistorystatusFilter':
        OcInfraModule.AppInjector.get(CommonService).paymentHistorystatusFilter = '';
        this.filter('paymenthistory', 'status');
        break;
    }
  }

  applyFilter() {
    this.selectedFilter = OcInfraModule.AppInjector.get(CommonService).selectdFilter;
    switch (this.selectedFilter) {
      case 'historicalCoiTransactionTypeFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).historicalCoiTransactionTypeFilter = this.query;
        this.filter('historicalcoi', 'transaction_type');
        break;
      case 'outGoingMoneyTransactionTypeFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).outGoingMoneyTransactionTypeFilter = this.query;
        this.filter('outgoingmoney', 'transaction_type');
        break;
      case 'outGoingMoneystatusFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).outGoingMoneystatusFilter = this.query;
        this.filter('outgoingmoney', 'status');
        break;
      case 'fundsTypeFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).fundsTypeFilter = this.query;
        this.filter('historyFunds', 'transaction_type');
        break; 
      case 'paymentHistoryTransactionTypeFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).paymentHistoryTransactionTypeFilter = this.query;
        this.filter('paymenthistory', 'transaction_type');
        break;
      case 'paymentHistorystatusFilter':
        this.query = this.getID(this.selectedValues, "enum");
        OcInfraModule.AppInjector.get(CommonService).paymentHistorystatusFilter = this.query;
        this.filter('paymenthistory', 'c');
        break;
    }
  }

  filter(params, type) {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    switch (params) {
      case 'historicalcoi':
        this.transactionType = OcInfraModule.AppInjector.get(CommonService).historicalCoiTransactionTypeFilter ? "&" + "transaction_type=" + OcInfraModule.AppInjector.get(CommonService).historicalCoiTransactionTypeFilter : "";
        let queryurl = policyDetails._links[params].href + this.transactionType
        resourceService.updateCreateAliasName(params, queryurl, true);
        break;
      case 'outgoingmoney':
        this.transactionType = OcInfraModule.AppInjector.get(CommonService).outGoingMoneyTransactionTypeFilter ? "&" + "transaction_type=" + OcInfraModule.AppInjector.get(CommonService).outGoingMoneyTransactionTypeFilter : "";
        this.status = OcInfraModule.AppInjector.get(CommonService).outGoingMoneystatusFilter ? "&" + "status=" + OcInfraModule.AppInjector.get(CommonService).outGoingMoneystatusFilter : "";
        if (this.transactionType || this.status) {
          let queryurl = policyDetails._links[params].href + this.transactionType + this.status;
          resourceService.updateCreateAliasName(params, queryurl, true);
        } else {
          let queryurl = policyDetails._links[params].href;
          resourceService.updateCreateAliasName(params, queryurl, true);
        }
        break;
      case 'paymenthistory':
        this.transactionType = OcInfraModule.AppInjector.get(CommonService).paymentHistoryTransactionTypeFilter ? "&" + "transaction_type=" + OcInfraModule.AppInjector.get(CommonService).paymentHistoryTransactionTypeFilter : "";
        this.status = OcInfraModule.AppInjector.get(CommonService).paymentHistorystatusFilter ? "&" + "status=" + OcInfraModule.AppInjector.get(CommonService).paymentHistorystatusFilter : "";
        if (this.transactionType || this.status) {
          let queryurl = policyDetails._links[params].href + this.transactionType + this.status;
          resourceService.updateCreateAliasName(params, queryurl, true);
        } else {
          let queryurl = policyDetails._links[params].href;
          resourceService.updateCreateAliasName(params, queryurl, true);
        }
        break;
        case 'historyFunds':
          this.transactionType = OcInfraModule.AppInjector.get(CommonService).fundsTypeFilter ? "&" + "transaction_type=" + OcInfraModule.AppInjector.get(CommonService).fundsTypeFilter : "";
          if (this.transactionType) {
            let queryurl = policyDetails._links[params].href + this.transactionType;
            resourceService.updateCreateAliasName(params, queryurl, true);
          } else {
            let queryurl = policyDetails._links[params].href;
            resourceService.updateCreateAliasName(params, queryurl, true);
          }
          break;
    }
    
  }

}
