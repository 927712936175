﻿import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonFactory } from './commonFactory';
import { CommonService } from '../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { WithdrawalSurrenderAction } from '../components/withdrawlsSurrender/withdrawalSurrenderAction';
import * as _ from 'lodash';

export class PartySearchFactory extends CommonFactory {

    savedProperties: any = {};
    checkedProperties: any = {};
    requiredPayload: any = {};
    authResponse: any = {};
    otherRolePayload: any = {};
    closeCallPayLoad: any = {};
    html = '';
    coveragehtml = '';
    coveragerArray: any;
    covereages: any = [];
    selectdValues = [];
    currentChecked = {};
    selectedSuspence = {};
    statusMsg = '';
    comments = '';
    res :any = {};
    searchResults :any = [];

    navigateTo(params) {
        super.navigateTo(params);
    }

    public resourceService = OcInfraModule.AppInjector.get(ResourceService);
    
    openPolicyDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = false;

        if (!params.summary.clientId && params.summary.msg && params.summary.msg != '') {
          let alertMsg =[];
          let popUpMsg = params.summary.msg.split(".")
          alertMsg.push(params.summary.msg.substring(params.summary.msg.indexOf("."),0) + '.',popUpMsg[1].substring(popUpMsg[1].indexOf(":"),0) + ':')
          alertMsg.push(params.summary.contactLabel1 ? params.summary.contactLabel1 + ": " + params.summary.contactNumber1 : '', params.summary.contactLabel2 ? params.summary.contactLabel2 + ": " + params.summary.contactNumber2 : '', params.summary.contactLabel3 ? params.summary.contactLabel3 + ": " + params.summary.contactNumber3 : '');
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({content :alertMsg, custom:true}, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
            OcInfraModule.AppInjector.get(CommonService).isAnnuity = params.summary.product_type == 'A1' || params.summary.product_type == 'A2';
            const navigationParams: any = {};
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary.product_type : '';
                (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "owner": '';
                (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole.toUpperCase() : '';
                (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role = params.summary.role: '';
                (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary.REGION : '';
                (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = params.summary.statutory_company_code : '';
            });
            navigationParams.url = 'policyDetails';
            navigationParams.link = params.href;
            navigationParams.alias = 'policyDetails';
            super.navigateTo(navigationParams);
            this.getPolicyCallHistory(params,'contact_policy_history_url');
            this.getCustomIdentity(params);
            this.alertMessages(params);
            this.setPaymentsUrl();
            this.resetPolicyResources();
            let customBanner;
            params.summary && params.summary.duplicatePolicyMsg && params.summary.duplicatePolicyMsg != '' ? customBanner = setInterval(function () {
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                if (policyDetails) {
                    let status_report = {
                        "consistent": true,
                        "messages": [
                            {
                                "message": params.summary.duplicatePolicyMsg,
                                "severity": "ALERT"
                            }
                        ],
                        url: params.href,
                        alias: "policyDetails"
                    }
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
                    clearInterval(customBanner);
                }
            }, 500) : '';
        }
    }

    /* This function is used for open Agent details */
    openAgentDetails(params) {
            OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary = params.summary;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
            const navigationParams: any = {};
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary && params.summary.product_type ? params.summary.product_type: 'U1' : '';
                (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "Agent": '';
                (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary && params.summary.REGION ? params.summary.REGION : 'BHF1' : '';
                (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = "105" : '';
                (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole : '';
                (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role ='Agent': '';
            });
            navigationParams.url = 'agentDetails';
            navigationParams.alias = 'agentDetails';
            super.navigateTo(navigationParams);
            this.getPolicyCallHistory(params,'agent_contact_history_url');
            this.getAgentRoleDetais(params);
            this.alertBannerMessages(params);
            this.resetPolicyResources();
    }

    /* This function is used for open Customer details */
    openCustomerDetails(params) {
        if (!params.summary.clientId && params.summary.msg && params.summary.msg != '') {
          let alertMsg =[];
          let popUpMsg = params.summary.msg.split(".")
          alertMsg.push(params.summary.msg.substring(params.summary.msg.indexOf("."),0) + '.',popUpMsg[1].substring(popUpMsg[1].indexOf(":"),0) + ':')
          alertMsg.push(params.summary.contactLabel1 ? params.summary.contactLabel1 + ":" + params.summary.contactNumber1 : '', params.summary.contactLabel2 ? params.summary.contactLabel2 + ":" + params.summary.contactNumber2 : '', params.summary.contactLabel3 ? params.summary.contactLabel3 + ":" + params.summary.contactNumber3 : '');
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({content :alertMsg, custom:true}, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            OcInfraModule.AppInjector.get(CommonService).customerDetails=params;
            OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
            const navigationParams: any = {};
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary && params.summary.product_type ? params.summary.product_type: 'U1' : '';
                (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "Owner": '';
                (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary && params.summary.REGION ? params.summary.REGION : 'BHF1' : '';
                (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = "105" : '';
                (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole : '';
                (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role ='Owner': '';
            });
            navigationParams.url = 'customerDetails';
            navigationParams.alias = 'customerDetails';
            super.navigateTo(navigationParams);
            this.getPolicyCallHistory(params,'customer_contact_history_url');
            this.getCustomerRoleDetails(params);
            this.alertBannerMessages(params);
            this.resetPolicyResources();
            if (params.summary && params.summary.duplicatePolicyMsg && params.summary.duplicatePolicyMsg != '') {
                let status_report = {
                    "showAll": true,
                    "consistent": true,
                    "messages": [
                        {
                            "message": params.summary.duplicatePolicyMsg,
                            "severity": "ALERT"
                        }
                    ],
                    url: params.href,
                    alias: "customerDetails"
                }
                // to make sure  customerDetails view is loaded  before banner shows up.
                setTimeout(() => {
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
                }, 700);
            }
        }
    }

    policyDetailsDisable(): boolean {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetailsWidget = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetailsWidget &&  OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1) {
            OcInfraModule.AppInjector.get(CommonService).disableTermandFia = policyDetailsWidget.response.product_type == "A2" ? policyDetailsWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1 ? true : false;;
            return policyDetailsWidget.response.product_type == "A2" ? policyDetailsWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1 ? true : false;
        }else {
            OcInfraModule.AppInjector.get(CommonService).disableTermandFia = false;
            return false;
        }
    }

    financialHistoryDisable(): boolean {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let financialHistWidget = resourceService.getResourceFromAliasName('policyDetails');
        if (financialHistWidget && OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(financialHistWidget.response.product_type) != -1) {
            return financialHistWidget.response.product_type == "A2" ? financialHistWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(financialHistWidget.response.product_type) != -1 ? true : false;;
        }else {
            return false;
        }
    }
 
    resetPolicyResources(): void {
        let resourceDirectory: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
        if(resourceDirectory){
          const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
          resourceDirectory.forEach(resource => {
            if(resource.alias !== "callHistory"||resource.alias !== "instances"||resource.alias !== "searchContracts"||resource.alias !== "avgCallHandleTime"||resource.alias !== "callBoard"){
                resourceDirectoryService.deleteElementResourceDirectory(resource.href);
            }
          });
        }
    }

    setPaymentsUrl() {
        let PaymentsUrl = setInterval(function () {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            if (policyDetails) {
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'payments' });
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('payments', policyDetails._links.claims.href + '/payments', true);
                clearInterval(PaymentsUrl);
            }
        }, 500);
    }

    refreshPolicyDetails() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'refreshPolicyDetails') {
                headerObj.headers.remote_user = null;
                headerObj.headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            }
        });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'refreshPolicyDetails' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        let refreshPolicyDetails = setInterval(function () {
            let authorizeSucess = resourceService.getResourceFromAliasName('authorizeSucess');
            if (authorizeSucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                let key = Object.keys(policyDetails._links);
                let refreshAlias = ["billing"]
                key.forEach(linksObj => {
                    if (refreshAlias.indexOf(linksObj) != -1) {
                        OcInfraModule.AppInjector.get(ResourceDirectoryService).deleteElementResourceDirectory(policyDetails._links[linksObj].href);
                        OcInfraModule.AppInjector.get(APICallerService).get(policyDetails._links[linksObj].href).subscribe(response => {
                            let patch = _.filter(response._options.links, function (o) { return o.method == 'PATCH' })
                            if (patch.length > 0) {
                                OcInfraModule.AppInjector.get(CommonService).billingPatchHref = patch[0].href;
                                OcInfraModule.AppInjector.get(CommonService).showEditIcon(linksObj, true);
                            } else {
                                OcInfraModule.AppInjector.get(CommonService).showEditIcon(linksObj, false);
                            }
                        });
                    }
                });
            }
            clearInterval(refreshPolicyDetails);
        }, 500);
    }

    alertMessages(params) {
        let alertMsgUrl = _.filter(params.summary.links, function (o) { return o.rel == "alert_Messages_url" });
        const headers = Configuration.config.headers.defaultHeader;
        if (alertMsgUrl.length && alertMsgUrl[0].href) {
            OcInfraModule.AppInjector.get(CommonService).getCallback(alertMsgUrl[0].href, headers).subscribe(responce => {
                responce._embedded.status_report.url = params.href;
                responce._embedded.status_report.alias = "policyDetails";
                responce._embedded.status_report.showAll = true;
                let policyCsrAlert = setInterval(function () {
                    if (OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert) {
                        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(responce._embedded.status_report);
                        clearInterval(policyCsrAlert);
                    }
                }, 500);
            });
        }
    }

    alertBannerMessages(params) {
        OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
        OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
        OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage = '';
        let alertMsgUrl = _.filter(params.summary.links, function (o) { return o.rel == "alert_Messages_url" });
        const headers = Configuration.config.headers.defaultHeader;
        if (alertMsgUrl.length && alertMsgUrl[0].href) {
            OcInfraModule.AppInjector.get(CommonService).getCallback(alertMsgUrl[0].href, headers).subscribe(responce => {
                responce._embedded.status_report.url = OcInfraModule.AppInjector.get(CommonService).searchAgentUrl;
                responce._embedded.status_report.alias = "searchAgents";
                responce._embedded.status_report.showAll = true;
                OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage = responce._embedded.status_report;
                OcInfraModule.AppInjector.get(CommonService).pushAlertBannerMessage(responce._embedded.status_report);
                OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
            });
        }
    }

    showBillingEditIcon() {
        return OcInfraModule.AppInjector.get(CommonService).billing;
    }

    showBlueBar(){
       return !OcInfraModule.AppInjector.get(CommonService).billing;
    }

    showMesseage(params) {
        let valuesMesseage = document.getElementsByClassName("valuesMesseage")[0];
        if (valuesMesseage ) {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let fincialValues = resourceService.getResourceFromAliasName(params.alias);
            if( fincialValues && fincialValues.response._embedded ){
               fincialValues._embedded.status_report.messages.forEach(msgObj => {
                    this.statusMsg += msgObj.message;
                });
                valuesMesseage.innerHTML = this.statusMsg;   
                OcInfraModule.AppInjector.get(CommonService).valuesMesseage = this.statusMsg;
            }
        }
        return true;
    }

    showLoanMesseage(params){
      let loanMesseage = document.getElementsByClassName("loanMesseage")[0];
        if (loanMesseage ) {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let fincialValues = resourceService.getResourceFromAliasName(params.alias);
            if( fincialValues && fincialValues.response._embedded ){
               fincialValues._embedded.status_report.messages.forEach(msgObj => {
                    this.statusMsg += msgObj.message;
                });
                loanMesseage.innerHTML = this.statusMsg;   
                OcInfraModule.AppInjector.get(CommonService).loanMesseage = this.statusMsg;
            }
        }
        return true;
    }

    deliveryMethod(field) {
        let delivary = OcInfraModule.AppInjector.get(CommonService).deliveryMethod;
        field.value == "" ? field.value = "Mail" : '';
        return delivary;
    }

    checkedQuoteValuesMesseage(field) {
        this.policyValues(field, true);
    }

    showQuoteValuesFinalHtml() {
        if (OcInfraModule.AppInjector.get(CommonService).quoteMesseageHtml != '' || OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml != '') {
            OcInfraModule.AppInjector.get(CommonService).quoteValuesFinalHtml = '<span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + OcInfraModule.AppInjector.get(CommonService).quoteMesseageHtml + OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml+'</dl>';
        } else {
            OcInfraModule.AppInjector.get(CommonService).quoteValuesFinalHtml = '';
        }

    }

    checkedLoanMesseage(field) {
        this.loanValues(field, true);
    }
    
    getCustomIdentity(params) {
        let selectedRole = _.filter(params.summary.links, function (o) { return o.rel == "authorize_url" });
        OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('roles', selectedRole[0].href, true);
        OcInfraModule.AppInjector.get(APICallerService).get(selectedRole[0].href).subscribe(response => {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let optionParams = new OptionParams();
            optionParams.headers= OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
            let selectedRole = _.filter(response._links.item, function (o) { return o.summary.clientId == params.summary.clientId; });
            resourceService.updateCreateAliasName('selectedRoles', selectedRole[0].href, true, optionParams);
            if (selectedRole[0].summary.partType === 'corporate') {
                OcInfraModule.AppInjector.get(CommonService).corporateOwner = true;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            } else {
                OcInfraModule.AppInjector.get(CommonService).corporateOwner = false;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            }
            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRole[0].summary.role;
        });
    }

    getAgentRoleDetais(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let optionParams = new OptionParams();
        let customIdentity = _.filter(OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary.links, function (o) { return o.rel == "authorize_url" });
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("roles");
        optionParams.alias = "roles"
        OcInfraModule.AppInjector.get(APICallerService).refresh(customIdentity[0].href, optionParams, 'Reset').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).agentRoleDetails = response;
            resourceService.updateCreateAliasName('selectedRoles', response.response._links.item[0].href, true, optionParams.headers);
        });
    }

    getCustomerRoleDetails(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let optionParams = new OptionParams();
        let customIdentity = _.filter(params.summary.links, function (o) { return o.rel == "authorize_url" });
        OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('roles', customIdentity[0].href, true);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("roles");
        optionParams.alias = "roles"
        OcInfraModule.AppInjector.get(APICallerService).get(customIdentity[0].href).subscribe(response => {
            let selectedRole = _.filter(response._links.item, function (o) { return o.summary.clientId == params.summary.clientId; });
            resourceService.updateCreateAliasName('selectedRoles', selectedRole[0].href, true, optionParams.headers);
            OcInfraModule.AppInjector.get(CommonService).customerRoleDetails = selectedRole;
            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRole[0].summary.role;
        }); 
    }

    getPolicyCallHistory(params,rel) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        let optionParams = new OptionParams();
        if (params.summary) {
            const aliasToDelete: IAlias = resourceService.getAliasByAliasName('policyCallHistory');
            if (aliasToDelete) {
                resourceDirectoryService.deleteElementResourceDirectory(OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl);
                resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
            }
            let selectedPolicy = _.filter(params.summary.links, function (o) { return o.rel == rel });
            OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl = selectedPolicy[0].href.split('?')[0];
            let newhref = selectedPolicy[0].href.substr(0, selectedPolicy[0].href.lastIndexOf('?'));
            let optionParams = new OptionParams();
            optionParams.params = []

            const pairs = selectedPolicy[0].href.split('?')[1].split('&');

            pairs.forEach(pair => {
                const queryParam = pair.split('=');
                optionParams.params.push({ key: queryParam[0], value: queryParam[1] });
            });
            optionParams.headers= OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");

            OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyCallHistory', selectedPolicy[0].href.split('?')[0], true, optionParams);
        }
        else {
            const aliasToDelete: IAlias = resourceService.getAliasByAliasName('policyCallHistory');
            resourceDirectoryService.deleteElementResourceDirectory(aliasToDelete.href);
            resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
            OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyCallHistory', OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl, true, optionParams);
        }
    }

    policyCoverageDetails(params) {
        const navigationParams: any = {};
        navigationParams.url = 'policyCoverageDetails';
        navigationParams.link = params.field.href;
        super.navigateTo(navigationParams);
    }

    openHomePage(params) {
        const navigationParams: any = {};
        navigationParams.url = 'home';
        navigationParams.link = params.href;
        super.navigateTo(navigationParams);
        OcInfraModule.AppInjector.get(CommonService).myCallHistory();
        OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
    }

    editParty(params) {
        return true
    }

    customIdentitySeprator(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && !(selectedIdentity.data.partType == 'corporate') && selectedIdentity.data.role == 'Owner') {
            return true;
        } else {
            return false;
        }
    }

    corporateOwnerSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Agent' || selectedIdentity.data.partType == 'corporate') {
               if( selectedIdentity.data.role == 'Assignee'){
                return false;
               }else{
                return true;
               }
            } 
        } 
    }

    hideBillingFields() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('policyDetails');
        if (billing && billing.response.product_type == 'T1') {
            return true;
        }
        else return false;
    }

    showListBillField(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        if (billing && billing.response.bill_option_desc == 'L - List Bill') {
            return true;
        }
        else return false;
    }
    
    showBillingFields() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        if (billing && billing.response.bill_option == 'P') {
            return true;
        }
        else return false;
    }

    hidePolicyDetailsFields() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails.response.product_type == 'T1') {
            return true;
        }
        else return false;

    }

    deleteParty(params) {
        return true;
    }

    createWorkItem(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
        const modalProperties = params.modal.renderer.data.sections[0].properties
        const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        const authResponse = resourceService.getResourceFromAliasName('authRes');
        const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
        const requiredProperties=[];
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      
        
        const payload = {
            "status": "CREATED",
            "CLTY": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType,
            "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
            "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
            "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
            "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
            "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
            "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
            "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
            "origin_app":"csr_portal"
        }; 
        let commentsValue = '';  
       
        if (selectedRoleSummary.data.role != undefined) {
            if (selectedRoleSummary.data.role == 'Payor') {
                commentsValue =  'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName ;
            } else {
                commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.fullName;
            }
        }
        else{
            let name = document.getElementById("selected-roles-name-text")["value"];
            let number = document.getElementById("selected-roles-phone-number-text")["value"];
            commentsValue = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
        }
        modalProperties.forEach(propertiesObj => {
            if(propertiesObj.required == true){
                requiredProperties.push(propertiesObj.id);
            }
            if (propertiesObj.value == '' && propertiesObj.id == 'comments') {
                payload[propertiesObj.id] = commentsValue;
            }
            if (propertiesObj.value) {
                payload[propertiesObj.id] = propertiesObj.value
            }
        });
        var blankRequiredfields = requiredProperties.filter(id => {
            return !payload[id] || payload[id] === '';
        })

        if(blankRequiredfields.length){       
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please fill required fields", 'Error', 0);
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
            OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
                if (response.success) {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                    setTimeout(() => {
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
                    }, 6000)
                    setTimeout(() => {
                        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                            params.modal.dialogRef.close();
                            setTimeout(() => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                            }, 7000)
                        });
                    }, 5000);
                } else {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                    params.modal.dialogRef.close();
                }
            },
                error => {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                });
        }
    }
addOlsWorkItemsComments(){
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false})
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'addComments' });
            OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            //const workRequestCommentsHref = resourceService.getHrefByAliasName("selectedWorkRequestComments");
           //  const workRequestDetailHistoryHref = resourceService.getHrefByAliasName("selectedWorkRequestDetailsHistory");
            this.comments =document.getElementById("selected-work-request-awd-comments-textarea_")["value"];
            const payload = {
                "AWDComments": this.comments
            };
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(CommonService).postCallback(OcInfraModule.AppInjector.get(CommonService).commentsUrl, payload,header[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.Status, 'Success');
            document.getElementById("selected-work-request-awd-comments-textarea_")["value"] = '';
        //   OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestDetailHistoryHref).subscribe(refreshResponse => {
        //         });
            },
            error =>{
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });

    }
    addWorkItemsComments() {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false})
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'addComments' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestCommentsHref = resourceService.getHrefByAliasName("selectedWorkRequestComments");
        const workRequestDetailHistoryHref = resourceService.getHrefByAliasName("selectedWorkRequestDetailsHistory");
        this.comments =document.getElementById("selected-work-request-details-history-comments-textarea_")["value"];
        const payload = { 
            "comment": this.comments
        };
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).post(workRequestCommentsHref, payload).subscribe(response => {
            document.getElementById("selected-work-request-details-history-comments-textarea_")["value"] = '';
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestDetailHistoryHref).subscribe(refreshResponse => {
            });
           let escalateWorkrequest  = OcInfraModule.AppInjector.get(CommonService).escalateWorkRequest;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false})
            if (escalateWorkrequest) {
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
                const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
                const authResponse = resourceService.getResourceFromAliasName('authRes');
                const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                const payload = {
                    "status": "CREATED",
                    "CLTY": "ESCALATE",
                    "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
                    "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
                    "type":"Escalate",
                    "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
                    "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
                    "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
                    "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
                    "comments": this.comments,
                    "origin_app":"csr_portal"
                };
                OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
                    if (response.success) {
                        document.querySelector('#escalate .mat-checkbox').classList.remove('mat-checkbox-checked')
                        document.querySelector('#escalate .mat-checkbox-input')['checked'] = false;
                        document.getElementById("selected-work-request-details-history-comments-textarea_")["value"] = ''; 
                        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false}) 
                        setTimeout(() => {
                            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                            });
                        }, 5000);
                    } else {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                    }
                },
                    error => {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                        document.querySelector('#escalate .mat-checkbox').classList.remove('mat-checkbox-checked')
                        document.querySelector('#escalate .mat-checkbox-input')['checked'] = false;
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                    });
            }
        },
        error =>{
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
    }
    
    disableAddCommentBtn(){
        let comments = document.getElementById("selected-work-request-details-history-comments-textarea_") ? document.getElementById("selected-work-request-details-history-comments-textarea_")["value"] : undefined;
        if(comments){
            return true;
        }else{
            return false;
        }
    }

    disableOlsAddCommentBtn(){
        let comments = document.getElementById("selected-work-request-awd-comments-textarea_") ? document.getElementById("selected-work-request-awd-comments-textarea_")["value"] : undefined;
        if(comments){
            return true;
        }else{
            return false;
        }
    }
    showAnnuityValues() {
        let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
        if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
            return true;
        }
        else return false;
    }

    showIulValues() {
        let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
        if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
            return false;
        }
        else return true;
    }

    selectedRoleDetails(params) {
        let optionParams = new OptionParams();
        let headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedRoles");
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null });
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.nameRoleTittle == params.value; });
        OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRoleSummary[0].summary.role;
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(APICallerService).refresh(selectedRoleSummary[0].href, { 'alias': "selectedRoles", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).corporateOwner = refreshResponse && refreshResponse.response && refreshResponse.response._embedded && refreshResponse.response._embedded.status_report.messages.length ? true : false;
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        })
    }
    selectedOtherRole(params) {
        if (params.value == 'Other') {
            OcInfraModule.AppInjector.get(CommonService).selectedOther = true;
            this.showOtherRoleName();
        }
        else {
            OcInfraModule.AppInjector.get(CommonService).selectedOther = false;
        }
        OcInfraModule.AppInjector.get(CommonService).otherRole = params.value;
    }
    otherRoleSelect() {
        OcInfraModule.AppInjector.get(CommonService).role = 'OtherRole';
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let roleList = resourceService.getResourceFromAliasName('roles');
        resourceService.updateCreateAliasName('selectedRoles', roleList.response._links.otherParty.href, true);
        OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection = true;
    }
    cancelOtherRole() {
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        OcInfraModule.AppInjector.get(CommonService).role = '';
        OcInfraModule.AppInjector.get(CommonService).otherRole = '';
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role == OcInfraModule.AppInjector.get(CommonService).selectedRole; });
        let agentDetais = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        currentPage == '/screen/agentDetails' ? selectedRoleSummary = agentDetais.response._links.item : '';
        resourceService.updateCreateAliasName('selectedRoles', selectedRoleSummary[0].href, true);
        OcInfraModule.AppInjector.get(CommonService).selectedOther = false;
        OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection = false;
    }
    quoteDate(field) {
        let year = field.value.substring(0, 4);
        let month = field.value.substring(5, 7);
        let day = field.value.substring(8, 10);
        OcInfraModule.AppInjector.get(CommonService).quoteDate = month + '/' + day + '/' + year;
    }
    loanDate(field) {
        let year = field.value.substring(0, 4);
        let month = field.value.substring(5, 7);
        let day = field.value.substring(8, 10);
        OcInfraModule.AppInjector.get(CommonService).loanDate = month + '/' + day + '/' + year;
    }
    validateDate(testdate) {
        let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
        return date_regex.test(testdate);
    }
    
    quoteValues() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteValueDetails = resourceService.getResourceFromAliasName('policyValues');
        let quoteDefaultDate = quoteValueDetails.data.quote_effective_date;
        if (quoteDate == "") {
            quoteDate = quoteDefaultDate;
        }
        let isDateCorrect = this.validateDate(quoteDate);
        if (isDateCorrect) {
            let url = policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href;
            let optionParams = new OptionParams();
            optionParams.alias = "policyValues";
            OcInfraModule.AppInjector.get(CommonService).quoteDate ? optionParams.params = [{ 'key': "quoteEffectiveDate", value: OcInfraModule.AppInjector.get(CommonService).quoteDate }] : null;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, "RESET").subscribe(res => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
            });
        }
        else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("policy-values-quote-effective-date-date_input")["value"] = quoteDefaultDate;
            console.log('incorrect date format');
        }
    }
    quoteLoan() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteValueDetails = resourceService.getResourceFromAliasName('loanValues');
        let quoteDefaultDate = quoteValueDetails.data.quote_effective_date;
        if(quoteDate ==""){
            quoteDate = quoteDefaultDate;
        }
        let isDateCorrect = this.validateDate(quoteDate);
        console.log(isDateCorrect);
        if(isDateCorrect){
            let url = OcInfraModule.AppInjector.get(CommonService).quoteDate ? policyDetails._links.financialvalues.href + '?quoteEffectiveDate=' + OcInfraModule.AppInjector.get(CommonService).quoteDate : policyDetails._links.financialvalues.href;
            resourceService.updateCreateAliasName('loanValues', url, true);
        }
        else{
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("loan-values-quote-effective-date-date_input")["value"] = quoteDefaultDate; 
            console.log('incorrect date format');
        }
    }
    showAuthorizeAndOther() {
        return !OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection;
    }

    showCancelAndContinue() {
        return OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection;
    }

    disableContinueButton() {
        let otherRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
        let authsucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let number = document.getElementById("selected-roles-phone-number-text") ? document.getElementById("selected-roles-phone-number-text")["value"] : undefined;
        let name = document.getElementById("selected-roles-name-text") ? document.getElementById("selected-roles-name-text")["value"] : undefined;
        if (number && number.length == 12 && name && otherRole != undefined && otherRole != '' && authsucess != true) {
            if (otherRole == 'Other') {
                if (document.getElementById("selected-roles-other-role-name-text")["value"] != '') {
                    return true;
                }
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }

    disableAuthorizeButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && policyDetails && selectedRoles;
    }

    disableAgentAuthorizeAndOtherRoleButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && selectedRoles;
    }

    disableCancelButton() {
        return !OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    }

    disableOtherRoleButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && policyDetails && selectedRoles;
    }

    disableSaveAndCloseButton() {
        let reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let closeCallButton = document.getElementById("save-close-close-call-button");
        let notes = document.getElementById("close-call-notes-textarea_");
        if (reason != "" && (authsucess || otherRoleSucess)) {
            if (closeCallButton) {
                closeCallButton.setAttribute('title', '');
            }
            return true;
        }
        else {
            if ( !(authsucess || otherRoleSucess) && closeCallButton) {
                closeCallButton.setAttribute('title', 'Caller was not Authorized');
            } else if ((authsucess || otherRoleSucess) && !reason && closeCallButton) {
                closeCallButton.setAttribute('title', 'Enable button by completing required items');
            }
            return false;
        }
    }

    showconfirmationNo(field) {
         OcInfraModule.AppInjector.get(CommonService).confirmationNo = field.value == undefined ? false : field.value ;
        if (field.value) {
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        } else {
            document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation';
        }
    }

    authorizeOtherRole() {
        this.authorize('otherRole');
    }

    showAlert() {
        return OcInfraModule.AppInjector.get(CommonService).corporateOwner;
    }

    showNotesBaner() {
        if (!OcInfraModule.AppInjector.get(CommonService).corporateOwner) {
            return true;
        }
        else {
            return false;
        }
    }
    noteMesseage() {
        let policyNotesDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyNotes');
        if (policyNotesDetails) {
            return policyNotesDetails.response._links.item && !policyNotesDetails.response._links.item.length;
        }
    }

    riderMesseage() {
        let policyRiderDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCoverage');
        if (policyRiderDetails) {
            return policyRiderDetails.response._links.item && !policyRiderDetails.response._links.item.length;
        }
    }
    getRiderMsg(params){
        if(params.value){
            let coveragemsg = {}
            coveragemsg['insured']= {};
            coveragemsg['insured']['Message'] = 'This Policy does not have any riders'
            localStorage.setItem('selectedCoverage', JSON.stringify(coveragemsg));
            OcInfraModule.AppInjector.get(CommonService).coverageHtml = ' <span style="font-size:15px" class="pad-lAr-10px">RIDERS</span><dl class="pad-lAr-10px">' + '<dt class="pull-left">Riders</dt><dd>&nbsp;This Policy does not have any riders</dd>' + '</dl>'
        }else{
            localStorage.removeItem('selectedCoverage');
            OcInfraModule.AppInjector.get(CommonService).coverageHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    
    showMsg(params){
        this.showMesseage(params);
        return true;
    }
    showFundsFilter() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historyFunds ? true : resource && resource.response && resource.response._links && resource.response._links.item.length > 0;
    }
    showHCoiFilter() {
        const resource = this.resourceService.getResourceFromAliasName("historicalcoi");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historicalcoi ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showPHFilter() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.paymenthistory ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showOGMFilter() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.outgoingmoney ? true : resource && resource.response && resource.response._links && resource.response._links.item.length > 0;
    }

    authorize(params) {
        let optionParams = new OptionParams();
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("authorize");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true})
        let authorize = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'authorize' });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authorize' });
       
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        this.otherRolePayload.policyNumber = policyDetails.response.policy_number;
        this.requiredPayload.policyNumber = policyDetails.response.policy_number;
        resourceService.updateCreateAliasName('policyNotes', policyDetails._links.notes.href, true);
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        if (params != 'otherRole') {
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                if (headerObj.alias == 'billing') {
                    headerObj.headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                }
            });
            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRoleSummary.data.role.toLowerCase();
            this.requiredPayload.callerName = selectedRoleSummary.data.payorFullName;
            this.requiredPayload.callerName = selectedRoleSummary.data.fullName;
            this.requiredPayload.callerRole = selectedRoleSummary.data.role;
            this.requiredPayload.contractId = selectedRoleSummary.data.hostKey;
            this.requiredPayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
            this.requiredPayload.csrName = sessionStorage.getItem('username')
            let payload = JSON.stringify(this.requiredPayload)
            OcInfraModule.AppInjector.get(CommonService).authorizedRole = selectedRoleSummary.data.role;
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, payload,'autorization').subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).corporateOwner = false;
                OcInfraModule.AppInjector.get(CommonService).authsucess = true;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                OcInfraModule.AppInjector.get(CommonService).authResponse = response;
                document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
                OcInfraModule.AppInjector.get(CommonService).selectedReason = '';
                OcInfraModule.AppInjector.get(CommonService).startCall();
                setTimeout(function () {
                    Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (!accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });

                    Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });
                }, 2000); 
                if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
                    let notesText = document.getElementById("close-call-notes-textarea_")["value"];
                    if (selectedRoleSummary.data.role == 'Payor') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName + ', ' + notesText;
                    } else {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.fullName + ', ' + notesText;
                    }
                }
                else {
                    if (selectedRoleSummary.data.role == 'Payor') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName;
                    } else {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.fullName;
                    }
                }
                let notes = resourceService.getResourceFromAliasName('policyNotes');
                if (notes && notes._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesMsg = true;
                }
                let suspense = resourceService.getResourceFromAliasName('policySuspense');
                if (suspense && suspense._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).SuspenseMsg = true;
                }
                const aliasToDelete: IAlias = resourceService.getAliasByAliasName('authRes');
                if (aliasToDelete) {
                    resourceDirectoryService.deleteElementResourceDirectory(response.updateResponse._options.links[0].href);
                    resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
                }
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({statuaory_company_code :null});
                resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null })
              resourceService.updateCreateAliasName('authorizeSucess', selectedRoleSummary.resourceURL, true);
                this.refreshPolicyDetails();
            });
        }
        else {
            let roleList = resourceService.getResourceFromAliasName('roles');
            let selectRole = _.filter(roleList.response._links.item, function (o) { return o.summary.role == OcInfraModule.AppInjector.get(CommonService).selectedRole; });
            this.otherRolePayload = _.pickBy(selectRole[0].summary, function (value, key) {
                return key == 'policyNumber';
            });
            OcInfraModule.AppInjector.get(CommonService).selectedRole =  OcInfraModule.AppInjector.get(CommonService).otherRole;
            this.otherRolePayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
            let number = document.getElementById("selected-roles-phone-number-text")["value"];
            this.otherRolePayload.callerName = document.getElementById("selected-roles-name-text")["value"];
            if (OcInfraModule.AppInjector.get(CommonService).selectedOther) {
                this.otherRolePayload.callerRole = document.getElementById("selected-roles-other-role-select")["value"];
            }
            this.otherRolePayload.phoneNumber = number;
            this.otherRolePayload.csrName = sessionStorage.getItem('username');
            if(selectRole.length  > 0){
                this.otherRolePayload.contractId = selectRole[0].summary.hostKey;
            }
            this.otherRolePayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, this.otherRolePayload).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = true;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                OcInfraModule.AppInjector.get(CommonService).startCall();
                document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
                const aliasToDelete: IAlias = resourceService.getAliasByAliasName('authRes');
                if (aliasToDelete) {
                    resourceDirectoryService.deleteElementResourceDirectory(response.updateResponse._options.links[0].href);
                    resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
                }
                resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
                this.refreshPolicyDetails();
                setTimeout(function () {
                    Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (!accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });

                    Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });
                }, 2000); 
                setTimeout(function () {
                    let name = document.getElementById("selected-roles-name-text")["value"]
                    OcInfraModule.AppInjector.get(CommonService).authorizedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
                    document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
                    document.getElementById('other-role-auth-role-label_label').innerHTML = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole;
                    document.getElementById('other-role-auth-name-label_label').innerHTML = 'Full Name: ' + name;
                    OcInfraModule.AppInjector.get(CommonService).authResponse = response;
                }, 1000);
                let notes = resourceService.getResourceFromAliasName('policyNotes');
                if (notes && notes._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesMsg = true;
                }
                let suspense = resourceService.getResourceFromAliasName('policySuspense');
                if (suspense && suspense._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).SuspenseMsg = true;
                }
            });
        }
    }

    pendingDeleteAndEditVisible(param) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        return true;
        // resourceService.getResourceFromAliasName('authorizeSucess') && !(param.field.data.body.items.find(item => item.href == param.href).fields.find(col => col.id =="status").value.trim().toLowerCase() == "deleted");
    }
    
    deletePendingTransaction(Params) {
        console.log("Params",Params);
        let msgShow = " ";
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
        let roles = ["Owner", "POA", "Agent Assistant"];
        let transaction = pendingTransactions._links.item; 
        if (otherRoleSucess || authsucess) {
           if (roles.includes(OcInfraModule.AppInjector.get(CommonService).authorizedRole)) {
             transaction.forEach(element => { 
               if (element.summary.status !== "Deleted") {
                 const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
                 const header = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
                 headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                 headers['message_key'] = Params.rowId;
                 if (Params.rowId == element.summary.message_key) {
                   let deleteUrl =  element.summary._options.links[1];
                   let getUrl =  pendingTransactions._links.self.href;
                   OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
                   OcInfraModule.AppInjector.get(CommonService).deleteCallback(deleteUrl.href, headers).subscribe(response => {
                   console.log("Response",response);
                   if (response._embedded.status_report.messages[0].message == "DataCollect Record Deletion is Successful") {
                     OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response._embedded.status_report.messages[0].message, 'Success');
                     setTimeout(() => {
                       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
                     }, 6000)
                     
		     setTimeout(() => {
                       OcInfraModule.AppInjector.get(APICallerService).refresh(getUrl, {'alias':"pendingTransactions", 'headers': header }).subscribe(refreshResponse => {
                         Params.modal.dialogRef.close();
                         setTimeout(() => {
                           OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false });
                         }, 7000)
                        });
                     }, 5000);
                   } else {
                     OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                     Params.modal.dialogRef.close();
                   }
                 });
               }
             }
           })
         }
         else {
           msgShow = "Authenticated party does not have authorization to make changes";
         }
      }
    
      return msgShow;
    }

    editPendingTransaction(pendingTransactionRow) {
        var linkParts  = pendingTransactionRow.href.split("/");
        let withdrawalSurrenderAction = new WithdrawalSurrenderAction();
        //set the type of withdrawal before opening the modal.
        switch(linkParts[linkParts.length-2].toLowerCase()) {
            case "partial": {
                withdrawalSurrenderAction.createPartialSurrenderPendingTransactionAction(
                    linkParts[linkParts.length-1]);
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction = 
                    withdrawalSurrenderAction;
                break;
            }
            case "full":{
                withdrawalSurrenderAction.createFullSurrenderPendingTransactionAction(
                    linkParts[linkParts.length-1]);
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction = 
                    withdrawalSurrenderAction;
                break; 
            }
            case "systematic":{
                withdrawalSurrenderAction.createSystematicWithdrawalPendingTransactionAction(
                    linkParts[linkParts.length-1]);
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction = 
                    withdrawalSurrenderAction;
                break; 
            }
               
        }
        // this is to open withdrawl modal window.
        document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
     }

    authorizeCurrentPageRole(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let otherRoleName = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-name-text")["value"] : "";
        OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName = otherRoleName.toUpperCase();
        let otherRoleNumber = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-phone-number-text")["value"] : "";
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        let optionParams = new OptionParams();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("authorize");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        let authorize = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'authorize' });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authorize' });
        let roleDetails = resourceService.getResourceFromAliasName('selectedRoles');
        let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        let customerRoleDetails = OcInfraModule.AppInjector.get(CommonService).customerRoleDetails;
        // this.requiredPayload.callerName = roleDetails.data.fullName ? roleDetails.data.fullName : document.getElementById("selected-roles-name-text")["value"];
        this.requiredPayload.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? otherRoleName.toUpperCase() : roleDetails.data.fullName ;
        this.requiredPayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role;
        let currentRole = customerRoleDetails && customerRoleDetails[0].summary && customerRoleDetails[0].summary.role +' Ssn';
        let key = _.camelCase(currentRole);
        currentPage == '/screen/customerDetails' ? this.requiredPayload.personKey = customerRoleDetails[0].summary[key] : '';
        currentPage == '/screen/agentDetails' ? this.requiredPayload.agentKey = agentRoleDetails.response._links.item[0].summary.agentNIPR : '';
        this.requiredPayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
        this.requiredPayload.csrName = sessionStorage.getItem('username');
        let payload = JSON.stringify(this.requiredPayload);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, payload, 'autorization').subscribe(response => {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
          OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
          OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            OcInfraModule.AppInjector.get(CommonService).authResponse = response;
            OcInfraModule.AppInjector.get(CommonService).selectedReason = '';
            OcInfraModule.AppInjector.get(CommonService).startCall();
            document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
            if(OcInfraModule.AppInjector.get(CommonService).otherRole){
                OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = true;
            }else{
                OcInfraModule.AppInjector.get(CommonService).authsucess = true;
            }
            setTimeout(function () {
                Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                    if (!accordian["classList"].contains("mat-expanded")) {
                        document.getElementById(accordian.id).click();
                    }
                });
            }, 2000);
            setTimeout(function () {
               OcInfraModule.AppInjector.get(CommonService).authorizedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
               let notesText = document.getElementById("close-call-notes-textarea_")["value"];
               let displayNote = "";
               if (OcInfraModule.AppInjector.get(CommonService).otherRole) {
                    displayNote = 'Role:' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name:' + otherRoleName.toUpperCase()  + ', Phone Number:' + otherRoleNumber;
                    document.getElementById('selected-roles-agent-otherrole-label_label').innerHTML = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole;
                    document.getElementById('selected-roles-other-role-full-name-label_label').innerHTML = 'Full Name: ' + otherRoleName.toUpperCase() ;
                } else {
                  displayNote = 'Role:' + roleDetails.data.role + ', Full Name:' + roleDetails.data.fullName;
                }
                document.getElementById('close-call-notes-textarea_')["value"] = notesText ? displayNote + ',' + notesText : displayNote;
                OcInfraModule.AppInjector.get(CommonService).authResponse = response;
            }, 1000);
        },error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
        setTimeout(function () {
            Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                if (accordian["classList"].contains("mat-expanded")) {
                    document.getElementById(accordian.id).click();
                }
            });
        }, 2000);
        const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        resourceService.updateCreateAliasName('authorizeSucess', selectedRoleSummary.resourceURL, true);
    }

    selectReason(params) {
        OcInfraModule.AppInjector.get(CommonService).selectedReason = params.value;
    }

    disabkeOk() {
        return true;
    }

    closeCallAgent(){
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'closeCall' });
        let self = this;
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
        let authResponseSummary = _.filter(this.authResponse.updateResponse._options.links, function (o) { return o.method === 'PATCH'; });
        let conformation = _.pickBy(this.authResponse.updateResponse.data, function (value, key) {
            return key == 'confirmationNumber' || key == 'contactType' || key == 'contactSessionID';
        });
        OcInfraModule.AppInjector.get(CommonService).confirmationNo || OcInfraModule.AppInjector.get(CommonService).tir2Ticket ? null : delete conformation['confirmationNumber'];
     let agentdetails =  OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
     let customerRoleDetails =  OcInfraModule.AppInjector.get(CommonService).customerRoleDetails;
     let currentRole = customerRoleDetails && customerRoleDetails[0].summary && customerRoleDetails[0].summary.role +' Ssn';
     let selectedAgentSummary = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
     let key = _.camelCase(currentRole);
     currentPage == '/screen/customerDetails' ? this.closeCallPayLoad.personKey = customerRoleDetails[0].summary[key].replace(/[^a-zA-Z0-9]/g, '') : '';
       currentPage == '/screen/customerDetails' ? this.closeCallPayLoad.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ?  OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : customerRoleDetails[0].summary.fullName: '';
       // this.requiredPayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role;
       this.closeCallPayLoad.contactType = "9";
        this.closeCallPayLoad.callStatus = "end";
        if(currentPage == '/screen/agentDetails' ){
        this.closeCallPayLoad.agentKey = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentNIPR ? agentdetails.response._links.item[0].summary.agentNIPR : '';
        this.closeCallPayLoad.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ?  OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.fullName ? agentdetails.response._links.item[0].summary.fullName : '';
        this.closeCallPayLoad.agencyName = selectedAgentSummary.agency_name;
        this.closeCallPayLoad.firmName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentFirmName ? agentdetails.response._links.item[0].summary.agentFirmName : '',
        this.closeCallPayLoad.agentFirstName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentFirstName ? agentdetails.response._links.item[0].summary.agentFirstName : '';
        this.closeCallPayLoad.agentLastName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentLastName ? agentdetails.response._links.item[0].summary.agentLastName : '';
        }
        this.closeCallPayLoad.reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        this.closeCallPayLoad.phoneNumber = document.getElementById("close-call-phone-number-text")["value"];
        if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
            this.checkedProperties.notes = document.getElementById("close-call-notes-textarea_")["value"];
        }
        this.closeCallPayLoad.text = JSON.stringify(this.checkedProperties);
        let payload = JSON.stringify(Object.assign(this.closeCallPayLoad, conformation));
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
       OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ role: null });
       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let header1 = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'transactions' });
    header1[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactId;
    OcInfraModule.AppInjector.get(APICallerService).changeHeaders({'statuaory_company_code' :null});
    OcInfraModule.AppInjector.get(APICallerService).patch(authResponseSummary[0].href, payload, header1[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            OcInfraModule.AppInjector.get(CommonService).myCallHistory();
            setTimeout(function () {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
                OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
            }, 1000);
        },
        error =>{
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false }) 
        });

    }

    closeCallAndGoToHome(): void {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'closeCall' });
        let self = this;
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectRole = _.filter(roleList.response._links.item, function (o) { return o.summary.role == 'Owner';})
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
        let authResponseSummary = _.filter(this.authResponse.updateResponse._options.links, function (o) { return o.method === 'PATCH'; });
        let conformation = _.pickBy(this.authResponse.updateResponse.data, function (value, key) {
            return key == 'confirmationNumber' || key == 'contactType' || key == 'contactSessionID';
        });
        OcInfraModule.AppInjector.get(CommonService).confirmationNo || OcInfraModule.AppInjector.get(CommonService).confirmation ? null : delete conformation['confirmationNumber'];
        // OcInfraModule.AppInjector.get(CommonService).confirmationNo == false ? delete conformation['confirmationNumber'] : '';
        for (var i = 0; i < localStorage.length; i++) {
            let namesObj = {'policyDetails':'DETAILS','policySuspense':'SUSPENSE',"loanInfo":"LOAN","fundInfo":"FUNDS","selectedCoverage":"RIDERS","policyRevolvingError":"REVOLVING","selectedNote":"NOTES","policyValues":"VALUES","billingInfo":"BILLING"}
            if (localStorage.key(i) == "policyDetails" || localStorage.key(i) == 'policySuspense' || localStorage.key(i) == 'loanInfo' || localStorage.key(i) == 'fundInfo' || localStorage.key(i) == 'selectedCoverage' || localStorage.key(i) == 'policyRevolvingError' || localStorage.key(i) == 'selectedNote' || localStorage.key(i) == 'policyValues' || localStorage.key(i) == 'billingInfo') {
            for (let key in namesObj) {
                let item = {};
                key ==  localStorage.key(i) ? item[namesObj[localStorage.key(i)]] = JSON.parse(localStorage.getItem(localStorage.key(i))) : '';
                _.assign(this.checkedProperties, item);
            }
        }
        }
        let transactions = OcInfraModule.AppInjector.get(CommonService).allTransactions;
        transactions ? _.assign(this.checkedProperties, transactions) : null;
        let quotes = OcInfraModule.AppInjector.get(CommonService).quoteValues;
        quotes ? _.assign(this.checkedProperties, quotes) : null;
        this.closeCallPayLoad.callerName = selectedRoleSummary.data.payorFullName ? selectedRoleSummary.data.payorFullName : selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : document.getElementById("selected-roles-name-text")["value"];
        this.closeCallPayLoad.contractId = selectRole[0].summary.hostKey;
        this.closeCallPayLoad.contactType = "9";
        this.closeCallPayLoad.callStatus = "end";
        this.closeCallPayLoad.reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        this.closeCallPayLoad.phoneNumber = document.getElementById("close-call-phone-number-text")["value"];
        if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
            this.checkedProperties.notes = document.getElementById("close-call-notes-textarea_")["value"];
        }
        this.closeCallPayLoad.text = JSON.stringify(this.checkedProperties);
        let payload = JSON.stringify(Object.assign(this.closeCallPayLoad, conformation));
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
       OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ role: null });
       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let header1 = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'transactions' });
    header1[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactId;
    OcInfraModule.AppInjector.get(APICallerService).changeHeaders({'statuaory_company_code' :null});
    OcInfraModule.AppInjector.get(APICallerService).patch(authResponseSummary[0].href, payload, header1[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            OcInfraModule.AppInjector.get(CommonService).myCallHistory();
            setTimeout(function () {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
                OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
            }, 1000);
        },
        error =>{
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false }) 
        });
        if (this.isSurrenderSubmitted()) {
            let href = OcInfraModule.AppInjector.get(CommonService).surrenderWorkRequestHref;
            const payload = OcInfraModule.AppInjector.get(CommonService).surrenderWorkRequestPayload;
            let headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
            payload.comments = document.getElementById("close-call-comments-textarea_")["value"];
            OcInfraModule.AppInjector.get(CommonService).postCallback(href, payload, headers).subscribe(response => {
                if (response.success) {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                } else {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong while creating a Work type", 'Error', 0);
                }
            }, error => {
               OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong while creating a Work type", 'Error', 0);
            });
        }
    }

    hideProperties(field) {

        if (field && (field.value || field.type === "button")) {
            return true;
        }
        return false;
    }

    showAuthSucessFields(field) {
        if (OcInfraModule.AppInjector.get(CommonService).authsucess && field && field.value) {
            return true;
        }
        return false;
    }

    showAgentAuthSucessFields() {
        return OcInfraModule.AppInjector.get(CommonService).authsucess;
    }

    otherRoleAuthSucessFields() {
        if (OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
            return true;
        }
        return false;
    }
    showOtherRoleName() {
        if (OcInfraModule.AppInjector.get(CommonService).selectedOther) {
            return true;
        }
        return false;
    }

    getSelectedWorkItem(params) { 
        OcInfraModule.AppInjector.get(CommonService).deliveryMethod  = false;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType = params.value;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue = params.viewValue;            
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams = params;   
        let forms = ["STOPSUSP","STOPREISSU","VOIDREISSU","VOIDSUSP","FORMS","LTCNOTICE"];
        OcInfraModule.AppInjector.get(CommonService).otherKey = false;
        OcInfraModule.AppInjector.get(CommonService).oAmount = '';
        if (forms.includes(params.value)) {
            if(params.value == "FORMS"){
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'types1' });
                let formsUrl = params.href.slice(0, -5) + 'schemas/types/FORMS';
                OcInfraModule.AppInjector.get(CommonService).getCallback(formsUrl, header[0].headers).subscribe(response => {
                    OcInfraModule.AppInjector.get(CommonService).formsList = response.FORMS.properties.formsList;
                    document.getElementById("work-items-open-modal-icon-open-form-request-items-anchor").click();
                    params.value = null;
                })
            }else{
                document.getElementById("work-items-open-modal-icon-open-form-request-items-anchor").click();
                params.value = null;
            }
        } else {
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
            OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null });
        let selectedWorkItem = 'types/' + params.value;
        let optionParams = {
            "resourceSchema": selectedWorkItem
        };
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItem = selectedWorkItem;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceSchemaService = OcInfraModule.AppInjector.get(ResourceSchemaService);

        resourceSchemaService.getSchema(selectedWorkItem, optionParams, null, 'workitemsSchema').subscribe(schemaResponse => {
            OcInfraModule.AppInjector.get(CommonService).deliveryMethod = schemaResponse.data && schemaResponse.data.deliveryMethod ? true : false;
            document.getElementById("work-items-open-modal-icon-open-work-items-anchor").click();
            let workItemsSchemaAlias = resourceService.getAliasByAliasName("workitemsSchema");
            workItemsSchemaAlias.resourceSchema = selectedWorkItem;
            params.value = null;
        });
    }
    }

    selectedWorkItem(params) {
        OcInfraModule.AppInjector.get(CommonService).preFillIndicator = false;
        let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
        OcInfraModule.AppInjector.get(CommonService).policyNumber = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess ? policyDetails && policyDetails.data && policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber;
        OcInfraModule.AppInjector.get(CommonService).attachedFiles = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        OcInfraModule.AppInjector.get(CommonService).deliveryMethod = false;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType = params.value;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue = params.viewValue;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams = params;
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null });
        let selectedWorkItem = 'types/' + params.value;
        let optionParams = {
            "resourceSchema": selectedWorkItem
        };
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItem = selectedWorkItem;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceSchemaService = OcInfraModule.AppInjector.get(ResourceSchemaService);
        resourceSchemaService.getSchema(selectedWorkItem, optionParams, true, 'workitemsSchema').subscribe(schemaResponse => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            resourceSchemaService.updateSchemaMap(schemaResponse);
            OcInfraModule.AppInjector.get(CommonService).deliveryMethod = schemaResponse.data && schemaResponse.data.deliveryMethod ? true : false;
            currentPage == '/screen/customerDetails' ? document.getElementById("work-items-open-modal-icon-open-work-items-customer-anchor").click() : '';
            currentPage == '/screen/agentDetails' ? document.getElementById("work-items-open-modal-icon-open-work-items-agent-anchor").click() : '';
            let workItemsSchemaAlias = resourceService.getAliasByAliasName("workitemsSchema");
            workItemsSchemaAlias.resourceSchema = selectedWorkItem;
            params.value = null;
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
    }

    createWorkRequestItem(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let attachedFiles = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
        let supportedFiletype = [];
        let fileName = ["img", "jpg", "pdf", "jpeg", "docx", "xlsx",];
        if (!(!attachedFiles || !attachedFiles.length)) {
            attachedFiles.forEach(headerObj => {
                let key = Object.keys(headerObj)
                let filetype = fileName.includes(key[0].split('.').pop().toLowerCase());
                supportedFiletype.push(filetype);
            })
        };
        if (!(supportedFiletype.includes(false))) {
            const modalProperties = params.modal.renderer.data.sections[0].properties;
            let modalPayloadProperties = {};
            let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
            modalProperties.forEach(propertiesObj => {
                if (propertiesObj.value) {
                    modalPayloadProperties[propertiesObj.id] = propertiesObj.value
                    propertiesObj.id == "callerPhoneNumber" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value != '' ? propertiesObj.value.replace(/[^a-zA-Z0-9]/g, '') : '' : '';
                    propertiesObj.id == "phoneCallRequested" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value ? "Yes" : "No" : '';
                    propertiesObj.id == "firmName" ? modalPayloadProperties["agencyName"] = propertiesObj.value : null;
                    propertiesObj.id == "description" ? modalPayloadProperties[propertiesObj.id] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] : '';
                } else {
                    propertiesObj.id == "NIPR" ? modalPayloadProperties[propertiesObj.id] = agentRoleDetails.response._links.item[0].summary.agentNIPR : '';
                    
                    propertiesObj.id == "summaryOfIssue" ? modalPayloadProperties[propertiesObj.id] = document.getElementById('workitems-schema-summary-of-issue-text')["value"] : '';
                    propertiesObj.id == "phoneCallRequested" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value ? "Yes" : "No" : '';
                    propertiesObj.id == "firmName" ? modalPayloadProperties["agencyName"] = '' : null;
                }
            });
            let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const refreshWorkRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
            const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
            const authResponse = resourceService.getResourceFromAliasName('authRes');
            let roleDetails = resourceService.getResourceFromAliasName('selectedRoles');
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
            //  let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
            this.requiredPayload.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-name-text")["value"].toUpperCase : roleDetails.data.fullName;
            this.requiredPayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role;
            const payload = {
                "workType": "COMMOLS",
                "type": "OLS Tier 2 Ticket",
                "callerName": OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-name-text")["value"].toUpperCase : roleDetails.data.fullName,
                "callerRole": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role,
                "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
                "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
                "inContactID": OcInfraModule.AppInjector.get(CommonService).incontactId,
                "ticketPriority": "MODERATE",
                "origin_app": "csr_portal",
            };
            _.assign(payload, modalPayloadProperties)
            if (currentPage == '/screen/agentDetails') {
                let agentInfo = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
                let agentPayLoad = {
                    // "firmName": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentFirmName ? agentRoleDetails.response._links.item[0].summary.agentFirmName : '',
                    "advisorName": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length ? agentRoleDetails.response._links.item[0].summary.fullName : '',
                    "agencyName": agentInfo && agentInfo.agency_name ? agentInfo.agency_name : '',
                    "clientId": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.clientId ? agentRoleDetails.response._links.item[0].summary.clientId : '',
                    "agentFirstName": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentFirstName ? agentRoleDetails.response._links.item[0].summary.agentFirstName : '',
                    "agentLastName": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentLastName ? agentRoleDetails.response._links.item[0].summary.agentLastName : '',
                    "agentNumber": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length ? agentRoleDetails.response._links.item[0].summary.agentNIPR : '',
                    "AWDComments": document.getElementById('workitems-schema-description-of-issue-textarea_')["value"],
                    "emailRole": "ADVISOR",
                }
                _.assign(payload, agentPayLoad);
            }
            let roledetails = {};
            if (currentPage == '/screen/customerDetails') {
                let roleList = resourceService.getResourceFromAliasName('roles');
                let customerdetails = OcInfraModule.AppInjector.get(CommonService).customerDetails;
                let roleName = OcInfraModule.AppInjector.get(CommonService).otherRole ? customerdetails.summary.role : OcInfraModule.AppInjector.get(CommonService).selectedRole;
                let selectedRole = _.filter(roleList._links.item, function (o) { return o.summary.role == roleName; });
                let role = OcInfraModule.AppInjector.get(CommonService).otherRole ? customerdetails.summary.role : selectedRole[0].summary.role 
                if (role == "Owner") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "socialSecurityNumber": selectedRole[0].summary.ownerSsn.replace(/-/g, ''),
                        "ownerFirstName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.ownerFirstName ? selectedRole[0].summary.ownerFirstName : '',
                        "ownerLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.ownerLastName ? selectedRole[0].summary.ownerLastName : '', 
                        "ownerCompanyName": "",
                        "emailRole": "CLIENT",
                    }
                } else if (role == "Annuitant") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "socialSecurityNumber": selectedRole[0].summary.annuitantSsn.replace(/-/g, ''),
                        "insuredFirstName":  selectedRole && selectedRole[0].summary && selectedRole[0].summary.annuitantFirstName ? selectedRole[0].summary.annuitantFirstName : '',
                        "insuredLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.annuitantLastName ? selectedRole[0].summary.annuitantLastName : '',
                        "emailRole": "CLIENT",
                    }
                }
                else if (role == "Joint-Owner") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "socialSecurityNumber": selectedRole[0].summary.jointOwnerSsn.replace(/-/g, ''),
                        "jointOwnerFirstName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.jointOwnerFirstName ? selectedRole[0].summary.jointOwnerFirstName : '',
                        "jointOwnerLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.jointOwnerLastName ? selectedRole[0].summary.jointOwnerLastName : '',
                        "emailRole": "CLIENT",
                    }
                }
                _.assign(payload, roledetails);
            }
            for (const property in payload) {
                payload[property] == "" ? delete payload[property] : null;
            }
            !attachedFiles || !attachedFiles.length ? '' : payload['attachments'] = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
                if (response.success) {
                    OcInfraModule.AppInjector.get(CommonService).tir2Ticket = true;
                    OcInfraModule.AppInjector.get(CommonService).attachedFiles = [];
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                    setTimeout(() => {
                        let optionParams = new OptionParams();
                        optionParams.alias = "workRequestHistory";
                        optionParams.params = OcInfraModule.AppInjector.get(CommonService).instanceParams;
                        optionParams.headers = headers;
                        OcInfraModule.AppInjector.get(APICallerService).refresh(refreshWorkRequestHref, optionParams, 'RESET').subscribe(refreshResponse => {
                            params.modal.dialogRef.close();
                            setTimeout(() => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
                            }, 7000)
                        });
                    }, 5000);
                }
            }, error => {
                console.log('error', error);
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            })
        } else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Attachment format not allowed', 'Error', 0);
        }
    }

    chanageInsuredNameLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("insuredNameLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length && selectedIdentity) {
                selectedIdentity.data.role == 'Agent' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant / Joint Annuitant Full Name' : element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant Name';
            } else if (selectedIdentity && element.length ) {
                 selectedIdentity.data.role == 'Agent' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Insured / Joint Insured Full Name' :'';
            }
            return true;
        }
        return false;
    }

    chanageInsuredSsnLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("insuredSsnLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length) {
                element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant SSN';
            }
            return true;
        }
        return false;
    }

    chanageInsuredDobLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("insuredDobLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length) {
                element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant DOB';
            }
            return true;
        }
        return false;
    }

    covrageChecked(field) {
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
        let keys = Object.keys(this.selectdValues[0][field.alias]);
        switch (field.alias) {
            case 'selectedCoverage':
                let finalHtml = '';
                if (keys.length > 0) {
                    for (let i = 0; i < this.selectdValues.length; i++) {
                        let subKeys = Object.keys(this.selectdValues[0][field.alias]);
                        this.coveragehtml += '';
                        localStorage.setItem(field.alias, JSON.stringify(Object.assign(this.checkedProperties,this.selectdValues[0].selectedCoverage)));
                        for (var j = 0; j < subKeys.length; j++) {
                            Object.keys(this.selectdValues[0][field.alias][subKeys[j]]).forEach(key => {
                                 currencyFields.includes(key) ? this.selectdValues[0][field.alias][subKeys[j]][key].indexOf('$') >= 0 ? '' : this.selectdValues[0][field.alias][subKeys[j]][key] = '$' + this.selectdValues[0][field.alias][subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                                    this.coveragehtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectdValues[0][field.alias][subKeys[j]][key] + '</dd>';
                            })
                        }
                        finalHtml += this.coveragehtml
                    }
                    OcInfraModule.AppInjector.get(CommonService).coverageHtml = ' <span style="font-size:15px" class="pad-lAr-10px">RIDERS</span><dl class="pad-lAr-10px">' + finalHtml + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).coverageHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                break;
            case 'selectedNote':
                let notesHtml = '';
                if (keys.length > 0) {
                    this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
                    for (let i = 0; i < this.selectdValues.length; i++) {
                        console.log(this.selectdValues.length)
                        var subKeys = Object.keys(this.selectdValues[0][field.alias]);
                        this.coveragehtml += '';
                        localStorage.setItem(field.alias, JSON.stringify(Object.assign(this.checkedProperties,this.selectdValues[0].selectedNote)));
                        for (var j = 0; j < subKeys.length; j++) {
                            this.coveragehtml += '';
                            Object.keys(this.selectdValues[0][field.alias][subKeys[j]]).forEach(key => {
                                 currencyFields.includes(key) ? this.selectdValues[0][field.alias][subKeys[j]][key].indexOf('$') >= 0 ? '' : this.selectdValues[0][field.alias][subKeys[j]][key] = '$' + this.selectdValues[0][field.alias][subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                                    this.coveragehtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectdValues[0][field.alias][subKeys[j]][key] + '</dd>';
                            })
                        }
                        notesHtml += this.coveragehtml
                    }
                    OcInfraModule.AppInjector.get(CommonService).notesHtml = '<span style="font-size:15px" class="pad-lAr-10px">NOTES</span><dl class="pad-lAr-10px">' + notesHtml + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                break;
        }
    }

    policyValues(field, message) {
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let response = this.resourceService.getResourceFromAliasName(field.alias);
        let effectivedate = response.data.quote_effective_date;
        this.checkedProperties = JSON.parse(localStorage.getItem('policyValues'));
        this.checkedProperties == null ? this.checkedProperties = {} : '';
        if (field.value) {
            if (message) {
                if (this.checkedProperties[effectivedate]) {
                    this.checkedProperties[effectivedate]['Message'] = OcInfraModule.AppInjector.get(CommonService).valuesMesseage;
                } else {
                    this.checkedProperties[effectivedate] = JSON.parse("{}");
                    this.checkedProperties[effectivedate]["Effective Date"] = effectivedate;
                    this.checkedProperties[effectivedate]['Message'] = OcInfraModule.AppInjector.get(CommonService).valuesMesseage ? OcInfraModule.AppInjector.get(CommonService).valuesMesseage : 'No data in admin system.';
                }
            } else {
                let checkedId = field.id.slice(0, -7);
                if (this.checkedProperties[effectivedate]) {
                    this.checkedProperties[effectivedate][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
                } else {
                    this.checkedProperties[effectivedate] = JSON.parse("{}");
                    this.checkedProperties[effectivedate]["Effective Date"] = effectivedate;
                    this.checkedProperties[effectivedate][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
                }
            }
        }
        else {
            let unCheckedId = message ? 'Message' : selectedLang[field.label];
            Object.keys(this.checkedProperties).map(keys => {
                if (effectivedate === keys) {
                    Object.keys(this.checkedProperties[keys]).map(key => {
                        if (unCheckedId === key) {
                            delete this.checkedProperties[keys][unCheckedId];
                            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))
                        }
                    })
                    if (Object.keys(this.checkedProperties[keys]).length < 2) {
                        delete this.checkedProperties[keys];
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))

                    }
                }
            })
        }
        Object.keys(this.checkedProperties).forEach(keys => {
            Object.keys(this.checkedProperties[keys]).map(key => {
                currencyFields.includes(key) ? this.checkedProperties[keys][key].indexOf('$') >= 0 ? '' : this.checkedProperties[keys][key] = '$' + this.checkedProperties[keys][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[keys][key] + '</dd>'
            })
        })
        OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml = _.isEmpty(this.checkedProperties) ? '' : '<span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + this.html + '</dl>';;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))

    }

    getCheckedValues(field) {
        console.log(field);
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let response = resourceService.getResourceFromAliasName(field.alias);
        let aliasName: boolean = false;
        field.alias == 'policyValues' ? aliasName = true : aliasName = false;
        let selctedCoverage = []
        this.checkFormat(field);
        selctedCoverage = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
        let getResourceByHref = resourceService.getResourceByHref(field.href);
        let currentId = field.alias == 'selectedCoverage' ? "coverage_id" : "note_type_desc";
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        if (field.value) {
            let checkedId = field.id.slice(0, -7);
            if (field.alias === "selectedCoverage" || field.alias === "selectedNote") {
                let coverageChecked = []
                this.currentChecked = {}
                getResourceByHref.response[checkedId] = getResourceByHref.response[checkedId] ? getResourceByHref.response[checkedId]:'No data in admin system.';
                if (getResourceByHref.response[checkedId]) {
                    this.currentChecked[selectedLang[field.label]] = getResourceByHref.response[checkedId];
                    if (selctedCoverage.length > 0) {
                        if (field.alias == "selectedCoverage" ? selctedCoverage[0].hasOwnProperty([field.alias]) : selctedCoverage[0].hasOwnProperty([field.alias])) {
                            for (let i = 0; i < selctedCoverage.length; i++) {
                                let subKeys = Object.keys(selctedCoverage[0][field.alias]);
                                let existed: boolean = true;
                                if (subKeys.length > 0) {
                                    for (let j = 0; j < subKeys.length; j++) {
                                        if (subKeys[j] == getResourceByHref.response[currentId]) {
                                            Object.assign(selctedCoverage[0][field.alias][getResourceByHref.response[currentId]], this.currentChecked);
                                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                            existed = false;
                                            this.covrageChecked(field);
                                        } else if (j + 1 >= subKeys.length && existed == true) {
                                            this.checkedProperties[field.alias] = []
                                            this.checkedProperties[field.alias][getResourceByHref.response[currentId]] = this.currentChecked;
                                            Object.assign(selctedCoverage[0][field.alias], this.checkedProperties[field.alias]);
                                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                            this.covrageChecked(field);
                                        }
                                    }
                                } else {
                                    this.checkedProperties[field.alias] = []
                                    this.checkedProperties[field.alias][getResourceByHref.response[currentId]] = this.currentChecked;
                                    Object.assign(selctedCoverage[0][field.alias], this.checkedProperties[field.alias]);
                                    OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                    this.covrageChecked(field);
                                }
                            }
                        } else {
                            this.checkedProperties[field.alias] = []
                            field.alias == "selectedCoverage" ? this.checkedProperties[field.alias][getResourceByHref.response["coverage_id"]] = this.currentChecked : this.checkedProperties[field.alias][getResourceByHref.response["note_type_desc"]] = this.currentChecked;
                            Object.assign(selctedCoverage[0], this.checkedProperties)
                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                            this.covrageChecked(field);
                        }
                    } else {
                        this.checkedProperties[field.alias] = []
                        field.alias == "selectedCoverage" ? this.checkedProperties[field.alias][getResourceByHref.response["coverage_id"]] = this.currentChecked : this.checkedProperties[field.alias][getResourceByHref.response["note_type_desc"]] = this.currentChecked;
                        selctedCoverage.push(this.checkedProperties);
                        OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                        this.covrageChecked(field);
                    }
                }
            }
            else {
                this.savedProperties = localStorage.getItem(field.alias);
                if (this.savedProperties == null) {
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    this.savedProperties = localStorage.getItem(field.alias);
                }
                this.checkedProperties = JSON.parse(this.savedProperties)
                if (!aliasName) {
                    this.checkedProperties[selectedLang[field.label]] = response.data[checkedId] && response.data[checkedId] !=" " ? response.data[checkedId] : 'No data in admin system.';
                    let val = JSON.stringify(this.checkedProperties);
                    Object.keys(this.checkedProperties).forEach(key => {
                        currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';
                    })
                }
                if (field.alias == "billingInfo") {
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else if (aliasName) {
                    this.policyValues(field, false);
                } else {
                    OcInfraModule.AppInjector.get(CommonService).detailsHtml = '<span style="font-size:15px" class="pad-lAr-10px">DETAILS</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                } 
            }
        }
        else if (field.alias === "selectedCoverage" || field.alias === "selectedNote") {
            let unCheckedId = selectedLang[field.label]
            delete selctedCoverage[0][field.alias][getResourceByHref.response[currentId]][unCheckedId]
            let subKeys = Object.keys(selctedCoverage[0][field.alias]);
            let checked = [];
            for (let i = 0; i < subKeys.length; i++) {
                if (_.isEmpty(selctedCoverage[0][field.alias][subKeys[i]])) {
                    delete selctedCoverage[0][field.alias][getResourceByHref.response[currentId]]
                    checked.push(false)
                } else {
                    checked.push(true)
                }
            }
            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
            this.covrageChecked(field)
        }
        else {
            this.savedProperties = localStorage.getItem(field.alias)
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = selectedLang[field.label];
            if (aliasName) {
                this.policyValues(field, false);
            }
            Object.keys(this.checkedProperties).map(key => {
                if (unCheckedId === key) {
                    delete this.checkedProperties[unCheckedId];
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))
                    let requestedDetailSection = document.getElementsByClassName("requested_details_section")[0];

                    Object.keys(this.checkedProperties).forEach(key => {
                        currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>'
                    })
                    if (field.alias == "billingInfo") {
                        if (!_.isEmpty(this.checkedProperties)) {
                            OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        else {
                            OcInfraModule.AppInjector.get(CommonService).billingHtml = '';
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    }
                    else {
                        if (!_.isEmpty(this.checkedProperties)) {
                            OcInfraModule.AppInjector.get(CommonService).detailsHtml = '<span style="font-size:15px" class="pad-lAr-10px">DETAILS</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        } else {
                            OcInfraModule.AppInjector.get(CommonService).detailsHtml = '';
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    }
                }
            })
        }
    }

  getSuspenseCode(field) {
    let checkedSuspenseHistoryProperties = OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory;
    let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
    let checkedId = field.href.substr(-4);
    let suspenseHtml = '';
    if (field.value) {
        this.savedProperties = localStorage.getItem(field.alias);
        if (this.savedProperties == null) {
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        this.savedProperties = localStorage.getItem(field.alias);
        }
        this.checkedProperties = JSON.parse(this.savedProperties)
        this.checkedProperties[checkedId] = []
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let getResourceByHref = resourceService.getResourceByHref(policyDetails._links.suspense.href);
        let selectedPolicy = _.filter(getResourceByHref.response._links.item, function (o) { return o.href == field.href });
        this.currentChecked[selectedLang._SUSPENSE_AMOUNT] = selectedPolicy[0].summary.suspense_amount;
        this.currentChecked[selectedLang._EFFECTIVE_DATE] = selectedPolicy[0].summary.suspense_effective_date;
        this.checkedProperties[checkedId] = this.currentChecked;
        _.assign(checkedSuspenseHistoryProperties, this.checkedProperties);
        let subKeys = Object.keys(checkedSuspenseHistoryProperties);
        for (var j = 0; j < subKeys.length; j++) {
            Object.keys(checkedSuspenseHistoryProperties[subKeys[j]]).forEach(key => {
                key == selectedLang._SUSPENSE_AMOUNT ? checkedSuspenseHistoryProperties[subKeys[j]][key].indexOf('$') >= 0 ? '' : checkedSuspenseHistoryProperties[subKeys[j]][key] = '$' + checkedSuspenseHistoryProperties[subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                    suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSuspenseHistoryProperties[subKeys[j]][key] + '</dd>';
            })
        }
        this.html += suspenseHtml;
        OcInfraModule.AppInjector.get(CommonService).suspenseHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Suspense</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails()
        OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory = checkedSuspenseHistoryProperties;
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
    } else {
        delete checkedSuspenseHistoryProperties[checkedId];
        _.assign(this.checkedProperties, checkedSuspenseHistoryProperties);
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        let subKeys = Object.keys(checkedSuspenseHistoryProperties);
        for (var j = 0; j < subKeys.length; j++) {
            Object.keys(checkedSuspenseHistoryProperties[subKeys[j]]).forEach(key => {
                if(key == selectedLang._SUSPENSE_AMOUNT){
                suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(checkedSuspenseHistoryProperties[subKeys[j]][key]) + '</dd>';
                }
                else{
                    suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSuspenseHistoryProperties[subKeys[j]][key] + '</dd>';
                }
            })
        }
        this.html += suspenseHtml;
        let finalHtml = '';
        Object.keys(checkedSuspenseHistoryProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Suspense</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
        OcInfraModule.AppInjector.get(CommonService).suspenseHtml = finalHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory = checkedSuspenseHistoryProperties;
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
    }
}

getRevolvingError(field) {
    let checkedRevolvingErrorProperties = OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError;
    let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
    let checkedId = field.href.substr(-4);
    let revolvingHtml = '';
    if (field.value) {
        this.savedProperties = localStorage.getItem(field.alias);
            if (this.savedProperties == null) {
                localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem(field.alias);
            }
            this.checkedProperties = JSON.parse(this.savedProperties)
        this.checkedProperties[checkedId] = []
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let getResourceByHref = resourceService.getResourceByHref(policyDetails._links.revolvingError.href);
        let selectedPolicy = _.filter(getResourceByHref.response._links.item, function (o) { return o.href == field.href });
        this.currentChecked[selectedLang._TYPE] = selectedPolicy[0].summary.revolving_error_type_desc;
        this.checkedProperties[checkedId] = this.currentChecked;
        _.assign(checkedRevolvingErrorProperties,this.checkedProperties);
        let subKeys = Object.keys(checkedRevolvingErrorProperties);
        for (var j = 0; j < subKeys.length; j++) {
            Object.keys(checkedRevolvingErrorProperties[subKeys[j]]).forEach(key => {
                revolvingHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedRevolvingErrorProperties[subKeys[j]][key] + '</dd>';
            })
        }
        this.html += revolvingHtml;
        OcInfraModule.AppInjector.get(CommonService).revolvingHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Revolving</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError = checkedRevolvingErrorProperties;
        console.log("getResour", OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError);
    } else {
        delete checkedRevolvingErrorProperties[checkedId];
        _.assign(this.checkedProperties, checkedRevolvingErrorProperties);
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        let subKeys = Object.keys(checkedRevolvingErrorProperties);
        for (var j = 0; j < subKeys.length; j++) {
            Object.keys(checkedRevolvingErrorProperties[subKeys[j]]).forEach(key => {
                revolvingHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedRevolvingErrorProperties[subKeys[j]][key] + '</dd>';
            })
        }
        this.html += revolvingHtml;
        let finalHtml = '';
        Object.keys(checkedRevolvingErrorProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Revolving</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
        OcInfraModule.AppInjector.get(CommonService).revolvingHtml = finalHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError = checkedRevolvingErrorProperties;
    }
}
    checkFormat(field) {
        let check = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let checkedId = field.id.slice(0, -7);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let getResourceByHref = resourceService.getResourceByHref(field.href);
        if (field.value) {
            if (getResourceByHref._options.properties[checkedId] && getResourceByHref._options.properties[checkedId].format && getResourceByHref._options.properties[checkedId].format == 'currency') {
                check.push(selectedLang[field.label]);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = check;
                console.log(check);
            } else {

            }
        }
        else {
            let removed = _.remove(check, function (n) {
                return n != selectedLang[field.label]
            });
            console.log("removed", removed);
            OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = removed;
            console.log("removed", OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields)
        }

    }
    checkedBillingValue(field) {
        this.checkFormat(field);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        if (field.value) {
            let response = resourceService.getResourceFromAliasName(field.alias);
            this.savedProperties = localStorage.getItem('billingInfo');
            if (this.savedProperties == null) {
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem('billingInfo');
            }
            let checkedId = field.id.slice(0, -7);
            this.checkedProperties = JSON.parse(this.savedProperties)
            if (response.data[checkedId]) {
                this.checkedProperties[selectedLang[field.label]] = response.data[checkedId];
                let val = JSON.stringify(this.checkedProperties);
                Object.keys(this.checkedProperties).forEach(key => {
                     currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';
                })
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
                OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            }
        } else {
            this.savedProperties = localStorage.getItem('billingInfo')
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = selectedLang[field.label];
            Object.keys(this.checkedProperties).map(key => {
                let html = '';
                delete this.checkedProperties[unCheckedId];
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties))
                Object.keys(this.checkedProperties).forEach(key => {
                     currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';
                })
                if (!_.isEmpty(this.checkedProperties)) {
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                else {
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
            })
        }
    }

    loanValues(field, message) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let response = resourceService.getResourceFromAliasName(message ? 'loanValues' : field.alias);
        this.savedProperties = localStorage.getItem('loanInfo');
        if (this.savedProperties == null) {
            localStorage.setItem('loanInfo', JSON.stringify(this.checkedProperties));
            this.savedProperties = localStorage.getItem('loanInfo');
        }
        let objID = response.data.quote_effective_date + 'v';
        let effectivedate = response.data.quote_effective_date;
        if (field.value) {
            let checkedId = message ? 'Message' : field.id.slice(0, -7);
            this.checkedProperties = JSON.parse(this.savedProperties)
            if (this.checkedProperties[objID]) {
                message ? this.checkedProperties[objID][checkedId] = OcInfraModule.AppInjector.get(CommonService).loanMesseage ? OcInfraModule.AppInjector.get(CommonService).loanMesseage : 'No data in admin system.' : this.checkedProperties[objID][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
            } else {
                this.checkedProperties[objID] = JSON.parse("{}");
                this.checkedProperties[objID]["Effective Date"] = effectivedate;
                message ? this.checkedProperties[objID][checkedId] = OcInfraModule.AppInjector.get(CommonService).loanMesseage ? OcInfraModule.AppInjector.get(CommonService).loanMesseage : 'No data in admin system.' : this.checkedProperties[objID][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
            }
        }
        else {
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = message ? 'Message' : selectedLang[field.label];
            Object.keys(this.checkedProperties).map(keys => {
                if (objID === keys) {
                    Object.keys(this.checkedProperties[keys]).map(key => {
                        if (unCheckedId === key) {
                            delete this.checkedProperties[keys][unCheckedId];
                        }
                    })
                }
                if (Object.keys(this.checkedProperties[keys]).length < 2) {
                    delete this.checkedProperties[keys];
                }
            })
        }
        let html = '';
        Object.keys(this.checkedProperties).forEach(keys => {
            Object.keys(this.checkedProperties[keys]).forEach(key => {
                currencyFields.includes(key) ? this.checkedProperties[keys][key].indexOf('$') >= 0 ? '' : this.checkedProperties[keys][key] = '$' + this.checkedProperties[keys][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[keys][key] + '</dd>'
            })
        })
        localStorage.setItem('loanInfo', JSON.stringify(this.checkedProperties));
        _.isEmpty(this.checkedProperties) ? OcInfraModule.AppInjector.get(CommonService).loanHtml = '' : OcInfraModule.AppInjector.get(CommonService).loanHtml = html;
        OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    checkedLoanValues(field) {
       this.checkFormat(field);
          this.loanValues(field,false);
    }

    openClaimDetails(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
        let headers = { remote_user: OcInfraModule.AppInjector.get(CommonService).incontactId }
        OcInfraModule.AppInjector.get(CommonService).selectedClaimUrl = params
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        OcInfraModule.AppInjector.get(CommonService).optionCallback(policyDetails._links.claims.href, claimListHeader[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).deathClaimProperties = response._options.properties;
            OcInfraModule.AppInjector.get(CommonService).deathClaimRequiredProperties = response._options.required;
            OcInfraModule.AppInjector.get(CommonService).getCallback(params.href, claimListHeader[0].headers).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).claimDetails = response;
                if (response._embedded) {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
                    if (response._embedded.status_report.messages[0].severity == 'INFO') {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedWarningMessage(response._embedded.status_report.messages[0].message, '', 0);
                    } else {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response._embedded.status_report.messages[0].message, '', 0);
                    }
                } else {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
                    document.getElementById("claims-open-claim-items-anchor").click();
                    OcInfraModule.AppInjector.get(CommonService).showConfirmation('Add comments via the AWD Claim Transaction', 'OK', '', "Alert");
                }
            },
            error =>{
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error',0);
            });
        },
        error =>{
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
        });
    }

    openPaymentDetails(params) {
        let headers = { remote_user: OcInfraModule.AppInjector.get(CommonService).incontactId }
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        OcInfraModule.AppInjector.get(APICallerService).get(params.href).subscribe(response => {
            resourceService.updateCreateAliasName('selectedPayment', params.href, true);
            document.getElementById("payments-open-payment-items-anchor").click();
        });
    }

    highlightRow(searchResults) {
        const router = OcInfraModule.AppInjector.get(CommonService).routerService()
        if (searchResults && searchResults.length && router.url === "/screen/partySearch" ) {
            let i = 0;
            searchResults.forEach(element => {
                i++;
                if ( !element.summary.clientId && element.summary.msg && element.summary.msg != '') {
                    let ele = document.getElementById("_" + (i - 1));
                    ele ? ele.style.backgroundColor = "#FCE4E6" : '';
                    ele ? ele.style.color = "#EF655F" : '';
                    ele ? ele.style.border = "solid" : "";
                }
                else {
                    let ele = document.getElementById("_" + (i - 1));
                    ele ? ele.style.backgroundColor = "" : "";
                    ele ? ele.style.color = "" : "";
                    ele ? ele.style.border = "" : "";
                }
            })
        }
    }

    searchBarHandler(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).searchResults ={};
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code:null, remote_user: null, role: null, incontact_user:null });
        const self = this;
        const urlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "searchContracts"]);
        const optionParams: OptionParams = OcInfraModule.AppInjector.get(ResourceSchemaService).getPayload(params.field.alias);
        optionParams.alias = params.field.actionURL;
        const searchValue = optionParams.params[0] ? optionParams.params[0].value.trim() : false;
        OcInfraModule.AppInjector.get(CommonService).searchContractsValue = optionParams.params[0] ? optionParams.params[0].value.trim() : "";
        const router = OcInfraModule.AppInjector.get(CommonService).routerService()
        const warnMessage = "CURRENT SEARCH RESULTS ARE LIMITED TO 50. PLEASE ASK FOR ADDITIONAL CLIENT SPECIFIC INFORMATION REQUIRED TO NARROW SEARCH RESULTS"        
        if (!searchValue) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please enter search criteria", 'Error', 0);
            return false;
        }
        this.searchResults = [] ;
        this.res = {};
        let url: any = Configuration.config.hostURL.multiHostUrl[urlIndex]['url'] + params.field.actionURL;
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code:null });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, "RESET").subscribe(function (response) {
            this.searchResults = response._links.item;
            OcInfraModule.AppInjector.get(CommonService).searchResults =response;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
           if (this.searchResults && this.searchResults.length === 1 ) {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                self.highlightRow(this.searchResults);
                if (!this.searchResults[0].summary.clientId && this.searchResults[0].summary.msg && this.searchResults[0].summary.msg != "") {
                 document.getElementById("contracts-collection-search-text") ?  document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue :'';
                  let alertMsg =[];
                    let popUpMsg = this.searchResults[0].summary.msg .split(".");
                    alertMsg.push(this.searchResults[0].summary.msg .substring(this.searchResults[0].summary.msg.indexOf("."),0) + '.',popUpMsg[1].substring(popUpMsg[1].indexOf(":"),0) + ':');
                    alertMsg.push(this.searchResults[0].summary.contactLabel1 ? this.searchResults[0].summary.contactLabel1 + ": " + this.searchResults[0].summary.contactNumber1 : '', this.searchResults[0].summary.contactLabel2 ? this.searchResults[0].summary.contactLabel2 + ": " + this.searchResults[0].summary.contactNumber2 : '', this.searchResults[0].summary.contactLabel3 ? this.searchResults[0].summary.contactLabel3 + ": " + this.searchResults[0].summary.contactNumber3 : '');
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({content :alertMsg, custom:true}, 'CLOSE', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        res;
                    });
                } else {
                    self.openPolicyDetails(this.searchResults[0])
                }
            } else {
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/partySearch');
                let table = document.querySelector(".policy-search-result table tbody");
                let observer = new MutationObserver(function (mutation) {
                    if (router.url === "/screen/partySearch") {
                       let sResults= OcInfraModule.AppInjector.get(CommonService).searchResults;
                        self.highlightRowText(OcInfraModule.AppInjector.get(CommonService).searchContractsValue);
                        self.highlightRow(sResults._links.item)
                       if( !(sResults && sResults.response._links.item && sResults.response._links.item.length ===1) ){
                        if (this.searchResults && this.searchResultsresponse._links.item.length >= 50 && sResults._embedded && sResults._embedded.status_report) {
                            this.res._embedded.status_report.url = this.res.resourceURL;
                            OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                            OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults.responce._embedded.status_report);
                        } else {
                            OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                            sResults._embedded.status_report && sResults._embedded &&  sResults._embedded.status_report && sResults._embedded.status_report.messages && sResults._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults._embedded.status_report) : OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                        }
                    }
                    }
                });
                if (table) {
                    observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                } else {
                    var highLightInterval = setInterval(function () {
                        let sResults= OcInfraModule.AppInjector.get(CommonService).searchResults;
                        self.highlightRowText(OcInfraModule.AppInjector.get(CommonService).searchContractsValue);
                        self.highlightRow(sResults._links.item);
                        if( !(this.searchResults && this.searchResults.length ===1) ){
                        if (this.searchResults && this.searchResults.length >= 50 && this.res._embedded && this.res._embedded.status_report) {
                            this.res._embedded.status_report.url = this.res.resourceURL;
                            router.url === "/screen/partySearch" ?  OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [] :'';
                            OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(this.res._embedded.status_report);
                        } else {
                            router.url === "/screen/partySearch" ? OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [] :'';
                            sResults._embedded && sResults._embedded.status_report && sResults._embedded &&  sResults._embedded.status_report && sResults._embedded.status_report.messages && sResults._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults._embedded.status_report) : OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                             OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                        }
                        }
                        let table = document.querySelector(".policy-search-result table tbody");
                        if (table) {
                            observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                        }
                        if (document.querySelector(".policy-search-result table")) {
                            clearInterval(highLightInterval);
                        }
                    }, 500);
                }
            }
        }, error =>{
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
    }

    highlightRowText(searchValue) {
        const searchedColumnArray = document.getElementsByClassName('oc-label-2');
        document.getElementById("contracts-collection-search-text") ? document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue : '';
        const test = searchValue.split(" ").join("|");
        for (var i = 0; i < searchedColumnArray.length; i++) {
            if (searchedColumnArray[i]) {
                let query = searchValue.startsWith('*') ? new RegExp("(\\b" + "[a-zA-Z0-9]*" + test.slice(1) + "\\b)", "gim") : (searchValue.endsWith("*") || searchValue.endsWith("?") ? new RegExp("(\\b" + test.slice(0, -1) + "[a-zA-Z0-9]*\\b)", "gim") : new RegExp("(\\b" + test + "\\b)", "gim"));
                var elemInnerText = searchedColumnArray[i]["innerText"];
                if (!!~searchValue.indexOf('?')) {
                    const reg = searchValue.replace(/\?/g, '.');
                    const results = elemInnerText.match(new RegExp(reg, 'gim'));
                    console.log(results);
                    if (results && results.length) {
                        query = new RegExp("(\\b" + results[0] + "\\b)", "gim")
                        console.log("query1", query)
                    }
                }
                if (searchedColumnArray[i]["innerText"].indexOf("-") !== -1 && searchValue.replace(/-/g, '') === searchedColumnArray[i]["innerText"].replace(/-/g, '')) {
                    var newe = elemInnerText.replace(elemInnerText, "<span class='text-primary'>" + elemInnerText + "</span>");
                } else if (searchedColumnArray[i]["innerText"].indexOf("-") !== -1 && query.test(searchedColumnArray[i]["innerText"].replace(/-/g, ''))) {
                    var newe = elemInnerText.replace(elemInnerText, "<span class='text-primary'>" + elemInnerText + "</span>");
                } else {
                    if (searchValue.split(" ").length > 1 && !~searchValue.indexOf('?') && !~searchValue.indexOf('*')) {
                        const arr = searchValue.split(" ").sort((a, b) => b.length - a.length);
                        var newe = elemInnerText;
                        for (var j = 0; j < arr.length; j++) {
                            const sQuery = new RegExp("(\\b" + arr[j] + "\\b)", "gim");
                            newe = newe.replace(sQuery, "<span class='text-primary'>$1</span>");
                        }
                    } else {
                        var newe = elemInnerText.replace(query, "<span class='text-primary'>$1</span>");
                    }
                }
                searchedColumnArray[i].innerHTML = newe;
            };
        }
        document.getElementById("contracts-collection-search-text") ? document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue : '';
    }

    createDeathClaim(params) {
        const payload = {};
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestHref = resourceService.getHrefByAliasName("createClaim");
        const wizardSteps = params.modal.renderer.data.sections[0].properties[0].wizardSteps
        wizardSteps.forEach(step => {
            step.renderer.data.sections[0].properties.forEach(propertiesObj => {
                if (propertiesObj.value) {
                    payload[propertiesObj.id] = propertiesObj.value
                }
            });
        });
        console.log(payload)
        let headers = { remote_user: 'JZACHF' }
        let url = 'http://20.15.66.42:8083/bpo/oc/contracts/PPLK102VL9008473/claims'
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        OcInfraModule.AppInjector.get(APICallerService).post(url, payload).subscribe(response => {
            document.getElementById("modal__cross").click();
        });
    }

    openWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias' : "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }       
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }

    openAgentWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias' : "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
            OcInfraModule.AppInjector.get(CommonService).commentsUrl = response && response._links && response._links.comments && response._links.comments.href;
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }

    openCustomerWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias' : "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false})
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            OcInfraModule.AppInjector.get(CommonService).commentsUrl = response && response._links && response._links.comments && response._links.comments.href;
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }

    openCallHistoryDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true});
        OcInfraModule.AppInjector.get(CommonService).paramDetails = params;
        document.getElementById("policy-call-history-open-call-history-details-anchor").click();
    }

    SuspenseMesseage() {
        let policySuspenseDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySuspense');
        if (policySuspenseDetails) {
            return policySuspenseDetails.response._links.item && !policySuspenseDetails.response._links.item.length;
        }
    }
    getTitle(){
        let title = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue;
        return title;
    }

    disableCreateWorkRequestButton(params) {
        let preFillIndicator = OcInfraModule.AppInjector.get(CommonService).preFillIndicator;
        let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        let selectedAgentSummary = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
        let selectedCustomerSummary = OcInfraModule.AppInjector.get(CommonService).customerDetails;
        let emailValidator: Boolean = false;
        let statusChange : Boolean = false;
        let phoneFieldChange : Boolean = false;
        if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0] && !preFillIndicator) {
            if (currentPage == '/screen/agentDetails') {
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value = "USA" : '';
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[6].value = agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentFirmName ? agentRoleDetails.response._links.item[0].summary.agentFirmName : '';
                if (selectedAgentSummary.email_address && params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value == '') {
                    params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value = selectedAgentSummary.email_address.trim();
                }
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[16].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[16].value = document.getElementById('close-call-notes-textarea_')["value"] + document.getElementById('close-call-phone-number-text')["value"] : null;
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value == undefined ? null : OcInfraModule.AppInjector.get(CommonService).preFillIndicator = true;
            } else {
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value = "USA" : '';
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[14].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[14].value = document.getElementById('close-call-notes-textarea_')["value"] + document.getElementById('close-call-phone-number-text')["value"] : null;
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[3].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[3].value = OcInfraModule.AppInjector.get(CommonService).policyNumber : null;
                if (selectedCustomerSummary.summary && selectedCustomerSummary.summary.email_address && params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value == '') {
                    params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value = selectedCustomerSummary.summary.email_address.trim();
                }
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value == undefined ? null : OcInfraModule.AppInjector.get(CommonService).preFillIndicator = true;
            }
        }
        if ((OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess || OcInfraModule.AppInjector.get(CommonService).authsucess) && document.getElementById('selected-roles-nipr-text') && !document.getElementById('selected-roles-nipr-text')['value']) {
            document.getElementById('selected-roles-nipr-text')["value"] = agentRoleDetails.response._links.item[0].summary.agentNIPR;
        }
        document.querySelector(".createWorkItemModal .okButtonModal");
        if (!(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Caller not authorized';
            return true
        } else if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0]) {
            let workItem = OcInfraModule.AppInjector.get(CommonService).selectedWorkItem;
            if (params.modal.modal.componentInstance.renderer.resourceSchemaService.schemaMap[workItem].required.length) {
                let modalProperties = params.modal.modal.componentInstance.renderer.data.sections[0].properties;
                let required: any = [];
                modalProperties.forEach(propertiesObj => {
                    if( propertiesObj.id == "phoneCallRequested"){
                        propertiesObj.value == true ? statusChange = true : statusChange = false ;
                    }
                    if(propertiesObj.id == "callerPhoneNumber"){
                        let number = document.getElementById("workitems-schema-phone-text") ? document.getElementById("workitems-schema-phone-text")["value"] : '';
                        propertiesObj.value == '' ?  phoneFieldChange = true:  number && number.length >= 12  ?  phoneFieldChange = false:  phoneFieldChange = true;
                    }
                    if (propertiesObj.required == true) {
                        if (propertiesObj.value == '') {
                            propertiesObj.id == "description" ? document.getElementById('workitems-schema-description-of-issue-textarea_')['value'] == '' ? required.push(true) : required.push(false) : required.push(true);
                        } else {
                            let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            propertiesObj.id == "email" ? propertiesObj.value.match(emailFormat) ? required.push(false) : required.push(true) : required.push(false);
                            propertiesObj.id == "email" ? emailValidator = propertiesObj.value.match(emailFormat) ? true : false : '';
                        }
                    }
                })
                if (required.includes(true)) {
                    if (required.filter(value => { return value === true; }).length == 1) {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = emailValidator ? 'Enable button by completing required items' : "Invalid Email Address Format";
                    } else {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable button by completing required items'
                    }
                    return true;
                } else {
                    if(statusChange){
                        if(phoneFieldChange){
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable by Entering Phone Number'
                        return true;
                    }
                    else {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                    return false;
                    }
                }
                    else{
                    document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                    return false;
                    }
                };
            } else {
                document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                return false
            }
        }
    }

    returnCallRequested(params) {
        if(params.value){
            document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] == '' ? 'Return Phone Call Requested' : document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] + ' Return Phone Call Requested';
        }else{
            document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"].replace("Return Phone Call Requested", "");
        }
    }

    selectedCategory(params) {
        document.getElementById('workitems-schema-summary-of-issue-text')["value"] = params.viewValue;
    }

    disableCreateWorkItem(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        if(document.getElementById('workitems-schema-comments-textarea_') && !document.getElementById('workitems-schema-comments-textarea_')['value']) {
            if (selectedRoleSummary.data.role != undefined) {
                if (selectedRoleSummary.data.role == 'Payor') {
                    document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName ;
                } else {
                    document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.fullName;
                }
            }
            else{
                setTimeout(function () {
                    let name = document.getElementById("selected-roles-name-text")["value"];
                    let number = document.getElementById("selected-roles-phone-number-text")["value"];
                    document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
                }, 1000);
               
            }
        }
        
        document.querySelector(".createWorkItemModal .okButtonModal");
        if (!(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Caller not authorized';
            return true
        } else if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0]) {
            let workItem = OcInfraModule.AppInjector.get(CommonService).selectedWorkItem;
            if (params.modal.modal.componentInstance.renderer.resourceSchemaService.schemaMap[workItem].required.length) {
                let modalProperties = params.modal.modal.componentInstance.renderer.data.sections[0].properties;
                let required: any = [];
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.required == true) {
                        if (propertiesObj.value == '') {
                            required.push(true);
                        } else {
                            required.push(false);
                        }
                    }
                })
                if (required.includes(true)) {
                    document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable button by completing required items';
                    return true;
                } else {
                    document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                    return false;
                };
            } else {
                document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                return false
            }
        }
    }

    isPolicyAvailable() {
       if(OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails()) {
           return true;
       }
    }
    isAnnuity(){
       if(this.isPolicyAvailable()){
      let details= OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if(details.data.product_type.includes('A')){
            return true;
        }
        else{
            return false;
        }
       }   
    }
    isUL(){
        if(this.isPolicyAvailable()){
       let details= OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
         if(details.data.product_type.includes('U')){
             return true;
         }
         else{
             return false;
         }
        }   
     }
     isOlsAnnuity(){
        if(this.isPolicyAvailable()){
       let details= OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
         if(details.data.product_type.includes('A')){
             return true;
         }
         else{
             return false;
         }
        }   
        if(OcInfraModule.AppInjector.get(CommonService).isOlsAnnuity){
            return true;
        }
        else {
            return false;
        }
     }
     isOlsIul(){
        if(this.isPolicyAvailable()){
       let details= OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
         if(details.data.product_type.includes('U')){
             return true;
         }
         else{
             return false;
         }
        }  
        if(OcInfraModule.AppInjector.get(CommonService).isOlsIul){
            return true;
        }
        else {
            return false;
        } 
     }



     openWorkRequestSourceDoc(params) {
        const selectedWorkRequestHeaders = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequestSource");        
        OcInfraModule.AppInjector.get(CommonService).openSourceDocument(params.href, selectedWorkRequestHeaders);
    }

    disableAutoComplete(){
        if(document.getElementById( "contracts-collection-search-text" )){
             document.getElementById( "contracts-collection-search-text" ).setAttribute( "autocomplete","off" );
        }
        return true;
    }


    showWorkRequestfilter(params){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.workRequestHistory ? workRequestDetails : workRequestDetails;
    }

    openPartyDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true});
        document.getElementById("policy-call-history-open-party-details-anchor").click();
        OcInfraModule.AppInjector.get(CommonService).personURL = params.href;
    }

    rBNameVisible(params) {
        //var rbNameIndicator;
        
        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    //rbNameIndicator = false;
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                }
                else {
                    //rbNameIndicator = true;
                }
            }
            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }
                    
            }
        });
        //return rbNameIndicator;
        return true;
    }
    rBTypeVisible(params) {
        //var rbTypeIndicator;
        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    //rbTypeIndicator = false;
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                }
                else {
                    //rbTypeIndicator = true;
                }
            }

            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }
                    
            }
        });
        //return rbTypeIndicator;
        return true;
    }
    dbVisible(params) {
        var indicator;
        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'D') {
                    indicator = true;
                }
                else if (code !== undefined && code === 'E'){
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                    indicator = true;
                }
                else {
                    indicator = false;    
                }
            }
            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }
                    
            }
        });
        return indicator;
        //return true;
    }


disableUpdateBilling() {
    let billingMethod = OcInfraModule.AppInjector.get(CommonService).billingMethod ;
    let paymentDueDate = document.getElementById("billing-info-payment-due-date-date_input") ? document.getElementById("billing-info-payment-due-date-date_input")["value"] : undefined;
    let effectiveDate = document.getElementById("billing-info-effective-date-date_input") ? document.getElementById("billing-info-effective-date-date_input")["value"] : undefined;
    let billingAmount = document.getElementById("billing-info-modal-premium-text") ? document.getElementById("billing-info-modal-premium-text")["value"] : undefined;
    if (billingMethod && paymentDueDate && billingAmount && effectiveDate) {
        document.querySelector(".updateBilling .okButtonModal")['title'] = '';
        return false;
    } else {
        document.querySelector(".updateBilling .okButtonModal")['title'] = 'Enable by completing required items';
        return true;
    }
}

selectdBillingMethod(params){
    params.value == 'I' ? OcInfraModule.AppInjector.get(CommonService).billingMethod  = true: OcInfraModule.AppInjector.get(CommonService).billingMethod  = false;
    
}

    updateBilling(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let msg = 'Changing the Billing Method from PAC to another method will permanently remove the existing PAC Banking Information. Do you want to proceed?';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(msg, 'CANCEL', 'YES')
        dialogRef.afterClosed().subscribe(res => {
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
            let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
            if (res) {
                const authResponse = resourceService.getResourceFromAliasName('authRes');
                const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
                const modalProperties = params.modal.renderer.data.sections[0].properties
               
                let payload = {
                    "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
                    "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
                    "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
                    "type": "Billing Change Request",
                    "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
                    "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
                    "policyNumber": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : '',
                    "bill_option": null,
                    "modal_premium": null,
                    "payment_due_date": null,
                    "effective_date": null,
                    'company_code':header[0].headers.company_code,
                    'region_code' :header[0].headers.region_code,
                    'remote_user' :header[0].headers.remote_user,
                }
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.value) {
                        payload[propertiesObj.id] = propertiesObj.value
                    }
                });
               
                payload.effective_date = OcInfraModule.AppInjector.get(CommonService).formatDate(payload.effective_date);
                payload.payment_due_date = OcInfraModule.AppInjector.get(CommonService).formatDate(payload.payment_due_date);
                const url = OcInfraModule.AppInjector.get(CommonService).billingPatchHref;
                
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true})
                OcInfraModule.AppInjector.get(APICallerService).patch(url, payload).subscribe(response => {
                    // OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                    OcInfraModule.AppInjector.get(CommonService).billing = false;
                    OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
                        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Work Item Successful: Billing Change Request', 'Success');
                   
                    params.modal.dialogRef.close();
                },
                    error => {
                        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
                           // OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Work Item Successful: Billing Change Request', 'Success');
                          //OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error._embedded.status_report.messages[0].message, 'Error', 0);
                    }
                )
            } 
        });
    }

billingUpdate(){
    return OcInfraModule.AppInjector.get(CommonService).editBillingFields;
}
checkedannuityLoanMesseage(field) {
    if (field.value) {
        let  loanMsg = {}
        loanMsg['annuity']= {};
        loanMsg['annuity']['Message'] = field.label;
        localStorage.setItem('loanInfo', JSON.stringify(loanMsg));
        this.html =  '<dt class="pull-left">Loan</dt>' +'<span style="padding:5px;">'+ field.label + '</span>';
        OcInfraModule.AppInjector.get(CommonService).loanHtml= this.html;
    } else {
        localStorage.removeItem('loanInfo');
        OcInfraModule.AppInjector.get(CommonService).loanHtml= '';
    }
    OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
}

checkCallBoardData() {
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let callBoard = resourceService.getResourceFromAliasName('callBoard');
    if (callBoard) {
        OcInfraModule.AppInjector.get(CommonService).callBoadData= false;
        return false;
    } else {
        OcInfraModule.AppInjector.get(CommonService).callBoadData= true;
        return true;
    }
}

    checkAvgCallHandleTimeData() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let avgCallHandleTime = resourceService.getResourceFromAliasName('avgCallHandleTime');
        if (avgCallHandleTime) {
            OcInfraModule.AppInjector.get(CommonService).avgCallHandleTime= false;
            return false;
        } else {
            OcInfraModule.AppInjector.get(CommonService).avgCallHandleTime= true;
            return true;
        }
    }

    showWorkRequestRecords() {
        const resource = this.resourceService.getResourceFromAliasName("workRequestHistory");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsg() {
        const resource = this.resourceService.getResourceFromAliasName("workRequestHistory");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showOutgoingTable() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.outgoingmoney ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgOgm() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showHcoiTable() {
        const resource = this.resourceService.getResourceFromAliasName("historicalcoi");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historicalcoi ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length  : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgHcoi() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resource = resourceService.getResourceFromAliasName("historicalcoi");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showPaymentTable() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.paymenthistory ? resource && resource.response && resource.response._links && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgPayment() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showSuspenceTable() {
        const resource = this.resourceService.getResourceFromAliasName("policySuspense");
        return resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgSuspence() {
        const resource = this.resourceService.getResourceFromAliasName("policySuspense");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showWithdrawalTable() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historyFunds ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgWith() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    } 

    showRevolvingTable() {
        const resource = this.resourceService.getResourceFromAliasName("policyRevolvingError");
        return resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgRevolving() {
        const resource = this.resourceService.getResourceFromAliasName("policyRevolvingError");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showClaimsTable() {
        const resource = this.resourceService.getResourceFromAliasName("claimList");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsgClaims() {
        const resource = this.resourceService.getResourceFromAliasName("claimList");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showPaymentsTable() {
        const resource = this.resourceService.getResourceFromAliasName("payments");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsgPayments() {
        const resource = this.resourceService.getResourceFromAliasName("payments");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }

    checkClass() {
        let correspondenceCheckWrapper = document.querySelectorAll('div.mat-step-icon-state-number')
        if (correspondenceCheckWrapper && correspondenceCheckWrapper.length) {
            for (let i = 0; i < correspondenceCheckWrapper.length; i++) {
                correspondenceCheckWrapper[i]['classList'].add('mat-step-icon-state-edit');
            }
        }
        return true;
    }

    csrWidgetVisibility() {
        return OcInfraModule.AppInjector.get(CommonService).csrUser;
    }

    olsWidgetVisibility() {
        return OcInfraModule.AppInjector.get(CommonService).olsUser;
    }

    showNoRecordsmsgAgent() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchAgents');
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }

    showCustomerTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchClients');
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }

    showNoRecordsMsgCustomer() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchClients');
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }

    showAgentTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchAgents');
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }

    showNoRecordsMsgCallHistory() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCallHistory');
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    
    showPendingTransactionsTable() {
        const resource = this.resourceService.getResourceFromAliasName("pendingTransactions");
        return resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    
    showPendingTransactionsMsg() {
        const resource = this.resourceService.getResourceFromAliasName("pendingTransactions");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }

    showCallHistoryTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCallHistory');
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }

    policyDetailsAobVisible(){
        return OcInfraModule.AppInjector.get(CommonService).aobVisible;
    }
    policyDetailsPorfolioVisible(){
        return !OcInfraModule.AppInjector.get(CommonService).aobVisible;
    }
    gmibPayoutVisible(){
        return true;
    }
   
    chanageCsrNameLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("olsCsrLabelClass");
            if (OcInfraModule.AppInjector.get(CommonService).olsUser && element.length) {
              element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'OLS CSR Name';
            }
            return true;
        }
        return true;
    }
    
    confirmationNumber(){
        return !OcInfraModule.AppInjector.get(CommonService).tir2Ticket;
    }

    confirmationTransaction(){
        return !OcInfraModule.AppInjector.get(CommonService).confirmation;   
    } 
    
    confirmationNumberTir2Ticket(){
        if(OcInfraModule.AppInjector.get(CommonService).tir2Ticket && document.getElementById('autorization-label_label')){
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('autorization-label_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        }
        return OcInfraModule.AppInjector.get(CommonService).tir2Ticket;
    }
    confirmationTransactionVisible(){
        if(OcInfraModule.AppInjector.get(CommonService).confirmation && document.getElementById('autorization-label_label') ){
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('autorization-label_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        }
     
        return OcInfraModule.AppInjector.get(CommonService).confirmation;
    }
    showCustomerBanner(){
        if((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)){
            OcInfraModule.AppInjector.get(CommonService).agentBanner = false;
            return OcInfraModule.AppInjector.get(CommonService).agentBanner;
        } else {
            return OcInfraModule.AppInjector.get(CommonService).agentBanner;
        }
    }

    showAgentBanner(){
        if((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)){
            OcInfraModule.AppInjector.get(CommonService).customerBanner = false;
            return OcInfraModule.AppInjector.get(CommonService).customerBanner;
        } else {
            return OcInfraModule.AppInjector.get(CommonService).customerBanner;
        }
    }


     withdrawlSurrenderModalTitle = () => {
        const selectedWithdrawlSurrenderType = OcInfraModule.AppInjector.get(CommonService).selectedWithdrawlSurrenderType ? OcInfraModule.AppInjector.get(CommonService).selectedWithdrawlSurrenderType 
                    :OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction().getWithdrawalSurrenderType();;
        if (selectedWithdrawlSurrenderType === 'FULL_SURRENDER') {
            return 'FULL WITHDRAWAL';
        }
        else if (selectedWithdrawlSurrenderType === 'PARTIAL_SURRENDER') {
            return 'PARTIAL SURRENDER';
        }
        else if (selectedWithdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL') {
            return 'SYSTEMATIC WITHDRAWAL';
        }

        return  OcInfraModule.AppInjector.get(CommonService).selectedWithdrawlSurrenderType.replace("_", " ");
    }  

    formatPhoneNum(field){
        if (field && (field.value || field.type === "label")){
            let replacePhone = field.value.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            let element = document.getElementsByClassName("phoneNumberFormat");
            field.viewValue= replacePhone;
            return true;
        }
        return true;
    }

    disableWorkRequestHistory() {
        return !(OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess || OcInfraModule.AppInjector.get(CommonService).authsucess);
    }

    isSurrenderSubmitted() {
        return (OcInfraModule.AppInjector.get(CommonService).surrenderWorkRequestHref ? true : false);  
    }
}
