import { Component, OnInit } from '@angular/core';
import { OcInfraModule, NavigationService, APICallerService, Configuration,ResourceService } from '@diaas/ux-web';
import { CommonService } from './../../services/common.service';
import { AuthenticationService } from './../../services/authentication.service';
import { ToasterService } from 'angular2-toaster';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { GetCognitoDetails } from '../../../ocInfraConfig/ocinfra-config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private authUsername: String;

  private authPassword: String;

  private cognitoUser: any;

  public authMsg = {
    type: '',
    title: '',
    body: '',
    showCloseButton: true,
    timeout: 20000
  };

  public processErrors(msg: string, timeout?: boolean) {
    this.authMsg.title = "Error";
    this.authMsg.type = "error";
    this.authMsg.body = msg;
    this.toasterService.pop(this.authMsg);
    if (timeout) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  constructor(private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private toasterService: ToasterService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.dialog.closeAll();
    var userPoolId = GetCognitoDetails().userPoolId;
    var clientId = GetCognitoDetails().clientId;;
    var userPoolData = {
        UserPoolId : userPoolId,
        ClientId : clientId
    };
    this.authenticationService.userPool = new CognitoUserPool(userPoolData);
  }

  private executeLogin() {
    if (this.authenticationService.userPool != undefined) {
      var authenticationData = {
        Username : this.authUsername.valueOf(),
        Password : this.authPassword.valueOf()
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      var userData = {
          Username : this.authUsername.valueOf(),
          Pool : this.authenticationService.userPool
      };
      this.cognitoUser = new CognitoUser(userData);
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: response => {
          OcInfraModule.AppInjector.get(CommonService).userRole=response.idToken.payload["cognito:groups"][0];
          response.idToken.payload["cognito:groups"].length < 2 ? response.idToken.payload["cognito:groups"].includes("csr") ? OcInfraModule.AppInjector.get(CommonService).csrUser = true : OcInfraModule.AppInjector.get(CommonService).olsUser = true : OcInfraModule.AppInjector.get(CommonService).olsUser = true;
          window.sessionStorage.setItem('tokenId', response.getAccessToken().getJwtToken());
          window.sessionStorage.setItem('refreshToken', response.getRefreshToken().getToken());
          window.sessionStorage.setItem('username', this.authUsername.valueOf());
          setTimeout(() => {
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
            OcInfraModule.AppInjector.get(CommonService).authsucess = false;
            OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = false;
            OcInfraModule.AppInjector.get(CommonService).notesMsg = false;
          }, 1000);
          Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            if (headerObj.headers.hasOwnProperty("Authorization")) {
              headerObj.headers.Authorization = "Bearer " + response.getAccessToken().getJwtToken();
            }
            if (headerObj.headers.hasOwnProperty("wma_user")) {
              headerObj.headers.wma_user = window.sessionStorage.getItem('username');
            }
          });
            this.userprofile();
            this.authMsg.title = "Success";
            this.authMsg.type = "success";
            this.authMsg.body = 'Logon successful';
            this.toasterService.pop(this.authMsg);
        },
        onFailure: error => {
            this.handleErrors(error);
        },
      });
    }
  }

  private handleErrors(error) {
    this.processErrors(error.message, true);
  }

  private userprofile() {
    this.cognitoUser.getUserAttributes(function (error, userData){
      if (error) {
        this.handleErrors(error);
      } else {
        for (let i = 0; i < userData.length; i++) {
          if (userData[i].getName() === 'custom:systemuserids'){
            let systemUserIds = userData[i].getValue().toString().split(",");
            for (let j = 0; j < systemUserIds.length; j++) {
              let keyval = systemUserIds[j].toString().split(":");
              let key = keyval[0].toString();
              let value = keyval[1].toString();
              if (key === "AWD") {
                OcInfraModule.AppInjector.get(CommonService).awdUserId = value;
                Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                  if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias !== 'authorize') {
                      headerObj.headers.remote_user = value;
                  }
                });
              }
              if (key === "INCONTACT") {
                Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                  if (headerObj.headers.hasOwnProperty("user_id")|| headerObj.alias === 'authorize'|| headerObj.alias === 'closeCall' ) {
                    if(headerObj.alias === 'authorize' || headerObj.alias === 'closeCall') {
                      headerObj.headers.incontact_user = value;
                    } else{
                      headerObj.headers.user_id = value;
                    }
                    OcInfraModule.AppInjector.get(CommonService).incontactId= value;
                                     }
                });
              }
              if (key === "SKILLID") {
                Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                  if (headerObj.headers.hasOwnProperty("skill_id")) {
                      headerObj.headers.skill_id = value ? value : '';
                }
              });
              }
            }
          }
        }
      }
      OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/' + Configuration.config.landingPage);
      let callHistoryUrl = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'callHistory' });
      OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('callHistory', callHistoryUrl[0].url + OcInfraModule.AppInjector.get(CommonService).incontactId, true);
    });
  }
}
