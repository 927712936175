import { ServerResponse, APICallerService, Configuration } from '@diaas/ux-web';
import { ResourceService } from '@diaas/ux-web';
import { Headers } from '@angular/http';
import { OcInfraModule } from '@diaas/ux-web';
import { CustomFileUploader } from './../../model/custom-file-uploader';
import { Component, OnInit, ElementRef } from '@angular/core';
import { UploadComponent } from '@diaas/ux-web';
import * as _ from 'lodash';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';


@Component({
  selector: 'app-group-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})

/**
 Represents a file uploader.
 @class
 */


export class GroupUploadComponent extends UploadComponent  implements OnInit {
 constructor(public element: ElementRef, public commonService : CommonService) {
   super();
  }
  private aliasResourceURL: string;
  private multiple: false;
  private dragAndDrop: false;
  public escalate:boolean = false;
  public upload: boolean = false;
  public escalateDisable: boolean = false;
  public multipleAttach: boolean = false;
  public olsAttach: boolean = false;
  uploadAttachSuccss: boolean = true;
  //public payLoad:any;
  
  ngOnInit() {
    this.uploadAttachSuccss = true;
    this.commonService.escalateWorkRequest = false;
    this.upload = this.element.nativeElement.id == "source_upload" ?  true : false;
    this.escalate = this.element.nativeElement.id == "escalate" ? true : false;
    this.multipleAttach = this.element.nativeElement.id == "multiple-Attach" ? true : false;
    this.olsAttach = this.element.nativeElement.id == "ols-Attach" ? true : false;
    this.escalateDisable = (this.commonService.authsucess || this.commonService.otherRoleAuthsucess);
    const headers = [];
    const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName('selectedWorkRequestSource');
    const selectedWorkRequestHeaders = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequestSource");
    Object.keys(selectedWorkRequestHeaders).forEach(key => {
      headers.push({'name': key, 'value': [selectedWorkRequestHeaders[key]]});
    });

    const allowedFileTypes = ['image/jpg', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf', 'application/octet-stream'];

    if (this.olsAttach || this.upload) {
      this.uploader = new CustomFileUploader({url: alias && alias.href ? alias.href : '', headers: headers, allowedMimeType: allowedFileTypes });
      this.uploader.onWhenAddingFileFailed = (fileItem, filter) => {
        if (filter.name = "mimeType") {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Attachment format not allowed', 'Error', 0);
        }
      };
    } else {
      this.uploader = new CustomFileUploader({url: alias && alias.href ? alias.href : '', headers: headers});
    }

  }

  selectEscalate(event){
    this.commonService.escalateWorkRequest = event.checked;
  }
  
  myFiles:string [] = [];
  sMsg:string = '';


  getFileDetails(e) {
    let files = [];
    if (e.target.files.length) {
      if (e.target.files.length <= 1) {
        files.push(e.target.files[0])
        this.myFiles.push(e.target.files[0]);
      } else {
        for (var i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i])
          this.myFiles.push(e.target.files[i]);
        }
      }
      this.convertFileToBase64AndSet(files);
    }
  }

  removeFile(name, index) {
    this.base64 = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
    let files = [];
    this.base64.filter(element => {
      element.hasOwnProperty(name) ? '' : files.push(element);
    });
    this.myFiles.splice(index, 1);
    OcInfraModule.AppInjector.get(CommonService).attachedFiles = files;
  }

  base64 :any = [];
  uploadFiles () {
    const frmData = new FormData();
  let  reader = new FileReader();
    for (var i = 0; i < this.myFiles.length; i++) { 
   //   reader.readAsDataURL(this.myFiles[i]["fileName"])
      frmData.append("fileUpload", this.myFiles[i]);
    }
   //  this.base64 = [];
    this.convertFileToBase64AndSet(this.myFiles)
    let headers = Configuration.config.headers.defaultHeader;
    //headers.set('Content-Type', 'multipart/form-data')
   // this.payLoad.attachemets = this.base64;

  }
  
  async convertFileToBase64AndSet(fileList) {
    this.base64 = [] = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
    if (fileList.length > 0) {
      const filePathsPromises = [];
      fileList.forEach(file => {
        filePathsPromises.push(this.toBase64(file));
      });
      const filePaths = await Promise.all(filePathsPromises);
      for (let i = 0; i < fileList.length; i++) {
        let fileObj = {};
        fileObj[fileList[i].name] = filePaths[i] || '';
        console.log(fileObj);
        this.base64.push(fileObj);
      }
      OcInfraModule.AppInjector.get(CommonService).attachedFiles = this.base64;
    } else {
      OcInfraModule.AppInjector.get(CommonService).attachedFiles = [];
    }
  }

  //BASE 64 Conversion
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  clearUploadedFiles(filesuploaded) {   
    let that  = this;  
    let myVar = setTimeout(function(){
      filesuploaded.clearQueue();
      if(that.uploadAttachSuccss){
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Attachment(s) successfully Uploaded', 'Success');
        that.uploadAttachSuccss = false;
      }
    }, 3000); 
  }
}