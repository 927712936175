import { Component, ElementRef, OnInit, AfterViewInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { initialPartialSurrender, initialFullSurrender, partialMockResponse, fullMockResponse, fundTableDataMock, withdrawalsMockResponse, surrenderConfirmationValues, eftInformation, fullWithdrawalsMockResponse } from './constants';
import { CommonService } from '../../services/common.service';
import { WithdrawlSurrenderSurrender } from './surrenderComponent';
import { WithdrawlSurrenderPayment } from './paymentComponent';
import { WithdrawlSurrenderConfirmation } from './confirmationComponent';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { SystematicWithdrawlComponent } from './systematicWithdrawlComponent'
import { WithdrawalSurrenderAction } from './withdrawalSurrenderAction'
import { WithdrawlSurrenderQuote } from './quoteComponent';

const ALIAS_KEY: String = 'policyDetails'

@Component({
  selector: 'app-withdrawals',
  templateUrl: './withdrawalsSurrenderComponent.html',
  styleUrls: ['withdrawalsSurrenderComponent.scss'],
})

export class WithdrawalsSurrender implements OnInit {

  constructor(private growlService: AdvGrowlService) {};

  @ViewChild(WithdrawlSurrenderQuote) childQuote: WithdrawlSurrenderQuote;
  @ViewChild(WithdrawlSurrenderSurrender) childSurrender: WithdrawlSurrenderSurrender;
  @ViewChild(WithdrawlSurrenderPayment) childPayment: WithdrawlSurrenderPayment;
  @ViewChild(WithdrawlSurrenderConfirmation) childConfirmation: WithdrawlSurrenderConfirmation;
  @ViewChild(SystematicWithdrawlComponent) systematicWithdrawlStep: SystematicWithdrawlComponent;

  stepCompletionIndicator: object = {"systematicWithDrawlTab":false, "paymentTab":false, "surrenderTab":false, "quoteTab":false};
  
  /*
    Following are props to popogate to  child components 
  */
  withdrawlSurrenderType: any;
  withdrawalSurrenderAction: WithdrawalSurrenderAction;
  policyDetails: any;
  currentPolicyResourceURL: String;
  receivedQuoteDetails: any;
  surrenderDetails: any;
  initialSurrenderValues: any = {
    PARTIAL_SURRENDER: initialPartialSurrender,
    FULL_SURRENDER: initialFullSurrender
  }
  mockResponseValues: any = {
    PARTIAL_SURRENDER: partialMockResponse,
    FULL_SURRENDER: fullMockResponse
  }
  
  headers = { ...{ role: OcInfraModule.AppInjector.get(CommonService).authorizedRole, wma_user : window.sessionStorage.getItem('username')}, user_role:"csr", ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search")};  
  surrenderAPISource: Object = { "PARTIAL_SURRENDER": "/surrender/partial", "FULL_SURRENDER": "/surrender/full", "SYSTEMATIC_WITHDRAWL": "/surrender/systematic"};
  receivedSurrenderDetails: any;
  receivedPaymentDetails: any;
  disableTabs: boolean;
  alertMessags: { severity: string; message: string; }[];

  public withdrawlSurrenderModel: any;

  ngOnInit() {
    this.resetData();
    this.getSurrenderDetails();
    if (this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
      this.stepCompletionIndicator["quoteTab"] = this.childQuote? this.childQuote.isStepComplete(): false;
      this.stepCompletionIndicator["surrenderTab"]  = this.childSurrender? this.childSurrender.isStepComplete(): false;
      this.stepCompletionIndicator["paymentTab"] = this.childPayment? this.childPayment.isStepComplete() : false;
    } else {
      this.stepCompletionIndicator["systematicWithDrawlTab"] = this.systematicWithdrawlStep? this.systematicWithdrawlStep.isStepComplete(): false;
      this.stepCompletionIndicator["paymentTab"] = this.childPayment? this.childPayment.isStepComplete() : false;
    }
  }

  resetData() {
    // Fetches User's selection Partial vs Full and popogates to child components
    this.withdrawalSurrenderAction = OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction();
    
    if (this.withdrawalSurrenderAction.isUserMenuAction()) {
      this.withdrawlSurrenderType = OcInfraModule.AppInjector.get(CommonService).selectedWithdrawlSurrenderType
    } else {
      this.withdrawlSurrenderType = this.withdrawalSurrenderAction.getWithdrawalSurrenderType();
    }

    // fetches current policy details and propogates to child components.
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    this.policyDetails = resourceService.getResourceFromAliasName('policyDetails')? resourceService.getResourceFromAliasName('policyDetails').response : null;
    this.currentPolicyResourceURL = this.policyDetails ? this.policyDetails._links.self.href : null;
  }

  /** API INTEGRATION */
  getSurrenderDetails() {
    if (this.withdrawalSurrenderAction.isUserMenuAction()) {
      let href = this.currentPolicyResourceURL + this.surrenderAPISource[this.withdrawlSurrenderType];
      
      OcInfraModule.AppInjector.get(CommonService).getCallback(href, this.headers).subscribe(response => {
        this.surrenderDetails = response;
        if (response._embedded && response._embedded.status_report) {
          this.alertMessags = response._embedded.status_report.messages;
          for (let i=0; i<response._options.links.length; i++) {
            if (response._options.links[i].method == 'POST') {
              this.disableTabs = false;
            } else {
              this.disableTabs = true;
            }
          }
         
	        if (this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' && this.alertMessags && this.alertMessags.length > 0) {
          	this.disableTabs = true;
          }
        }
      }, error => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
       }
      });
    } 
    else {
      //get pending transaction details
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
      let pendingTransactionItems = pendingTransactions._links.item; 
      console.log(pendingTransactionItems);
      let selectedPend =  pendingTransactionItems.find(item => item.summary.message_key == this.withdrawalSurrenderAction.getMessageKey())
      if (selectedPend) {
        let getLink =  selectedPend.summary._options.links.find(link => link.method.toLowerCase() == "get");
        if (getLink) {
          OcInfraModule.AppInjector.get(CommonService).getCallback(getLink.href, this.headers).subscribe(response => {
            this.surrenderDetails = response;
            if (response._embedded && response._embedded.status_report) {
              this.alertMessags = response._embedded.status_report.messages;
            }
          }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
            }
          });
        }
      }
    }
  }

  toggleAlertMsg(event) {
    event.target.classList.toggle("alertMsg-overflow");
  }

  getQuoteValues(quoteValues) {
    this.receivedQuoteDetails = quoteValues;
  }

  onwithdrawlSurrenderModelChange(e) {
    this.withdrawlSurrenderModel = Object.assign([], e);
  }

  tabClick(e) {
    if (this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL') {
      this.stepCompletionIndicator["quoteTab"] = this.childQuote? this.childQuote.isStepComplete(): false;
      this.stepCompletionIndicator["surrenderTab"]  = this.childSurrender? this.childSurrender.isStepComplete(): false;
      this.stepCompletionIndicator["paymentTab"] = this.childPayment? this.childPayment.isStepComplete() : false;
    } else {
      this.stepCompletionIndicator["systematicWithDrawlTab"] = this.systematicWithdrawlStep? this.systematicWithdrawlStep.isStepComplete(): false;
      this.stepCompletionIndicator["paymentTab"] = this.childPayment? this.childPayment.isStepComplete() : false;
    }

    if (this.isConfirmationTab(e.selectedIndex)) {
      let authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
      this.receivedSurrenderDetails = this.withdrawlSurrenderType !== 'SYSTEMATIC_WITHDRAWL' ? this.childSurrender.getSurrenderValues() : { required_fields: this.systematicWithdrawlStep.isStepComplete() ? 'Y': 'N'};
      this.receivedPaymentDetails = this.childPayment.getPaymentValues();
      Object.assign(this.receivedPaymentDetails, this.receivedSurrenderDetails, { confirmationNumber: authResponse && authResponse.updateResponse && authResponse.updateResponse.data.confirmationNumber });
      this.surrenderDetails && this.surrenderDetails.firm_id ? this.receivedPaymentDetails.firm_id = this.surrenderDetails.firm_id : null;
      this.childConfirmation.confirmationValues(this.receivedPaymentDetails, this.surrenderDetails._links.scripts_url.href);
    }
  }

  isConfirmationTab(index) {
    return this.withdrawlSurrenderType == "SYSTEMATIC_WITHDRAWL" && index == 2 ? true : (index == 3);
  }
}
