import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',

})
export class TimerComponent implements OnInit {


  public countDown: string;
  public showTimer: boolean;
  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) {
  }
  ngOnInit() {

    this.commonService.change
      .subscribe(response => {
        this.showTimerEvent()
      });
  }

  public startTimer() {
    var duration = 0
    var timer = duration, minutes, seconds;
    var self = this;
    setInterval(function () {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      self.countDown = minutes + ":" + seconds;

      if (++timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  showTimerEvent() {
    this.showTimer = true;
    this.startTimer();
  }

}
