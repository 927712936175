import { Component, OnInit } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

@Component({
  selector: 'address-update',
  templateUrl: './addressUpdate.component.html',
  styleUrls: ['./addressUpdate.component.scss']
})

export class AddressUpdateComponent implements OnInit {

  constructor(private dialog: MatDialog, private growlService: AdvGrowlService) {};
  value:string;
  addressList: any[] = [];
  headers: any;
  partyResponse: any;
  policyDetails: any;
  addressProperties: any;
  addUpdateFormObj: any = {};
  addAddressEnabled: boolean = false;
  otherPolicyInfo: boolean = false;
  lodash: any;
  minDate: any = new Date();
  addressPostalCode: any;
  addressResponse: any;
  policyOwnerName: any;
  otherPolicyColumns: string[] = ['blank', 'name', 'policyNumber', 'productCodeDesc', 'policyStatusDesc', 'roleDesc'];
  additionalPolicies: any;
  oldAddress : any;
  selectedAdditionalPolicies: any[] = [];
  isAnnuity: boolean = false;
  disableAddress: boolean = false;
  disableSaveButton: boolean = true;
  stateChange: boolean = false;
  authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  disableAddressTermFia: boolean = false;

  ngOnInit() {
    this.headers = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
    let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
    let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    this.lodash = _;
    if (otherRoleSucess || authsucess) {
       this.headers[0].headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
    } else {
      this.headers[0].headers = this.headers[0].headers.hasOwnProperty('role') ? _.omit(this.headers[0].headers, ['role']) : this.headers[0].headers;
    }
    if (OcInfraModule.AppInjector.get(CommonService).personResponse.first_name) {
      this.policyOwnerName = OcInfraModule.AppInjector.get(CommonService).personResponse.last_name +', '+  OcInfraModule.AppInjector.get(CommonService).personResponse.first_name;
    }else{
      this.policyOwnerName = OcInfraModule.AppInjector.get(CommonService).personResponse.company_name;
    }
    this.policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
    this.disableAddressTermFia = OcInfraModule.AppInjector.get(CommonService).disableTermandFia;
    this.partyResponse = OcInfraModule.AppInjector.get(CommonService).partyResponse;
    this.isAnnuity = OcInfraModule.AppInjector.get(CommonService).isAnnuity;
    this.getAddress();
  }

  getAddress() {
    let roleList = this.resourceService.getResourceFromAliasName('roles');
    let getAddressHref = OcInfraModule.AppInjector.get(CommonService).personResponse._links['address'].href;
    let addressHeaders = this.headers[0].headers;
    addressHeaders.wma_user = window.sessionStorage.getItem('username');
    OcInfraModule.AppInjector.get(CommonService).getCallback(getAddressHref, addressHeaders).subscribe(addressResponse => {
      this.addressResponse = addressResponse;
     this.addressList = addressResponse._embedded.addressList;
     this.addressProperties = addressResponse._options.properties;
    //  this.addressResponse._embedded.addressList.indexOf('other_policy_info')
     // let additionalPolicies = this.addressResponse._embedded.addressList.filter(element => element.other_policy_info &&  element.other_policy_info.length);
    //  this.additionalPolicies = additionalPolicies.length ? _.sortBy(additionalPolicies[0].other_policy_info, 'directoryId') : false;
    this.additionalPolicies = OcInfraModule.AppInjector.get(CommonService).additionalPolicies; 
      const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles'); 
     let otherRoleAuthorize = selectedRoleSummary && selectedRoleSummary.data ? selectedRoleSummary.data.policyNumber :  _.filter(roleList._links.item, function (o) { return o.summary.role ==  this.partyResponse.role_name_desc; })
      let policiesToAdd = this.additionalPolicies.filter(e => {
       let policynum = selectedRoleSummary && selectedRoleSummary.data ? selectedRoleSummary.data.policyNumber : otherRoleAuthorize[0].summary.policyNumber;
        return  e.policyNumber == policynum; 
      });   
      this.selectedAdditionalPolicies.push(...policiesToAdd);
      OcInfraModule.AppInjector.get(CommonService).currentPolicy = this.selectedAdditionalPolicies;
    })
  }

  addAddressClick() {
    let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
    let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    this.addAddressEnabled = true;
    this.addUpdateFormObj = {}
    this.addressPostalCode = "";
    this.addressList.forEach(item => {item.editEnabled = false
      if(authsucess==true || otherRoleSucess==true){
        this.value="";
        } 
        else{
          this.value="Caller was not authorized";
        }});
  }
tooltip(key){
  let returnvalue = '';
  if(key === true){
    if(this.authsucess == true || this.otherRoleSucess == true){
      returnvalue="Authenticated party does not have authorization to make changes";
      } 
      else{
        returnvalue="Caller was not authorized";
      }
  }
  return returnvalue;
}
  addAddress(address) {
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const authResponse = resourceService.getResourceFromAliasName('authRes');
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');   
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
    let roleList = resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize =  _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false ;
    let addAddressHref = OcInfraModule.AppInjector.get(CommonService).personResponse._links['address'].href;   
    this.addUpdateFormObj['company_code'] = this.policyDetails.data.company_code;
    this.addUpdateFormObj.zip5 = this.addressPostalCode.split('-')[0];
    this.addUpdateFormObj.zip4 = this.addressPostalCode.split('-')[1];
    this.addressProperties.address_type.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.address_type ? this.addUpdateFormObj.address_type_desc = e.title : '';
    });
    this.addressProperties.country.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.country ? this.addUpdateFormObj.country_desc = e.title : '';
    });
    this.addressProperties.state.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.state ? this.addUpdateFormObj.state_desc = e.title : '';
    });
    this.addressProperties.country.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.country ? this.addUpdateFormObj.country_desc = e.title : '';
    });
    let blankFields = this.addressResponse._options.required.filter(element => {
      return !this.addUpdateFormObj[element] || this.addUpdateFormObj[element] === '';
    });
    let transationDetails = {
      "type": "Address Change",
      "hostKey":  otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.hostKey ? otherRoleAuthorize[0].summary.hostKey : '' : selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',      
      "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' :selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      'company_code':this.policyDetails.data.company_code,
      'region_code' :header[0].headers.region_code,
      'remote_user' :header[0].headers.remote_user,
      "origin_app":"csr_portal"
    }
    if (blankFields.length === 0) {
      Object.assign(this.addUpdateFormObj,transationDetails);
      let addressHeaders = this.headers[0].headers;
      addressHeaders.wma_user = window.sessionStorage.getItem('username');
      OcInfraModule.AppInjector.get(CommonService).postCallback(addAddressHref, this.addUpdateFormObj, addressHeaders).subscribe(response => {
        this.getAddress();
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Address added successfully', 'Success');
        this.addAddressEnabled = false;
        this.disableSaveButton = true;
        this.stateChange = false;
        this.selectedAdditionalPolicies = [];
        setTimeout(() =>{
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
        },5000);        
       }, error => {      
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        //  OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
        } else {
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Not authorized", 'Error', 0);
        }
      });
    } else {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please fill in the required fields", 'Error', 0);
    }    
  }

  updateAddressClick(element) {
    let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
    let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    this.addUpdateFormObj = _.cloneDeep(element);
    this.selectedAdditionalPolicies = [];
    this.stateChange = false;
    let policyInfo = OcInfraModule.AppInjector.get(CommonService).currentPolicy;
    this.selectedAdditionalPolicies.push(...policyInfo);
    this.addUpdateFormObj.editEnabled = true;
    this.addUpdateFormObj.zip4 = element.zip4;
    this.addUpdateFormObj.zip5 = element.zip5;
    this.addressPostalCode = element.zip5 + (element.zip4 && element.zip4 !== "" ? '-' + element.zip4 : '');
    this.addAddressEnabled = false;
    this.otherPolicyInfo = element.address_type_desc == 'Residence' ? true : false;
    for (let item of this.addressList) {
      if (item.address_id === element.address_id) {
        item.editEnabled = true;
      } else {
        item.editEnabled = false;
        if (authsucess == true || otherRoleSucess == true) {
          this.value = "Authenticated party does not have authorization to make changes";
          } 
        else {
          this.value = "Caller was not authorized";
          }
      }
    }
  }
  updateAddress(element) {
    this.addressList.forEach(item => {
      if (item.address_id == element.address_id) {
        this.oldAddress = item;
      }
    })
    let residenceChnage = this.selectedAdditionalPolicies.filter(e => {
      let nameArr = [];
      e.roleDesc.includes(",") ? nameArr = e.roleDesc.replace(/\s/g, '').split(',') : nameArr.push(e.roleDesc.replace(/\s/g, ''));
      return nameArr.includes("Owner"); 
    })
    let partyRoles = this.resourceService.getResourceFromAliasName('partyRoles');
    let partyDetails = [];
     partyDetails = partyRoles._links.item.filter(e => {
      let party = e.summary.role_name && e.summary.party_name ? e.summary.party_name == this.partyResponse.party_name && e.summary.role_name == 'OWN' : false;
      return party ? e : '';
    })
    if ((partyDetails.length && this.stateChange) || (residenceChnage.length && this.stateChange)) {
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('The Resident State will be updated on the policy, do you wish to continue?', 'NO', 'YES', 'alert', '340px');
      dialogRef.afterClosed().subscribe(res => {
        res ? this.address(element) : '';
      })
    }
    else {
      this.address(element);
    }
  }

  address(element) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    let updateAddressHref = element._links.item.href;
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const authResponse = resourceService.getResourceFromAliasName('authRes');
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');   
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
    let apiMethodType = element.address_type_desc == "Residence" ? 'postCallback' : 'patchCallback'; 
    let roleList = resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize =  _.isEmpty(selectedRoleSummary.data) ? _.filter( this.additionalPolicies , function (o) { return o.policyNumber  == policyDetails.data.policy_number; }) : false ;
    let otherRoleDetails =  _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false ;
    //  this.selectedAdditionalPolicies.forEach(e => {e.addressChange = true, e.emailIdChange = false});
    let payload = {
      "type": "Address Change",
      "hostKey":  otherRoleAuthorize ? otherRoleDetails[0] && otherRoleDetails[0].summary.hostKey ? otherRoleDetails[0].summary.hostKey : '' : selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',      
      "clientId": otherRoleAuthorize ? otherRoleDetails[0] && otherRoleDetails[0].summary.clientId ? otherRoleDetails[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      'company_code':this.policyDetails.data.company_code,
      'region_code' :header[0].headers.region_code,
      'remote_user' :header[0].headers.remote_user,
      'stateChange' : this.stateChange,
      'other_policy_info' : this.selectedAdditionalPolicies.length ? this.selectedAdditionalPolicies :  otherRoleAuthorize ,
      "origin_app":"csr_portal"
    }
    this.oldAddress = _.omit(this.oldAddress, ['_links', '_options', 'editEnabled']);
    this.oldAddress ?  payload['old_address'] = this.oldAddress : '';
    if(element.address_type === '01'){
      let patchAddressesHref = this.addressResponse._options.links.filter(element => {
        return element.rel === 'patch_addresses';
      });
      updateAddressHref = patchAddressesHref.length ? patchAddressesHref[0].href : element._links.item.href;
      apiMethodType = 'postCallback'
    }
    Object.assign(this.addUpdateFormObj, payload);
    let blankFields = this.addressResponse._options.required.filter(element => {
      return !this.addUpdateFormObj[element] || this.addUpdateFormObj[element] === '';
    });
    delete this.addUpdateFormObj.editEnabled;
    delete this.addUpdateFormObj._links;
    delete this.addUpdateFormObj._options;
    this.addUpdateFormObj.zip5 = this.addressPostalCode.split('-')[0];
    this.addUpdateFormObj.zip4 = this.addressPostalCode.split('-')[1];
    this.addressProperties.address_type.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.address_type ? this.addUpdateFormObj.address_type_desc = e.title : '';
    });
    this.addressProperties.country.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.country ? this.addUpdateFormObj.country_desc = e.title : '';
    });
    this.addressProperties.state.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.state ? this.addUpdateFormObj.state_desc = e.title : '';
    });
    this.addressProperties.country.oneOf.filter(e => {
      e.enum[0] == this.addUpdateFormObj.country ? this.addUpdateFormObj.country_desc = e.title : '';
    });
    if(blankFields.length === 0){
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
      let addressHeaders = this.headers[0].headers;
      addressHeaders.wma_user = window.sessionStorage.getItem('username');
      OcInfraModule.AppInjector.get(CommonService)[apiMethodType](updateAddressHref, this.addUpdateFormObj, addressHeaders).subscribe(response => {
        this.getAddress();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Address Change Processed Successfully', 'Success');
        delete this.addUpdateFormObj.editEnabled;
        this.disableSaveButton= true;
        this.stateChange = false;
        this.selectedAdditionalPolicies = [];
        setTimeout(() =>{
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
        },5000);        
      }, error => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
         // OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0); 
          setTimeout(() =>{
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
          },5000);       
        }else{
          OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Not authorized", 'Error', 0);
          setTimeout(() =>{
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, {'alias':"workRequestHistory",'headers':header[0].headers}).subscribe(refreshResponse => {});
          },5000); 
        }
      });
    }else {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please fill in the required fields", 'Error', 0); 
    }
  }

  formatPostalCode(e){
    if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
      e.preventDefault();
    } else {
      this.addressPostalCode =  e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
    }
  }

  otherPolicyClick(e, row){
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles'); 
    if(e.checked){
      let policiesToAdd = this.additionalPolicies.filter(e => {
          return e.directoryId === row.directoryId && e.policyNumber !== selectedRoleSummary.data.policyNumber   
      });      
      this.selectedAdditionalPolicies.push(...policiesToAdd);
    }else{
      let remainingPolicies = this.selectedAdditionalPolicies.filter(e => {
        return e.directoryId !== row.directoryId;
      })
      this.selectedAdditionalPolicies = remainingPolicies;      
    }  
  }

  getRowSpan(index, element){
   let polIndex =  _.findIndex(this.additionalPolicies, ['directoryId', element.directoryId]);

   let numOfPolicies = this.additionalPolicies.filter(e => {
    return e.directoryId === element.directoryId
   }).length

   if (polIndex === index && numOfPolicies > 0){
     return numOfPolicies;
   }else{
     return false;
   }
  }

  closeUpdate(address) {
    this.oldAddress = {};
    if(!_.isEqual(address, this.addUpdateFormObj) || this.selectedAdditionalPolicies.length){
      let content = 'Are you sure?  You have unsaved changes in the Addresses Tab. Do you wish to Continue?';
      let title = 'Alert - Update Address'
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title,'340px')
      dialogRef.afterClosed().subscribe(dialogResponse => {
        if(dialogResponse){
          address.editEnabled = false;
        }else{
          return false;
        }
      })
    }else{
      address.editEnabled = false;
    } 
    
  }

  onValueChange(event, fieldName) {
    let formObj = this.addUpdateFormObj;
    let currentObj = this.addressList;
    currentObj.forEach(item => {
      if (item.address_id == this.addUpdateFormObj.address_id) {
         fieldName == 'state' ?  _.isEqual(item, formObj) ? this.stateChange = false : this.stateChange = true : '';
        fieldName == 'addressPostalCode' ? '' : formObj[fieldName] = formObj[fieldName].toUpperCase();
        let zip5 = this.addressPostalCode.includes('-') ? this.addressPostalCode.split('-')[0] : this.addressPostalCode;
        let zip4 = this.addressPostalCode.includes('-') ? this.addressPostalCode.split('-')[1] : '';
        this.disableSaveButton = fieldName == 'addressPostalCode' ? item.zip5 == zip5 && item.zip4 == zip4 ? true : false : _.isEqual(item, formObj);
      }
    })
  }
}




