import { Component, OnInit, ViewChild,HostListener} from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, ResourceService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { DecimalPipe} from '@angular/common';
import { CurrencyPipe} from '@angular/common';
import { TwoDigitDecimaNumberDirective} from '../aceFormRequest.component/customTwoDigitDecimaNumberDirective';
import * as _ from 'lodash';

@Component({
   selector: 'ace-formrequest',
   templateUrl: './aceFormRequest.component.html',
   styleUrls: ['./aceFormRequest.component.scss']
})

export class AceFormRequestComponent implements OnInit {
   stopReissueCheck: FormGroup;
   voidPaymentRequestCheckReissue: FormGroup;
   stopPaymentRequestSuspenceFunds: FormGroup;
   voidPaymentRequestSuspenceFunds: FormGroup;
   claimsLongTermCare: FormGroup;
   constructor(private dialog: MatDialog, private growlService: AdvGrowlService, private formBuilder: FormBuilder,private decimalPipe:DecimalPipe, private currencyPipe : CurrencyPipe) { };
   formChekProperties: any = OcInfraModule.AppInjector.get(CommonService).formsList;
   checkProperties: any = this.formChekProperties;
   selectedFormRequest = [];
   selectedFormRequestDesc = [];
   checked: boolean = false;
   selectedRoleSummary: any;
   addComment: string = '';
   hoverMessage: string = '';
   disableCreate: boolean = true;
   authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
   otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
   registerForm: any;
   disableException: boolean = true;
   exceptionProcessing: boolean = false;
   role: boolean = false;
   commentRequired: boolean = true;
   disableField: boolean = false;
   selectedWorkItemType: any = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType;
   authorizationCheck: boolean = false;
   message: string;
   check: boolean = false;
   schema: any;
   myValue:string;
   maxlengthValue:string="13";
   maxDateNew = new Date();


   formatPostalCode(e) {
      e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
   }

   numbersOnly(e) {
      e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '';
   }
   
   formatAmount(e) {
      e.keyCode != 46 ? e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() :'' : '';
   }

   currencyFormat(e) {
      e.keyCode != 46 ? e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : '' : '';
      let finalNum = e.target.value + e.key;
      this.maxlengthValue = finalNum.indexOf(".") != -1 ? "16" : "13";
   }

   format(element, obj, fieldName) {
      let num = obj.value[fieldName] && obj.value[fieldName].replace(/\,/g, '').replace(/\$/g, '');
      element.target.value = num ? num : '';
      this.maxlengthValue = obj.value[fieldName] && obj.value[fieldName].indexOf(".") != -1 ? "16" : "13";
   }

   transformAmount(element, obj, fieldName) {
      let num = obj.value[fieldName].replace(/\,/g, '').replace(/\$/g, '');
      let newnum = this.currencyPipe.transform(num, '$');
      element.target.value = newnum;
   }

   cannotLocateCheckInfo(e, name) {
      let event = e.checked ? 'disable' : 'enable';
      this[name].get('OCMP') ? this[name].get('OCMP').reset() ? '' : this[name].get('OCMP')[event]({ emitEvent: true }) : '';
      this[name].get('OAMT') ? this[name].get('OAMT').reset() ? '' : this[name].get('OAMT')[event]({ emitEvent: true }) : '';
      this[name].get('OCDT') ? this[name].get('OCDT').reset() ? '' : this[name].get('OCDT')[event]({ emitEvent: true }) : '';
      this[name].get('OPAY') ? this[name].get('OCDT').reset() ? '' : this[name].get('OPAY')[event]({ emitEvent: true }) : '';
      this[name].get('CHK1') ? this[name].get('CHK1').reset() ? '' : this[name].get('CHK1')[event]({ emitEvent: true }) : '';
      this[name].get('ADTY') ? this[name].get('ADTY').reset() ? '' : this[name].get('ADTY')[event]({ emitEvent: true }) : '';
      this[name].get('CAM2') ? this[name].get('CAM2').reset() ? '' : this[name].get('CAM2')[event]({ emitEvent: true }) : '';
      this[name].get('NAM1') ? this[name].get('NAM1').reset() ? '' : this[name].get('NAM1')[event]({ emitEvent: true }) : '';
      this[name].get('ADD1') ? this[name].get('ADD1').reset() ? '' : this[name].get('ADD1')[event]({ emitEvent: true }) : '';
      this[name].get('ADD2') ? this[name].get('ADD2').reset() ? '' : this[name].get('ADD2')[event]({ emitEvent: true }) : '';
      this[name].get('ADD3') ? this[name].get('ADD3').reset() ? '' : this[name].get('ADD3')[event]({ emitEvent: true }) : '';
      this[name].get('CITY') ? this[name].get('CITY').reset() ? '' : this[name].get('CITY')[event]({ emitEvent: true }) : '';
      this[name].get('STC1') ? this[name].get('STC1').reset() ? '' : this[name].get('STC1')[event]({ emitEvent: true }) : '';
      this[name].get('ZIP1') ? this[name].get('ZIP1').reset() ? '' : this[name].get('ZIP1')[event]({ emitEvent: true }) : '';
   }
   onSubmit(formObj) {
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      const authResponse = resourceService.getResourceFromAliasName('authRes');
      const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      const payload = {
         "status": "CREATED",
         "CLTY": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType,
         "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
         "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
         "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "origin_app":"csr_portal"
        };
         let dateofInjury = new Date(formObj.value.dateofInjury);
         let newDateofInjury = dateofInjury.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
         let dateReported = new Date(formObj.value.dateReported);
         let newDateReported = dateReported.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      Object.entries(formObj.value).forEach(([key, val]) => {
         key == 'YN01' ? val == '' || val == false ? payload[key] = "YES" : payload[key] = "NO" : val == '' ? '' : payload[key] = val;
         key == "FNAR" ? val == '' ? '' : payload[key] = payload[key][0] : '';
         key == "ADTY" ? val == '' ? '' : payload[key] = payload[key][0].toUpperCase() : '';
        // key == "OAMT" ? val == '' ? '' : OcInfraModule.AppInjector.get(CommonService).otherKey ? '' : payload[key] = OcInfraModule.AppInjector.get(CommonService).oAmount : '';
         key== "dateReported" ? val == ''?  '': payload[key]= newDateReported:'';
         key== "dateofInjury" ? val == ''?  '': payload[key]= newDateofInjury:'';
      });
      this.createWorkRequest(workRequestHref, payload, headers)
   }

   selectedform(event, checkedform) {
      if (event.checked) {
         if (checkedform.enum[0] == "form_loan_request") {
            for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? '' : this.checkProperties.oneOf[i]['isChecked'] = false;
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? '' : this.checkProperties.oneOf[i].disable = true;
            }
         } else {
            for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? this.checkProperties.oneOf[i].disable = true : '';
            }
         }
         this.selectedFormRequest.push(checkedform.enum[0]);
         this.selectedFormRequestDesc.push(checkedform.title);
      } else {
         let is_checked = false;

         this.selectedFormRequest.splice(this.selectedFormRequest.indexOf(checkedform.enum[0]), 1);
         this.selectedFormRequestDesc.splice(this.selectedFormRequestDesc.indexOf(checkedform.title), 1);
         if (checkedform.enum[0] == "form_loan_request") {
            for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? '' : this.checkProperties.oneOf[i]['disable'] = false;
            }
         } else {
            for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? this.checkProperties.oneOf[i]['disable'] = true: '';
               is_checked = false;
               if (this.checkProperties.oneOf[i]['isChecked'] == true){
                  is_checked = false;
                  break;
               } else {
                  is_checked = true;
               }
            }
         }
         if (is_checked) {
            for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
               this.checkProperties.oneOf[i].enum[0] == "form_loan_request" ? this.checkProperties.oneOf[i]['disable'] = false : '';
            }
         }
      }
      this.exceptionProcessing ? this.addComment != '' && (this.authsucess || this.otherRoleSucess) ? this.disableCreate = false : this.disableCreate = true : this.selectedFormRequest.length && (this.authsucess || this.otherRoleSucess) ? this.disableCreate = false : this.disableCreate = true;
      this.authsucess || this.otherRoleSucess ?this.selectedFormRequest.length || this.exceptionProcessing?this.exceptionProcessing ?this.addComment ? this.hoverMessage = '' :  this.hoverMessage = 'Enable button by completing required items' :this.hoverMessage = '' :this.hoverMessage = 'Enable by making a Form selection or checking the Exception Processing check box' :this.hoverMessage = 'Caller was not authorized';
   }

   addCommentChange(event) {
      this.exceptionProcessing && (this.addComment != '' && event.target.value != '')  && (this.authsucess || this.otherRoleSucess) ? this.disableCreate = false : this.disableCreate = true;
      this.addComment != '' && event.target.value != '' ? this.commentRequired = false : this.commentRequired = true;
      this.authsucess || this.otherRoleSucess ? this.selectedFormRequest.length || this.exceptionProcessing ? this.exceptionProcessing ? this.addComment ? this.hoverMessage = '' : this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = 'Enable by making a Form selection or checking the Exception Processing check box' : this.hoverMessage = 'Caller was not authorized';
   }

   createFormRequestWorkItem() {
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
      const authResponse = resourceService.getResourceFromAliasName('authRes');
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
      headers.statuaory_company_code = policyDetails.data.statutory_company_code;
      let loanInterest ={
         "loan_interest_rate" : OcInfraModule.AppInjector.get(CommonService).loanInterestRate
      }
      let payload = {
         "status": "CREATED",
         "CLTY": "FORMS",
         "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
         "comments": this.exceptionProcessing ? this.addComment  + " Forms for exception processing "+ this.selectedFormRequestDesc : this.addComment,
         "attachedForms": this.selectedFormRequest,
         "attachedFormsDesc": this.selectedFormRequestDesc,
         "exception_processing" : this.exceptionProcessing.toString(),
         "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
         "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
         "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
         "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
         "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
         "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
         "origin_app":"csr_portal"
      }
         payload.attachedFormsDesc[0] == "Loan Form Request"  ? Object.assign(payload,loanInterest) : ''
         this.createWorkRequest(workRequestHref, payload, headers);
      // OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      // OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
      //    if (response.success) {
      //       OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
      //       document.getElementById("modal__cross").click();
      //       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      //       setTimeout(() => {
      //          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
      //             OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      //          });
      //       }, 5000);
      //    } else {
      //       response.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(response.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
      //       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      //       document.getElementById("modal__cross").click();
      //    }
      // },
      //    error => {
      //       OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      //       error.error && error.error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
      //    });
   }
   createWorkRequest(url, payload, headers) {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
      OcInfraModule.AppInjector.get(CommonService).postCallback(url, payload, headers).subscribe(response => {
         if (response.success) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            setTimeout(() => {
               OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                  document.getElementById("modal__cross").click();
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
               });
            }, 5000);
         } else {
            response.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(response.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            setTimeout(() => {
               OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                  OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                  document.getElementById("modal__cross").click();
               });
            }, 5000);
         }
      },
         error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            error.error && error.error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
            });
         });
   }
   exceptionChecked(e) {
      this.exceptionProcessing = e.checked;
      e.checked ? '' : this.addComment = '';
      e.checked ? this.disableException = false : this.disableException = true;
      this.exceptionProcessing ?  this.addComment != '' && (this.authsucess || this.otherRoleSucess) ? this.disableCreate = false : this.disableCreate = true : this.selectedFormRequest.length && (this.authsucess || this.otherRoleSucess) ? this.disableCreate = false : this.disableCreate = true;
      this.authsucess || this.otherRoleSucess ?this.selectedFormRequest.length || this.exceptionProcessing?this.exceptionProcessing ?this.addComment ? this.hoverMessage = '' :  this.hoverMessage = 'Enable button by completing required items' :this.hoverMessage = '' :this.hoverMessage = 'Enable by making a Form selection or checking the Exception Processing check box' :this.hoverMessage = 'Caller was not authorized';   }

   ngOnInit() {
      OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
      if (this.selectedWorkItemType =='FORMS') {
         for (let i = 0; i < this.checkProperties.oneOf.length; i++) {
            this.checkProperties.oneOf[i]['disable'] = false;
            this.checkProperties.oneOf[i]['isChecked'] = false;
         }
      }
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      if (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
         this.message = "Enable button by completing required items."
         this.authorizationCheck = true;
      } else {
         this.authorizationCheck = false;
         this.message = "Caller not authorized."
      }
      let params = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams;
     if(!(this.selectedWorkItemType =='FORMS')){
        let form = { "STOPREISSU" : "stopReissueCheck",
                     "VOIDREISSU" : "voidPaymentRequestCheckReissue", 
                     "STOPSUSP" : "stopPaymentRequestSuspenceFunds", 
                     "VOIDSUSP" : "voidPaymentRequestSuspenceFunds",
                     "LTCNOTICE" : "claimsLongTermCare"}
      let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
      let formsUrl = params.href.slice(0, -5) + 'schemas/types/' + this.selectedWorkItemType;
      OcInfraModule.AppInjector.get(CommonService).getCallback(formsUrl, header[0].headers).subscribe(response => {
         this.schema = response[this.selectedWorkItemType];
         this.createForm();
         let commentsValue;
         const resourceService = OcInfraModule.AppInjector.get(ResourceService);
         let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
         if(document.getElementById('#workrequestComment') && this.authorizationCheck) {
            if (selectedRoleSummary.data.role != undefined) {
                if (selectedRoleSummary.data.role == 'Payor') {
                  commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName ;
                } else {
                  commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.fullName;
                }
                this[form[this.selectedWorkItemType]].get('comments').setValue(commentsValue);
               // document.getElementById('#workrequestComment')["value"] = commentsValue;
            }
            else{
                    let name = document.getElementById("selected-roles-name-text")["value"];
                    let number = document.getElementById("selected-roles-phone-number-text")["value"];
                    commentsValue = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
                    this[form[this.selectedWorkItemType]].get('comments').setValue(commentsValue);
            }
        }
      })
   }
      this.authsucess || this.otherRoleSucess ? this.selectedFormRequest.length ? this.exceptionProcessing ? this.addComment ? this.hoverMessage = '' : this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = 'Enable by making a Form selection or checking the Exception Processing check box' : this.hoverMessage = 'Caller was not authorized';
      //  OcInfraModule.AppInjector.get(CommonService).role == 'OtherRole' ? this.role = true : this.role = false;
      let roleList = resourceService.getResourceFromAliasName('roles');
      this.selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner' });
   }
   numberOnly(e): boolean {
      {
       if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
         e.preventDefault();          
      }else{
          e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
         return true;
        }        
      }
   }
   createForm(): void {
      this.stopReissueCheck = this.formBuilder.group({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         NAM1: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         comments: new FormControl('')
      });
      this.voidPaymentRequestCheckReissue = new FormGroup({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         OPAY: new FormControl(''),
         CHK1: new FormControl(''),
         ADTY: new FormControl(''),
         CAM2: new FormControl(''),
         NAM1: new FormControl(''),
         ADD1: new FormControl(''),
         ADD2: new FormControl(''),
         ADD3: new FormControl(''),
         CITY: new FormControl(''),
         STC1: new FormControl(''),
         ZIP1: new FormControl(''),
         comments: new FormControl('')
      });
      this.stopPaymentRequestSuspenceFunds = new FormGroup({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         CHK1: new FormControl(''),
         comments: new FormControl('')
      });
      this.voidPaymentRequestSuspenceFunds = new FormGroup({
         FNAR: new FormControl(''),
         YN01: new FormControl(''),
         OCMP: new FormControl(''),
         OAMT: new FormControl(''),
         OCDT: new FormControl(''),
         CHK1: new FormControl(''),
         comments: new FormControl('')
      });
      this.claimsLongTermCare = new FormGroup({
         dateofInjury: new FormControl(''),
         dateReported: new FormControl(''),
         notifierFirstName: new FormControl(''),
         notifierLastName: new FormControl(''),
         notifierPhone: new FormControl(''),
         notifierCompany: new FormControl(''),
         notifierAddress: new FormControl(''),
         notifierAddress2: new FormControl(''),
         notifierCity: new FormControl(''),
         state: new FormControl(''),
         notifierFax : new FormControl(''),
         notifierPostalCode: new FormControl(''),
         comments: new FormControl('')
      });
   }
}
