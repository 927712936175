import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MomentModule } from 'ngx-moment';

import {
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatStepperModule, MatFormFieldModule,
    MatListModule, MatOptionModule,
    MatTableModule, MatCardModule, MatTabsModule, MatPaginatorModule, MatChipsModule
} from '@angular/material';

import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from './../app/services/common.service';
import { MenuComponent } from '../app/components/customMenu.component/customMenu.component';
import { FilterComponent } from '../app/components/customFilter.component/customFilter.component';
import { TimerComponent } from '../app/components/timer.component/timer.component';
import { AgentBOBHeaderComponent } from '../app/components/agentBOBHeader.component/agentBOBHeader.component';
import { SearchHighlightComponent } from '../app/components/searchHighlight.component/searchHighlight.component';
import { MessageComponent } from '../app/components/customMessage.component/message.component';
import { WizardComponent } from '../app/components/customWizard.component/customWizard.component';
import { FileUploadModule } from 'ng2-file-upload';
import { GroupUploadComponent } from '../app/components/upload.component/upload.component';
import { LoanHistoryTableComponent } from '../app/components/loanHistoryTable.component/loanHistoryTable.component';
import { FundTableComponent } from '../app/components/fundTable.component/fundTable.component';
import { CorrespondenceTableComponent } from '../app/components/correspondenceTable.component/correspondenceTable.component';
import { CallDetailComponent } from '../app/components/customCallDetail.component/customCallDetail.component';
import { BannerAlertComponent } from '../app/components/bannerAlert.component/bannerAlert.component';
import { PartyInformationComponent } from '../app/components/partyInformation.component/partyInformation.component';
import { AddressUpdateComponent } from '../app/components/addressUpdate.component/addressUpdate.component';
import { KnowledgeCenterTableComponent } from '../app/components/knowledgeCenterTable.component/knowledgeCenterTable.component';
import { IulFundTableComponent } from '../app/components/iulfundTable.component/iulfundTable.component';
import { CustomFieldComponent } from '../app/components/customFields.component/customFieldsComponent';
import { TableFilterComponent } from '../app/components/customTableFilter.component/customTableFilter.component';
import { AceFormRequestComponent } from '../app/components/aceFormRequest.component/aceFormRequest.component';
import { CorrespondenceFaxComponent } from '../app/components/correspondenceFax.component/correspondenceFax.component';
import { TwoDigitDecimaNumberDirective } from '../app/components/aceFormRequest.component/customTwoDigitDecimaNumberDirective';
import { CustomPolicyPortfolioComponent } from '../app/components/customPolicyPortfolio.component/custom-policy-portfolio.component';
import { AgentBookOfBusinessTableComponent } from '../app/components/agentBookOfBusinessComponent/agentBookOfBusinessComponent';
import { WithdrawalsSurrender } from '../app/components/withdrawlsSurrender/withdrawlsSurrenderComponent';
import { WithdrawlSurrenderQuote } from '../app/components/withdrawlsSurrender/quoteComponent';
import { WithdrawlSurrenderSurrender } from '../app/components/withdrawlsSurrender/surrenderComponent';
import { WithdrawlSurrenderPayment } from '../app/components/withdrawlsSurrender/paymentComponent';
import { WithdrawlSurrenderConfirmation } from '../app/components/withdrawlsSurrender/confirmationComponent';
import {SystematicWithdrawlComponent} from '../app/components/withdrawlsSurrender/systematicWithdrawlComponent'

/* */
@NgModule({
    imports: [OcInfraModule,
        FormsModule,
        ReactiveFormsModule,
        FileUploadModule,
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatStepperModule,
        MatListModule, MatSelectModule, MatOptionModule, MatButtonModule, MatTableModule, MatSortModule, MomentModule, MatCardModule, MatTabsModule, MatPaginatorModule, MatChipsModule],
    declarations: [MenuComponent, TimerComponent, AgentBOBHeaderComponent, MessageComponent, FilterComponent, WizardComponent, GroupUploadComponent, LoanHistoryTableComponent, FundTableComponent, IulFundTableComponent, CorrespondenceTableComponent, CallDetailComponent, BannerAlertComponent, PartyInformationComponent, KnowledgeCenterTableComponent, AddressUpdateComponent, CustomFieldComponent, TableFilterComponent, AceFormRequestComponent, CorrespondenceFaxComponent, TwoDigitDecimaNumberDirective, CustomPolicyPortfolioComponent, AgentBookOfBusinessTableComponent, SearchHighlightComponent, WithdrawalsSurrender,WithdrawlSurrenderQuote, WithdrawlSurrenderSurrender, WithdrawlSurrenderPayment, WithdrawlSurrenderConfirmation,SystematicWithdrawlComponent],
    exports: [MenuComponent, TimerComponent, AgentBOBHeaderComponent, MessageComponent, FilterComponent, WizardComponent, GroupUploadComponent, LoanHistoryTableComponent, FundTableComponent, IulFundTableComponent, CorrespondenceTableComponent, CallDetailComponent, BannerAlertComponent, PartyInformationComponent, KnowledgeCenterTableComponent, AddressUpdateComponent, CustomFieldComponent, TableFilterComponent, AceFormRequestComponent, CorrespondenceFaxComponent, TwoDigitDecimaNumberDirective, CustomPolicyPortfolioComponent, AgentBookOfBusinessTableComponent, SearchHighlightComponent, WithdrawalsSurrender,WithdrawlSurrenderQuote, WithdrawlSurrenderSurrender, WithdrawlSurrenderPayment, WithdrawlSurrenderConfirmation, SystematicWithdrawlComponent],
    providers: [CommonService]
})
export class SharedModule { }