import { Injectable, EventEmitter, Output } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router/src/interfaces';
import * as _ from 'lodash';
import { CommonService } from './../services/common.service';
import { OcInfraModule, APICallerService, ResourceSchemaService, Configuration, NavigationService } from '@diaas/ux-web';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/src/router_state';
import { MatDialog } from '@angular/material';
import { ConfirmdialogComponent, confirmDialogConfig } from './../components/confirmdialog/confirmdialog.component';
import { GetCognitoDetails } from '../../ocInfraConfig/ocinfra-config';
@Injectable()
export class AuthenticationService implements CanActivate {

    public logoutURL = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'logout' })[0].url;

    private header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authentication' });

    public config: any = {};

    public httpHeaders: Headers;

    public sessionCounter: number;

    public timeCounter: number;

    public logoutBody: any = {};

    public userPool: any;

    @Output()
    getHeaderProperties: EventEmitter<any> = new EventEmitter();

    constructor(private http: Http, private dialog: MatDialog) {
        this.resetHeaders();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const user = window.sessionStorage.getItem('username');
        const tokenId = "Bearer " + window.sessionStorage.getItem('tokenId');
        const localHeaders = OcInfraModule.AppInjector.get(APICallerService).headers;
        if (user && tokenId) {
            this.getHeaderProperties.emit(true);
            if (!localHeaders.Authorization || !this.httpHeaders.get('Authorization')) {
                this.httpHeaders.set('Authorization', tokenId);
                this.changeApiHeaders();
            }
            if (!this.sessionCounter) {
                this.sessionTimeOut();
            }
            if (!this.timeCounter) {
                this.refreshtoken();
            }
            return true;
        } else {
            this.getHeaderProperties.emit(false);
            window.location.href = window.location.href;
            return false;
        }
    }

    public resetHeaders() {
        this.httpHeaders = new Headers;
        // this.httpHeaders.set('X-IBM-Client-ID', this.header[0].headers.clientId);
        // this.httpHeaders.set('X-IBM-Client-Secret', this.header[0].headers.clientSecret);
        this.changeApiHeaders();
    }

    public changeApiHeaders() {
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(this.httpHeaders.toJSON());
        OcInfraModule.AppInjector.get(ResourceSchemaService).changeHeaders(this.httpHeaders.toJSON());
    }
     
    public sessionTimeOut() {
        var self = this;
        var minutes = true;
        var interval = minutes ? 60000 : 1000;
        var idleTimeout = 360; // (Minutes): if minutes = true : else (Seconds)
        var sessionTimeout = 720;
        let idleCounter = 0;
        this.sessionCounter = 0;
        var resetCounter = 0;
        //console.log("Session start time : ", new Date().toLocaleTimeString());
        //console.log("Session will expire on : ", new Date(new Date().getTime() + 60000 * 720).toLocaleTimeString());

        document.onmousemove = document.onkeypress = function () {
            idleCounter = 0;
        };
        var sessionInterval = setInterval(function () {
            if (++idleCounter >= idleTimeout) {
                //console.log("Session has expired due to inactivity: ", new Date().toLocaleTimeString())
                alert("Session has expired due to inactivity. Please login again");
                window.sessionStorage.clear();
                clearInterval(sessionInterval)
                window.location.reload();
            }
            if (++self.sessionCounter == sessionTimeout-5) {
                //console.log("Session has exceeded the maximum time allotted: ", new Date().toLocaleTimeString())
               // showConfirmationPopup('The Resident State will be updated on the policy, do you wish to continue?', 'NO', 'YES','alert','340px');
              this.dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup('Your current session will end in 5 minutes due to the maximum time allowed.  If you are still working, select Continue to extend the session.  Select End to end your session and return to the Login page. Any unsaved work will be lost.', 'End', 'Continue','alert','340px')
                this.dialogRef.afterClosed().subscribe(res => {
                    if(res){
                    }
                    else{
                      window.sessionStorage.clear();
                      clearInterval(sessionInterval)
                      window.location.reload();
                    }
                })
                
            }
            if(self.sessionCounter==sessionTimeout){
                this.dialogRef.close();
                alert("You will now be logged out and re-directed to the login page due to maximum time reached.");
                   window.sessionStorage.clear();
                   clearInterval(sessionInterval)
                   window.location.reload();
               }   
        }, interval);
    }
    
    
    
    
    public executeLogout() {
        var cognitoUser = this.userPool && this.userPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.signOut();
            this.getHeaderProperties.emit(false);
            this.resetHeaders();
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
            window.sessionStorage.clear();
            window.location.href = "/";
            OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.loginPage);
        } else {
            window.sessionStorage.clear();
            window.location.href = "/";
            OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.loginPage);
        }
    }
    public refreshtoken() {
        var minutes = true;
        var interval = minutes ? 3479999 : 1000;
        var sessionInterval = setInterval(function () {
            const URL = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias === 'refreshtoken' });
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("refreshtoken");
            let AuthParameters = {
                "REFRESH_TOKEN": window.sessionStorage.getItem('refreshToken')
            }
            let payload = {
                "AuthFlow": "REFRESH_TOKEN_AUTH",
                "ClientId": GetCognitoDetails().clientId,
                "AuthParameters": AuthParameters
            }
            OcInfraModule.AppInjector.get(CommonService).postCallback(URL[0].url, payload, headers).subscribe(response => {
                if (response.AuthenticationResult) {
                    window.sessionStorage.setItem('tokenId', response.AuthenticationResult.AccessToken);
                    Configuration.config.headers.defaultHeader.Authorization = "Bearer " + response.AuthenticationResult.AccessToken;
                    Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                        if (headerObj.headers.hasOwnProperty("Authorization")) {
                            headerObj.headers.Authorization = "Bearer " + response.AuthenticationResult.AccessToken;
                        }
                    });
                }
            });
        }, interval);
    }
}
