import { Property } from '@diaas/ux-web';
import { CustomTable } from '../model/custom-table';
import { CustomSelect } from '../model/custom-select';

export class CustomPropertyFactory {
  public instanceProperty(property: any): Property {
    switch (property.type) {
      case 'table':
        return new CustomTable(property).transformData(property);
      case 'select':
        return new CustomSelect(property).transformData(property);
      default:
        return null;
    }
  }
}