import { Component, OnInit, Input } from '@angular/core';
import { eftInformation} from './constants';
import * as _ from 'lodash';

interface Payment {
    value: String;
    viewValue: String;
}

@Component({
    selector: 'withdrawl-surrender-payment',
    templateUrl: './paymentComponent.html',
    styleUrls: ['./paymentComponent.scss']
})
export class WithdrawlSurrenderPayment implements OnInit {

    @Input() withdrawlSurrenderType: any;
    @Input() policyDetails: Object;
    @Input() surrenderDetails: any;
    paymentMethod: any[];
    surrenderEftInformation = eftInformation;
    eftInformation: boolean = false;
    distributionCode: any; 
    firstName: any;
    lastName: any;
    addressLine1: any;
    addressLine2: any;
    addressLine3: any;
    city: any;
    state: any;
    postalCode: any;
    country: any;
    overnightRequest: any;
    comment: String;
    requiredFields: any;
    surrenderPaymentMetod: any;
    responseOptions: any;
    disbursement: any;
    disableOvernightCheckbox: boolean;

    ngOnInit() {
        this.getPaymentDetails();
        this.disableOvernightCheckbox = true;
        this.overnightRequest = false;
    }

    paymentMethodChange(val) {
      this.eftInformation = val.value == "EFT";
      this.setEFTInformation();
      if (val.value == "Check") {
        this.disableOvernightCheckbox = false;
        this.overnightRequest = true;
      } else {
        this.overnightRequest = false;
        this.comment = '';
        this.disableOvernightCheckbox = true;
      }
    }

    setEFTInformation() {
      if (eftInformation) {
        for (let i = 0; i < eftInformation.length; i++) {
          if (eftInformation[i].label == 'Bank Name') {
            eftInformation[i].value = this.surrenderDetails.bank_name;
          } else if (eftInformation[i].label == 'Account Type') {
            eftInformation[i].value = this.surrenderDetails.account_type;
          } else if (eftInformation[i].label == 'Account Number') {
            eftInformation[i].value = this.surrenderDetails.account_number;
          } else if (eftInformation[i].label == 'Routing/Transit Number') {
            eftInformation[i].value = this.surrenderDetails.transit_number;
          }
        }
      }
    }

    getPaymentDetails() {
       this.responseOptions = this.surrenderDetails._options;
      this.distributionCode = this.surrenderDetails.distribution_code;
      this.requiredFields = this.responseOptions && this.responseOptions.required || [];
      this.paymentMethod = [];
      if (this.responseOptions.properties.payment_method && this.responseOptions.properties.payment_method.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.payment_method.oneOf.length; i++) {
          if (this.surrenderDetails.payment_method.includes(this.responseOptions.properties.payment_method.oneOf[i].title)) {
            this.paymentMethod.push({ value: this.responseOptions.properties.payment_method.oneOf[i].title, viewValue: this.responseOptions.properties.payment_method.oneOf[i].title })
          }
        }
      }
      this.firstName = this.surrenderDetails.payee_firstname;
      this.lastName = this.surrenderDetails.payee_lastname;
      this.addressLine1 = this.surrenderDetails.payee_address1;
      this.addressLine2 = this.surrenderDetails.payee_address2;
      this.addressLine3 = this.surrenderDetails.payee_address3;
      this.city = this.surrenderDetails.payee_city;
      this.state = this.surrenderDetails.payee_state;
      this.postalCode = this.surrenderDetails.payee_zip;
      this.country = this.surrenderDetails.payee_country;

      // in case of SWP and existing SWP availabel for policy , preselect payment method dropdown based on selected_payment_method attribute from SWP get call.
       if (this.surrenderDetails && this.surrenderDetails.selected_payment_method && this.withdrawlSurrenderType == "SYSTEMATIC_WITHDRAWL") {
         let currentPayMethod = this.surrenderDetails._options.properties.payment_method.oneOf.find(opt => opt.enum.includes(this.surrenderDetails.selected_payment_method));
         this.surrenderPaymentMetod  = currentPayMethod ?  currentPayMethod.title : "";
       }
    }

    isStepComplete() {
      let stepComplete = this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' ? this.checkRequiredFieldsforSysmtematicWithdrawal() : this.checkRequiredFields();
      return stepComplete == 'Y' ? true : false;
    }

    getPaymentValues() {
      if (this.responseOptions.properties.payment_method && this.responseOptions.properties.payment_method.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.payment_method.oneOf.length; i++) {
          if(this.responseOptions.properties.payment_method.oneOf[i].title == this.surrenderPaymentMetod) {
             this.disbursement = Object.assign({title: this.surrenderPaymentMetod, enum: this.responseOptions.properties.payment_method.oneOf[i].enum[0]});
          }
        }
      }  
      return {
        disbursement: this.disbursement,
        payee_firstname: this.firstName,
        payee_lastname: this.lastName,
        payee_address1: this.addressLine1,
        payee_address2: this.addressLine2,
        payee_address3: this.addressLine3,
        payee_city: this.city,
        payee_state: this.state,
        payee_zip: this.postalCode,
        payee_country: this.country,
        overnight :this.overnightRequest ? "Y" : "N",
        required_payment_fields: this.withdrawlSurrenderType === 'SYSTEMATIC_WITHDRAWL' ? this.checkRequiredFieldsforSysmtematicWithdrawal() : this.checkRequiredFields()
      };
    }

  checkRequiredFields() {
    if (this.surrenderPaymentMetod == 'Check' && this.overnightRequest) {
      return this.comment ? 'Y' : 'N';
    }
    return this.surrenderPaymentMetod ? 'Y' : 'N';
  }

  checkRequiredFieldsforSysmtematicWithdrawal() {
    return this.surrenderPaymentMetod ? 'Y' : 'N';
  }
}
