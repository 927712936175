import { FactoryLoader } from'@diaas/ux-web';
import { PartySearchFactory } from './partySearchFactory';
import { PolicyDetailsFactory } from './policyDetailsFactory';
import { DefaultCustomFactory } from './defaultCustomFactory';
import { CustomPropertyFactory } from './customPropertyFactory';
// import { OlsPolicyDetailsFactory } from './olsPolicyDetailsFactory';
export class CustomFactoryLoader implements FactoryLoader {
    getCustomFactory(factory: string) {
        switch (factory) {
            case 'partySearchFactory': return new PartySearchFactory();
            case 'policyDetailsFactory': return new PolicyDetailsFactory(); 
            case 'olsPolicyDetailsFactory': return new PolicyDetailsFactory();
            case 'agentDetailsFactory': return new PolicyDetailsFactory();
            case 'customerDetailsFactory': return new PolicyDetailsFactory();
            default : return new DefaultCustomFactory();
        }
    }
    getCustomPropertyFactory() {
        return new CustomPropertyFactory();
    }
}
