import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './customSpinner.component.html',
  styleUrls: ['./customSpinner.component.scss']
})

export class SpinnerComponent implements OnInit {

showSpinner:boolean = false;
edit;
getCss:boolean = false;
postCss:boolean = true;  

constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.showHideCustomSpinner
      .subscribe(params => {
        this.showHideSpinner(params)
      });
  }

  public showHideSpinner(params){
    this.showSpinner = params.showSpinner;
    this.edit = params.edit;
      
    }
  public addClass(){
    if(this.edit==true){
    let classess= {
      spinnerWrapperPost: this.postCss,
      spinnerWrapper: this.getCss
    };
    return classess;
    }
    if(this.edit==false){
      let classess={
        spinnerWrapperPost: this.getCss,
      spinnerWrapper: this.postCss
      };
      return classess;
    }
  }

}
