import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';

@Component({
  selector: 'iulfund-table',
  templateUrl: './iulfundTable.component.html',
})

export class IulFundTableComponent implements OnInit {

  displayedColumns: string[] = ['name','fund_type_desc','allocation_percent','interest_rate','fund_value'];
  selectedDate: Object = {};
  fundList:  any[] = [];
  noRecords: boolean = true;
  fundsObj: any = {};
  statusMsg: string="";
  message: boolean = false;
  olsPolicyfund: boolean = false;
  csrPolicyfund: boolean = false;

  constructor(public elementRef: ElementRef) {

  }

  ngOnInit() {
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (loanHistoryURL["funds"] && loanHistoryURL["funds"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("loanTransactionHistory");
      OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href, optionParams).subscribe(response => {
        console.log("funds response", response.response._links.item);
        this.fundList = response.response._links.item;
        this.noRecords = this.fundList && !this.fundList.length;
        if (response.response._embedded && response.response._embedded.status_report.messages) {
          response.response._embedded.status_report.messages.forEach(msgObj => {
            this.statusMsg += msgObj.message;
            this.message = true;
          });
        }
      });
    }
    this.olsPolicyfund = this.elementRef.nativeElement.id == 'iulOlsFundTable' ? true : false;
    this.csrPolicyfund = this.elementRef.nativeElement.id == 'iulfundTable' ? true : false;

  } 

  testChange(event, data, selectedValue, selectedKey, columnName,valueType){
    let apiName = 'funds';
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue= valueType=='currency'? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;
    let selectedVal;
    if(this.selectedDate[data.number] && columnName !== 'Investment'){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        event.checked ? this.selectedDate[data.number].push(selectedKey+':'+ selectedVal) : this.selectedDate[data.number].splice(this.selectedDate[data.number].indexOf(selectedKey+':'+ selectedVal), 1)
    } else { 
      if(!this.selectedDate[data.number]){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.selectedDate[data.number] = new Array(selectedKey + ':' + selectedVal);
      }
    }

    if(this.selectedDate[data.number].length == 1 && this.selectedDate[data.number][0].indexOf('Investment') !== -1 && !event.checked){
      delete this.selectedDate[data.number];
    } else {
      if (this.selectedDate[data.number][0].indexOf('Investment') === -1) {
        this.selectedDate[data.number].unshift("Investment:" + data.name);
      }
    }

    let html =  '';
    if(Object.keys(this.selectedDate).length > 0){
      html +=  '<dl class="pad-lAr-10px">';
      Object.keys(this.selectedDate).forEach(key => {
        this.selectedDate[key].forEach(element => {
          if (currencyFields.includes(element.split(":")[0])) {
            html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;">&nbsp;'+ OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
          } else {
            html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;">&nbsp;'+element.split(":")[1] + '</dd>';
          }
        });
      });
      html += '</dl>';
    }

    localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  checkedMesseage(event, selectedValue, selectedKey) {
    let html = '';
    if (event.checked) {
      this.selectedDate["annuity"] = new Array(selectedKey + ':' + selectedValue ? selectedValue : 'No data in admin system.');
      localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
      html = '<dl class=""><dt class="pull-left">' + selectedKey + ':</dt><dd style="font-weight: 200;">&nbsp;' + selectedValue + '</dd></d1>';
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = html;
    } else {
      localStorage.removeItem('fundInfo');
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = '';
    }

    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
}

}