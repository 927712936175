import { Component } from '@angular/core';
import { OcInfraModule } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private location: LocationStrategy) {
    this.location.onPopState(() => {
      OcInfraModule.AppInjector.get(CommonService).backButton = true;
    });
  }
  title = 'Omnichannel CSR';
}
