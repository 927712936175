import { Component, OnInit, Input } from '@angular/core';
import { surrenderConfirmationValues, Payload,partialCallNoteTransaction,fullSurrenderCallNoteTransaction, systematicWithdrawalNoteTransaction,systematicWithdrawalRemoveTransaction } from './constants';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, Configuration, ResourceService } from '@diaas/ux-web';
import { ToasterService } from 'angular2-toaster';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { findIndex } from 'lodash';

@Component({
  selector: 'withdrawl-surrender-confirmation',
  templateUrl: './confirmationComponent.html',
  styleUrls: ['./confirmationComponent.scss']
})

export class WithdrawlSurrenderConfirmation implements OnInit {

  @Input() withdrawlSurrenderType: any;
  @Input() policyDetails: Object;
  @Input() headers: any;
  private _swpDataModal: any;
  public confirmationProperty:{};
  workRequestHref: any;
  commentsValue: any;
  @Input() set withdrawlSurrenderModel(value: any) {
    if (value) {
      this._swpDataModal = value
      this.updateTransactionData(value);
    }
  }
  public requestedDate = new Date();
  public newRequestedDate = this.requestedDate.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  public sysIndicator : string;
  get swpDataModal() {
    return this._swpDataModal;
  } 

  @Input() surrenderDetails: any;
  payload : any;
  disableProcessBtn: boolean = true;
  scriptCheckBox: boolean = true;
  confirmMessage: string = '';
  paymentDetails: any;
  partialSurrenderConfirmationValues: any = [];
  type = { PARTIAL_SURRENDER: 'partialSurrender', FULL_SURRENDER: 'fullSurrender', SYSTEMATIC_WITHDRAWL: 'systematicWithdrawal' }
  ConfirmationObj = { PARTIAL_SURRENDER: partialCallNoteTransaction, FULL_SURRENDER: fullSurrenderCallNoteTransaction, SYSTEMATIC_WITHDRAWL: systematicWithdrawalNoteTransaction }
  deleteSystematic = {SYSTEMATIC_WITHDRAWL: systematicWithdrawalRemoveTransaction }
  public systematicWithdrawlConfirmations: any;
  authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  showSWPTerminateButton : boolean = false;
  constructor(private toasterService: ToasterService,
    private datePipe: DatePipe) { }


    ngOnDestroy(){
      this.toasterService.clear();
    }


  ngOnInit() {

    this.systematicWithdrawlConfirmations = [
      {
        id: 'transactionEffectiveDate',
        label: 'Transaction Effective Date:',
        value: '',
      },
      {
        id: 'startNextDate',
        label: 'Start/Next Date:',
        value: '',
      },
      {
        id: 'confirmationNumber',
        label: 'Confirmation Number:',
        value: '',
      },
      {
        id: 'option',
        label: 'Option:',
        value: '',
      },
      {
        id: 'withdrwalAmount',
        label: 'Withdrawal Amount:',
        value: '',
      },
      {
        id: 'withdrwalPercentage',
        label: 'Withdrawal Percentage:',
        value: '',
      },
      {
        id: 'frequency',
        label: 'Frequency:',
        value: '',
      },
      {
        id: 'distributionIndicator',
        label: 'Distribution Indicator:',
        value: '',
      },
      {
        id: 'fundOption',
        label: 'Fund Option:',
        value: '',
      },
      {
        id: 'statewithholdpercent',
        label: 'State Withholding Percent:',
        value: '',
      },
      {
        id: 'federalwithholdpercent',
        label: 'Federal Withholding Percent:',
        value: '',
      },
    ]
    let headerProperties = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
    Object.assign(this.headers,{product_type:headerProperties[0].headers.product_type,region_code:headerProperties[0].headers.region_code, remote_user:headerProperties[0].headers.remote_user , statuaory_company_code : headerProperties[0].headers.statuaory_company_code})
  }
   formatCurrency = function(amount) {
    amount = "$" + amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
    if(amount.indexOf('.') === -1)
        return amount + '.00';
    var decimals = amount.split('.')[1];
    return decimals.length < 2 ? amount + '0' : amount;
};
  updateTransactionData(model) {
    const confirmationNum = OcInfraModule.AppInjector.get(CommonService).authResponse.updateResponse.data.confirmationNumber;
    const swpEndDateField = model.find(field => field.id == "end_date");
    const swpEffectiveDateField = model.find(field => field.id == "effective_date");
    this.showSWPTerminateButton = swpEndDateField.value && (swpEndDateField.value <= swpEffectiveDateField.value) && this.surrenderDetails._options.links.findIndex(link => link.method.toLowerCase() === 'patch' && link.rel.toLowerCase() == "terminate") > -1;
    this.systematicWithdrawlConfirmations = [
      {
        id: 'transactionEffectiveDate',
        label: 'Transaction Effective Date:',
        value: this.datePipe.transform(this.getSWPConfirmationValue(model, 'effective_date'), 'MM/dd/yyyy'),
      },
      {
        id: 'startNextDate',
        label: 'Start/Next Date:',
        value: this.datePipe.transform(this.getSWPConfirmationValue(model, 'next_date'), 'MM/dd/yyyy'),
      },
      {
        id: 'confirmationNumber',
        label: 'Confirmation Number:',
        value: confirmationNum,
      },
      {
        id: 'option',
        label: 'Option:',
        value: this.getSWPConfirmationValue(model, 'option'),
      },
      {
        id: 'withdrwalAmount',
        label: 'Withdrawal Amount:',
        value: this.getSWPConfirmationValue(model, 'withdrawal_amount'),
      },
      {
        id: 'withdrwalPercentage',
        label: 'Withdrawal Percentage:',
        value: this.getSWPConfirmationValue(model, 'payout_percent'),
      },
      {
        id: 'frequency',
        label: 'Frequency:',
        value: this.getSWPConfirmationValue(model, 'frequency'),
      },
      {
        id: 'distributionIndicator',
        label: 'Distribution Indicator:',
        value: this.getSWPConfirmationValue(model, 'net_gross_indicator') ? 'Gross' : '',
      },
      {
        id: 'fundOption',
        label: 'Fund Option:',
        value: this.getSWPConfirmationValue(model, 'transaction_level'),
      },
      {
        id: 'statewithholdpercent',
        label: 'State Withholding Percent:',
        value: this.getSWPConfirmationValue(model, 'state_withholding_percentage'),
      },
      {
        id: 'federalwithholdpercent',
        label: 'Federal Withholding Percent:',
        value: this.getSWPConfirmationValue(model, 'federal_withholding_percentage'),
      },
    ];
  }

  getValueOfField(model, fieldId) {
    const item = model.find(item => item.id == fieldId);
    return item ? fieldId === "fundValues" ? item.values : item.value: '';
  }

  getSWPConfirmationValue(model, fieldId) {
    const item = model.find(item => item.id == fieldId);
    switch(item.type){
      case "select": {
        const res =  item.values.find((val) => val.value === item.value);
          return res? res.label: '';
      }
      case "radio": {
        var selectedOption =  item.values.find(option => option.value == item.value);
        return selectedOption ? selectedOption.label: ""
        break;
      }
      default: {
        if (['state_withholding_percentage', 'federal_withholding_percentage', 'payout_percent'].includes(item.id)) {
          return item.value && item.value !== '' && item.value !== '0.0' ? item.value: '0.0%';
        }
        return item ? fieldId === "fundValues" ? item.values : item.value: '';
        break;
      }
    }   
  }

  scriptChecked(e) {
    this.scriptCheckBox = !e.checked;
    this.enableProcessBtn();
  }


  onTerminateClick(){
    this.createSWPRequestBody();
    this.sysIndicator = "deleteWithdrawl"
    var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'patch' && link.rel.toLowerCase() == "terminate");
      OcInfraModule.AppInjector.get(CommonService).patchCallback(link.href, this.payload, this.headers).subscribe(response => {
        this.processSWPResponse(response, 'PATCH', true); 
      }, error => {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
      });   
  }

  processSWPResponse(response, httpVerb, isTerminate){
    if (response.confirm_override == "Y") {
      let content = 'Are you sure?  You want to override ?';
      let title = 'Alert';
      let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
      dialogRef.afterClosed().subscribe(res => {
        console.log(res)
        if (res) {
          this.payload.confirm_override = "Y"
          this.processSystematicWithdrawl();
        }
      });
    } else {
          if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0 ){
            response._embedded.status_report.messages.forEach(element => {
              let alertMsg = {
                title: element.severity,
                type: "error",
                body: element.message,
                showCloseButton: true,
                timeout: 0
              }
              this.toasterService.pop(alertMsg);
            });
          }else {
            OcInfraModule.AppInjector.get(CommonService).confirmation = true;
            let message = isTerminate ? 'Success Terminate SWP Processed Successfully': 
                  ( httpVerb === 'POST' ? 'Success Addition of SWP Processed Successfully': 'Success Modify SWP Processed Successfully');
            response.confirm_override == "" || response.confirm_override == "N" ? OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(message, 'Success') : null;
            document.getElementById("modal__cross").click();
          }
    }


  }

  processSystematicWithdrawl(){
    let callNoteObj ={};
    console.log("Processing SWP ... Calling API ");
    console.log("req body ", this.payload);
    let isPost = this.surrenderDetails && this.surrenderDetails._options.links.findIndex((link) => link.method.toLowerCase() === 'post') > -1
    if (isPost){
      this.sysIndicator = "Withdrawl"
     var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'post');
     OcInfraModule.AppInjector.get(CommonService).postCallback(link.href, this.payload, this.headers).subscribe(response => { 
      this.processSWPResponse(response, 'POST', false);

     }, error => {
      OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
    });
    }else {
      this.sysIndicator = "updatWithdrawl" ;
      var link = this.surrenderDetails._options.links.find(link => link.method.toLowerCase() === 'patch' && link.rel.toLowerCase() == "update");
      OcInfraModule.AppInjector.get(CommonService).patchCallback(link.href, this.payload, this.headers).subscribe(response => {
        OcInfraModule.AppInjector.get(CommonService).confirmation = true;
        this.processSWPResponse(response, 'PATCH', false);
      }, error => {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
      });
    }
    if(this.withdrawlSurrenderType == "SYSTEMATIC_WITHDRAWL")
    {
      if(this.sysIndicator == 'deleteWithdrawl'){
        this.payload['Requested_Date'] = this.newRequestedDate;
        for (const property in this.deleteSystematic[this.withdrawlSurrenderType]) {
          if (this.payload.hasOwnProperty(property)) {   
            if(typeof this.payload[property] == 'object'){
              callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.payload[property][0]
            }
            else{
              callNoteObj[this.deleteSystematic[this.withdrawlSurrenderType][property]] = this.payload[property]
            }
            
          } 
        }
        OcInfraModule.AppInjector.get(CommonService).allTransactions = {'SYSTEMATIC WITHDRAWAL REMOVE': callNoteObj}
      }
    for (const property in this.ConfirmationObj[this.withdrawlSurrenderType]) {
      if (this.payload.hasOwnProperty(property)) {   
        if(typeof this.payload[property] == 'object'){
          callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property][0]
        }
        else{
          property == "payout_percent" ? (this.payload[property] = this.payload[property] + "%") :this.payload[property] = this.payload[property];
          property == "withdrawal_amount" ? (this.payload[property] = this.formatCurrency(this.payload[property])) :this.payload[property] = this.payload[property];  
          callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property]
        }
        
      } 
    }
      if(this.sysIndicator == "updateWithdrawl" ){ 
      OcInfraModule.AppInjector.get(CommonService).allTransactions = {'SYSTEMATIC WITHDRAWAL UPDATE': callNoteObj};
      }
     
      if(this.sysIndicator == 'Withdrawl') {
        OcInfraModule.AppInjector.get(CommonService).allTransactions = {'SYSTEMATIC WITHDRAWAL': callNoteObj};
      }
      }

  }

  onSWPSubmitClick(){
      this.createSWPRequestBody();
      this.processSystematicWithdrawl();
  }

  createSWPRequestBody(){
    this.payload = {
      "passed_enhanced_security": this.getValueOfField(this.swpDataModal, 'passed_enhanced_security'),
      "end_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'end_date'), 'MM/dd/yyyy'),
      "next_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'next_date'), 'MM/dd/yyyy'),
      "effective_date": this.datePipe.transform(this.getValueOfField(this.swpDataModal, 'effective_date'), 'MM/dd/yyyy'),
      "transaction_level": this.getValueOfField(this.swpDataModal, 'transaction_level'),
      "frequency": this.getValueOfField(this.swpDataModal, 'frequency'),
      "option": this.getValueOfField(this.swpDataModal, 'option'),
      "no_federal_withholding": this.getValueOfField(this.swpDataModal, 'no_federal_withholding'),
      "no_state_withholding": this.getValueOfField(this.swpDataModal, 'no_state_withholding'),
      "federal_withholding_override": this.getValueOfField(this.swpDataModal, 'federal_withholding_override'),
      "state_withholding_override": this.getValueOfField(this.swpDataModal, 'state_withholding_override'),
      "federal_withholding_percentage": this.getValueOfField(this.swpDataModal, 'federal_withholding_percentage').replace(/[^0-9.-]+/g,''),
      "state_withholding_percentage": this.getValueOfField(this.swpDataModal, 'state_withholding_percentage').replace(/[^0-9.-]+/g,''),
      "net_gross_indicator": this.getValueOfField(this.swpDataModal, 'net_gross_indicator') ? 'G' : '',
      "wma_transaction_code": this.surrenderDetails.wma_transaction_code,
      "distribution_code": this.surrenderDetails.distribution_code ? this.surrenderDetails.distribution_code.split("-")[0].replace("U","G"): '', 
      "firm_id": this.surrenderDetails.firm_id,
      "eft_id": this.surrenderDetails.eft_id,
    };
    this.payload.fundValues = [];
    this.payload.payment_method = [];
    
    this.paymentDetails && this.paymentDetails.disbursement && this.paymentDetails.disbursement.enum ? this.payload.payment_method.push(this.paymentDetails.disbursement.enum) : null;
    
    const fundValues = this.getValueOfField(this.swpDataModal, 'fundValues');
    console.log('fund values', fundValues)
    if (this.paymentDetails && this.payload.transaction_level === 'F' && ['u', 'a'].includes(this.payload['option'].toLowerCase())
    && fundValues && fundValues.length > 0) {
      fundValues.forEach(fundValue =>   {
        const fund = {
          "surrender_unit": ((fundValue['surrender_unit'] || '').replace(/[^0-9.-]+/g,'') || ''),
          "fund_value": ((fundValue['fund_value'] || '').replace(/[^0-9.-]+/g,'') || ''),
          "surrender_percent": ((fundValue['surrender_percent'] || '').replace(/[^0-9.-]+/g,'') || ''),
          "fund_number": fundValue['fund_number'],
          "surrender_amt": ((fundValue['surrender_amt'] || '').replace(/[^0-9.-]+/g,'') || '0.0'),
          "fund_name": fundValue['fund_name']
        };
        this.payload.fundValues.push(fund);
      });
    } else {
      this.payload.fundValues = null;
    }
    const withdrawalAmount = this.getValueOfField(this.swpDataModal, 'withdrawal_amount').replace(/[^0-9.-]+/g,'');
    this.payload['withdrawal_amount'] = this.payload["option"] === 'u' ? "0.0" : withdrawalAmount && withdrawalAmount !== ''? withdrawalAmount[0] === '$' ? withdrawalAmount.substr(1) : withdrawalAmount : "0.0";

    const payoutPercent = this.getValueOfField(this.swpDataModal, 'payout_percent').replace(/[^0-9.-]+/g,'');
    this.payload['payout_percent'] = this.payload["option"] === 'u' ? "0.0" : payoutPercent && payoutPercent !== ''? payoutPercent : "0.0";
  }

  enableProcessBtn() {
    this.disableProcessBtn = (this.authsucess || this.otherRoleSucess) && (this.paymentDetails.required_fields && this.paymentDetails.required_fields == 'Y') &&
    (this.paymentDetails.required_payment_fields && this.paymentDetails.required_payment_fields == 'Y') ? this.scriptCheckBox : true;
  }

  confirmationValues(paymentDetails, scripts) {
    this.paymentDetails = paymentDetails;
    this.enableProcessBtn();
    this.partialSurrenderConfirmationValues = [];
    surrenderConfirmationValues.forEach(element => {
      if (this.paymentDetails.hasOwnProperty(element.id)) {
        if (typeof this.paymentDetails[element.id] == 'object') {
          element.value = this.paymentDetails[element.id].title;
        } else {
          element.value = this.paymentDetails["_options"] && this.paymentDetails["_options"].properties[element.id] && this.paymentDetails["_options"].properties[element.id].format && this.paymentDetails["_options"].properties[element.id].format === 'currency' ? '$' + this.paymentDetails[element.id] : this.paymentDetails[element.id]
        }
        let allowElement = this.filterOptionalConfirmationValues(element);
        allowElement ? this.partialSurrenderConfirmationValues.push(element) : null; 
       
      } else {
        element.id == "effective_date" ? element.value = this.datePipe.transform(this.paymentDetails.effective_date, "MM/dd/yyyy") : null;
        element.id == "effective_date" ? this.partialSurrenderConfirmationValues.push(element) : null;
      }
    });
    if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
      this.partialSurrenderConfirmationValues.splice(
        findIndex(this.partialSurrenderConfirmationValues, ['id', this.paymentDetails.transaction_type.title === 'Percent' ? 'surrender_amount' : 'surrender_percent']), 1)
    }
    this.paymentDetails && this.paymentDetails.fundValues.length && this.paymentDetails.fundValues.forEach(element => {
      element.surrender_amt ? element.surrender_amt = element.surrender_amt.replace(/,|\s/g, "").replace("$", "") : '';
      element.surrender_percent ? element.surrender_percent = element.surrender_percent.replace(/,|\s/g, "").replace("%", "") : '';
    });
    if (this.authsucess || this.otherRoleSucess) {
      let withHoldInd = (this.paymentDetails && this.paymentDetails.federal_withholding_override == "Y" || this.paymentDetails &&this.paymentDetails.state_withholding_override == "Y") ? "Y" : "N";
      let url = scripts && scripts.split('?')[0] + "?callerRole=" + OcInfraModule.AppInjector.get(CommonService).authorizedRole + "&withHoldInd="+ withHoldInd + "&paymentMethod=" + this.paymentDetails.disbursement.enum;
      OcInfraModule.AppInjector.get(CommonService).getCallback(url, this.headers).subscribe(response => {
        this.confirmMessage = response.confirm_message;
      })
    }
  }

  filterOptionalConfirmationValues(element) {
    if (element.value == '$0.00') {
      const isValid = element.id === 'gmib_fee_amount' || element.id === 'edb_fee_amount' || element.id === 'gwb_lwg_fee_amount' || element.id === 'odb_fee_amount' || element.id === 'gmab_fee_amount' ? false : true;
      return isValid;
    } else if(element.value == undefined || element.value == ""){
      return false;
    } else {
      return true;
    }
  }

  createPayLoad() {
    this.payload = {};
    let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    let roleList = this.resourceService.getResourceFromAliasName('roles');
    let otherRoleAuthorize = _.isEmpty(selectedRoleSummary.data) ? _.filter(roleList._links.item, function (o) { return o.summary.role == 'Owner'; }) : false;
    let transactionFields = {
      "type": "non-awd_with_wma_test",
      "hostKey": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.hostKey ? otherRoleAuthorize[0].summary.hostKey : '' : selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": otherRoleAuthorize ? otherRoleAuthorize[0] && otherRoleAuthorize[0].summary.clientId ? otherRoleAuthorize[0].summary.clientId : '' : selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "policyNumber": this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      'company_code': policyDetails.data.company_code,
      'region_code': header[0].headers.region_code,
      'remote_user': header[0].headers.remote_user,
      "origin_app":"csr_portal",
    }
    for (const property in Payload) {
      if (this.paymentDetails.hasOwnProperty(property) && typeof this.paymentDetails[property] == 'object' ? true : this.paymentDetails[property] !== undefined) {
        this.payload[property] = typeof this.paymentDetails[property] == 'object' ? this.paymentDetails[property].enum : this.paymentDetails[property].replace(/,|\s/g, "").replace("%", "");
      } else {
        this.surrenderDetails.hasOwnProperty(property) ? typeof this.surrenderDetails[property] == 'object' ? null : this.payload[property] = this.surrenderDetails[property] : null;
      }
    }
    this.withdrawlSurrenderType == "FULL_SURRENDER" ? this.payload.agent_recommend_or_authorized = this.paymentDetails.agent_recommend_or_authorized : null;
    this.payload.payment_method = [];
    this.payload.distribution_code = this.surrenderDetails.distribution_code && this.surrenderDetails.distribution_code.substring(0, 1);
    this.paymentDetails && this.paymentDetails.fundValues.length ? this.paymentDetails.transaction_level && this.paymentDetails.transaction_level.enum == 'F' ? this.payload.fundValues = this.paymentDetails.fundValues : null : null;
  //  this.paymentDetails.transaction_level && this.paymentDetails.transaction_level.enum == 'F' ? this.paymentDetails.fundValues.length ? this.payload.fundValues = this.paymentDetails.fundValues : null : null;
    this.payload.effective_date = this.paymentDetails && this.datePipe.transform(this.paymentDetails.effective_date, "MM/dd/yyyy");
    this.paymentDetails && this.paymentDetails.disbursement && this.paymentDetails.disbursement.enum ? this.payload.payment_method.push(this.paymentDetails.disbursement.enum) : null;
    this.payload.confirm_override = "";
    for (let propName in this.payload) {
      typeof this.payload[propName] === 'string' ? this.payload[propName] = this.payload[propName].replace('$', '') : null;
    }
    if(this.payload && this.payload.account_type){
      this.payload.account_type = this.payload.account_type.split("-")[0];
    }
    Object.assign(this.payload, transactionFields);
    // as per OCCD-3002 net_gross_indicator is N it should be M on wMA. Thi sis for only Full and Partial
    if (this.payload.net_gross_indicator && this.payload.net_gross_indicator == "N"){
      this.payload.net_gross_indicator = "M";
    }
  }

  createAWDWorkType() {
    const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = this.resourceService.getResourceFromAliasName('authRes');
    let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
    this.commentsValue = '';
    if(this.partialSurrenderConfirmationValues) {
      for(let i=0; i<this.partialSurrenderConfirmationValues.length; i++) {
        this.commentsValue += this.partialSurrenderConfirmationValues[i].label + ' ' + this.partialSurrenderConfirmationValues[i].value + ',';
      }
    } 
    this.commentsValue = this.commentsValue.slice(0, -1);
    const payload = {
      "status": "CREATED",
      "CLTY": this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ? 'WITHDRAW' : 'SURR',
      "POLN": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      "comments": this.commentsValue,
      "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      "type": this.withdrawlSurrenderType == 'PARTIAL_SURRENDER' ? 'Withdrawal Request/Partial Surrender' : 'Surrender Request',
      "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
      "origin_app": "csr_portal"
    };
    return payload;
  }

  createTransaction(clicked) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    clicked ? this.createPayLoad() : null;
    let url = this.policyDetails && this.policyDetails['_links'][this.type[this.withdrawlSurrenderType]].href;
    OcInfraModule.AppInjector.get(CommonService).postCallback(url, this.payload, this.headers).subscribe(response => {
          if (response.confirm_override == "Y") {
            let content = 'Are you sure?  You want to override ?';
            let title = 'Alert';
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px');
            dialogRef.afterClosed().subscribe(res => {
              console.log(res)
              if (res) {
                this.payload.confirm_override = "Y"
                this.createTransaction(false);
              }
            });
          } else {
            if (response._embedded && response._embedded.status_report && response._embedded.status_report.messages && response._embedded.status_report.messages.length > 0) {
              response._embedded.status_report.messages.forEach(element => {
                let alertMsg = {
                  title: element.severity,
                  type: "error",
                  body: element.message,
                  showCloseButton: true,
                  timeout: 0
                }
                this.toasterService.pop(alertMsg);
              });
              for (let i = 0; i < response._options.links.length; i++) {
                if (response._options.links[i].method == 'POST') {
                  this.workRequestHref = response._options.links[i].href;
                  OcInfraModule.AppInjector.get(CommonService).surrenderWorkRequestHref = response._options.links[i].href;
                  OcInfraModule.AppInjector.get(CommonService).surrenderWorkRequestPayload = this.createAWDWorkType();
                  document.getElementById("modal__cross").addEventListener("click", () => {
                    document.getElementById("close-call-comments-textarea_")["value"] = this.commentsValue; 
                  });
                }
              }
            } else {
              OcInfraModule.AppInjector.get(CommonService).confirmation = true;
              response.confirm_override == "" ? OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Transaction Processed Successfully', 'Success') : null;
              document.getElementById("modal__cross").click();
            }
          }
        let callNoteObj= {};
        for (const property in this.ConfirmationObj[this.withdrawlSurrenderType]) {
          this.payload['Requested_Date'] = this.newRequestedDate;
          if (this.payload.hasOwnProperty(property)) {   
            if(typeof this.payload[property] == 'object'){
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property][0]
            }
            else{
              property == "surrender_percent" ? (this.payload[property] = this.payload[property] + "%") :this.payload[property] = this.payload[property]; 
              property == "surrender_amount" ? (this.payload[property] = this.formatCurrency(this.payload[property])) :this.payload[property] = this.payload[property]; 
              callNoteObj[this.ConfirmationObj[this.withdrawlSurrenderType][property]] = this.payload[property]
            }
            
          } 
        }
        if(this.withdrawlSurrenderType == "FULL_SURRENDER"){
          
         Object.assign(callNoteObj,OcInfraModule.AppInjector.get(CommonService).fullSurrenderFields);
        OcInfraModule.AppInjector.get(CommonService).allTransactions = {'FULL SURRENDER': callNoteObj};
        }
          if(this.withdrawlSurrenderType == "PARTIAL_SURRENDER"){
            OcInfraModule.AppInjector.get(CommonService).allTransactions = {'PARTIAL SURRENDER': callNoteObj};
          }
        }, error => {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded && error._embedded.status_report && error._embedded.status_report.messages[0].message, 'Error', 0);
      }
      )
  }
}
