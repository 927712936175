import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RendererComponent } from'@diaas/ux-web';
import { LoginComponent } from './../components/login.component/login.component';
import { LogoutComponent } from './../components/logout.component/logout.component';
import { AuthenticationService } from './../services/authentication.service';
import { CanDeactivateGuard } from './../services/canDeactivateGuard';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'screen/:screenId', component: RendererComponent, canActivate: [ AuthenticationService ], canDeactivate: [CanDeactivateGuard] },
  { path: 'logout', component: LogoutComponent},
  { path: '**', component: LogoutComponent, canActivate: [ AuthenticationService ], canDeactivate: [CanDeactivateGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [RendererComponent];
