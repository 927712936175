import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface confirmDialogConfig {
  content?: string,
  ok?: string,
  close: string,
  title : string,
  width : string,
}

@Component({
  //selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  //styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent {

  get dialog(): confirmDialogConfig {
    return this.data;
}

constructor(
    @Inject(MAT_DIALOG_DATA) public data: confirmDialogConfig,
    public dialogRef: MatDialogRef<ConfirmdialogComponent>
) {}

}
