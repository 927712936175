import { Component, ElementRef, OnInit, AfterViewInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';

const MENU: String = 'menu'
const PENDING_TRANSACTION: String = 'pendingTransaction'

const PARTIAL_SURRENDER: String = 'partialSurrender'
const FULL_SURRENDER: String = 'fullSurrender'
const SYSTEMATIC_WITHDRAWAL: String = 'systematicWithdrawal'

export class WithdrawalSurrenderAction {
  private userAction: String = MENU;
  private withdrawalType: String;  
  private messageKey: String;

  constructor() {
    this.userAction = MENU;
    this.withdrawalType = '';
    this.messageKey = '';
  }

  createPartialSurrenderMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = PARTIAL_SURRENDER;
  }

  createFullSurrenderMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = FULL_SURRENDER;
  }

  createSystematicWithdrawalMenuAction() {
    this.userAction = MENU;
    this.withdrawalType = SYSTEMATIC_WITHDRAWAL;
  }

  createPartialSurrenderPendingTransactionAction(messageKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = PARTIAL_SURRENDER;
    this.messageKey = messageKey;
  }

  createFullSurrenderPendingTransactionAction(messageKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = FULL_SURRENDER;
    this.messageKey = messageKey;
  }

  createSystematicWithdrawalPendingTransactionAction(messageKey) {
    this.userAction = PENDING_TRANSACTION;
    this.withdrawalType = SYSTEMATIC_WITHDRAWAL;
    this.messageKey = messageKey;
  }

  resetData() {
    this.userAction = MENU;
    this.withdrawalType = '';
    this.messageKey = '';
  }

  getMessageKey() {
    return this.messageKey;
  }

  isUserMenuAction() {
    return this.userAction == MENU;
  }

  isPendingTransactionAction() {
    return this.userAction == PENDING_TRANSACTION;
  }

  isPartialSurrender() {
    return this.withdrawalType == PARTIAL_SURRENDER;
  }
  
  isFullSurrender() {
    return this.withdrawalType == FULL_SURRENDER;
  }

  isSystematicWithdrawal() {
    return this.withdrawalType == SYSTEMATIC_WITHDRAWAL;
  }

  getWithdrawalSurrenderType() {
    if (this.isPartialSurrender())
        return 'PARTIAL_SURRENDER';
    else if (this.isFullSurrender())
        return 'FULL_SURRENDER';
    else if (this.isSystematicWithdrawal())
        return 'SYSTEMATIC_WITHDRAWL';
    
    return '';
  }

  getWithdrawalSurrenderAction() {
    if (this.isPartialSurrender())
        return 'PARTIAL SURRENDER';
    else if (this.isFullSurrender())
        return 'FULL SURRENDER';
    else if (this.isSystematicWithdrawal())
        return 'SYSTEMATIC WITHDRAWL';
    
    return '';
  }
}
