import { Component, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, ResourceService, Configuration } from '@diaas/ux-web';
import * as _ from 'lodash';
import {MatMenuTrigger, MatSelect} from '@angular/material';
import { NgModel } from '@angular/forms';


@Component({
  selector: 'app-table-filter',
  templateUrl: './customTableFilter.component.html',
  styleUrls: ['./customTableFilter.component.scss']
})


export class TableFilterComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChildren('matSelectRef') matSelectRef: QueryList<ElementRef>;
  constructor(private resourceService: ResourceService,  public element: ElementRef) {
    this.elementData = this.element.nativeElement
  }

  optionList: any[] = [];
  appliedFilters: any;
  selectedValues: any;
   
  headers: any;
  elementData: any;
  filterElementsData: any;
  filterMetamodel: any[] = [
    {
      id:"workRequestTableFilter",
      alias: "workRequestHistory",
      field: [
        { 
          title: "Type",
          id: "worktype",
          type: "select",
          optionList: ""
        }
      ]
    },
    {
      id:"paymentHistoryFilter",
      alias: "paymenthistory",
      defaultParams: [
        {
          key: "history_type",
          value: "payment_history"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"historicalCoiFilter",
      alias: "historicalcoi",
      defaultParams: [
        {
          key: "history_type",
          value: "historical_coi"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        } 
      ]
    },
    {
      id:"outGoingMoneyFilter",
      alias: "outgoingmoney",
      defaultParams: [
        {
          key: "history_type",
          value: "outgoing_money"
        }        
      ],
      field: [
        {
          title: "Transaction Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        },
        {
          title: "Status",
          id: "status",
          type : "multi-select",
          optionList: ""
        }
      ]
    },
    {
      id:"fundsFilter",
      alias: "historyFunds",
      defaultParams: [
        {
          key: "history_type",
          value: "funds"
        }        
      ],
      field: [
        {
          title: "Type",
          id: "transaction_type",
          type : "multi-select",
          optionList: ""
        }
      ]
    },

  ];

  
  ngOnInit() {
    this.selectedValues ={};
    this.appliedFilters={};
    let filteredElement = this.filterMetamodel.filter(obj => {
      return obj.id === this.elementData.id;
    })   
    this.filterElementsData = filteredElement.length  ? filteredElement[0] : {}
    let metadata =  this.resourceService.getResourceFromAliasName(this.filterElementsData.alias);
    this.filterElementsData.field.forEach(element => {
      element.optionList = metadata && metadata.response && metadata.response["_options"].properties[element.id] ? metadata.response["_options"].properties[element.id].oneOf : [];
    }); 

  }

  removeFilter(keyName, obj, matSelectRef: MatSelect) {  
    if(this.isArray(this.selectedValues[keyName])){
      const index = _.findIndex(this.selectedValues[keyName], ['title', obj.title]);    
      if (index >= 0) {
        this.selectedValues[keyName].splice(index, 1);  
        matSelectRef['_results'].forEach(element => {
          if(element.id === keyName){
            const matSelect: MatSelect = element;
            matSelect.writeValue(this.selectedValues[keyName]) 
          }       
        });
      }
    }else{
      delete this.selectedValues[keyName];
    }
    this.applyFilter();
  }

  applyFilter(){    
    let filterHref = this.resourceService.getHrefByAliasName(this.filterElementsData.alias);
    let isFinancialHistory = this.filterElementsData.alias === 'paymenthistory' || this.filterElementsData.alias === 'outgoingMonney' ? true : false;
    const selectedFilters = [];
   let selectedProperties =  OcInfraModule.AppInjector.get(CommonService).filterProperties;
   selectedProperties[this.filterElementsData.alias]={};
   Object.assign(selectedProperties[this.filterElementsData.alias] ,this.selectedValues)
   OcInfraModule.AppInjector.get(CommonService).filterProperties = selectedProperties;
    Object.keys(this.selectedValues).forEach(key => {
      if(Array.isArray(this.selectedValues[key]) && this.selectedValues[key].length){     
        let valueArray = []
        this.selectedValues[key].forEach(element => {
          valueArray.push(element.enum[0])
        });
        if(valueArray){
          selectedFilters.push({key: key, value: valueArray.join()});
        }        
      }else{
        if(this.selectedValues[key] && this.selectedValues[key].enum && this.selectedValues[key].enum[0]){
          selectedFilters.push({key: key, value: this.selectedValues[key].enum[0]});
        }        
      }
    });
    if(this.filterElementsData.defaultParams && this.filterElementsData.defaultParams.length){      
      selectedFilters.unshift(...this.filterElementsData.defaultParams)
      const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName(this.filterElementsData.alias);
      filterHref = alias.href ? alias.href.split("?")[0] : '';
    }  
    
    const optionParams = {
      'alias': this.filterElementsData.alias,
      'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName(this.filterElementsData.alias),
      'params': selectedFilters
    };
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true })
    OcInfraModule.AppInjector.get(APICallerService).refresh(filterHref, optionParams).subscribe(refreshResponse => {
      this.trigger.closeMenu();
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: true });
      this.appliedFilters = Object.assign({}, this.selectedValues);
    })
    
  }

  isArray(value) {
    return Array.isArray(value) ? true : false;     
  } 

  resetFilter() {   
    this.selectedValues = {};
    let matSelectRef: QueryList<ElementRef>;
    this.applyFilter();
  }

}
