import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService, ResourceDirectoryService, IAlias } from '@diaas/ux-web';
import * as _ from 'lodash';
import { KeyValue } from '@angular/common';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'app-callDetails',
  templateUrl: './customCallDetail.component.html',

})

export class CallDetailComponent implements OnInit {
  selectedCallHistoryDetails : any = {};
  workRequestList : string = '';
  callNotes : string = '';
  objArray = [];
  fundObj  = {};
  objLength :any = 0;
  //selectedCallHistoryDetailsArray: any = [];
  constructor(private resourceService: ResourceService) {
      
  }

  ngOnInit() {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    let params = OcInfraModule.AppInjector.get(CommonService).paramDetails;
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
    const aliasToDelete: IAlias = resourceService.getAliasByAliasName('selectedCallHistory');
    aliasToDelete ? resourceDirectoryService.deleteElementResourceDirectory(aliasToDelete.href) && resourceService.updateCreateAliasName(aliasToDelete.id, '', true) :'' ;
    const optionParams: Object = {
      'alias': "selectedCallHistory",
      'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedCallHistory")
    }
    OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams).subscribe(response => {
      var workRequestList = [];
      let responseText = response.data.text;
      const options: Object = {
        'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedCallHistory")
      }
      OcInfraModule.AppInjector.get(CommonService).getCallback(response._links.transaction.href, optionParams["headers"]).subscribe(transactionResponse => {
        if (transactionResponse.length !== 0) {
          transactionResponse.forEach(key => {
            workRequestList.push(key.type);
          });
          OcInfraModule.AppInjector.get(CommonService).workRequestList = workRequestList.join(',');
        }
        else {
          OcInfraModule.AppInjector.get(CommonService).workRequestList = 'No Transactions Found';
        }
        if (responseText !== undefined && responseText !== '') {
          let formattedText = responseText.slice(responseText.indexOf('{'), responseText.lastIndexOf('}') + 1);
          OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = JSON.parse(formattedText);
          this.selectedDetails();
        }
        else {
          OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails = undefined;
          this.selectedDetails();
        }
        resourceService.updateCreateAliasName('selectedCallHistory', params.href, true);
      });
    });
  }
  reverseKeyOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }
  selectedDetails() {
    this.selectedCallHistoryDetails = OcInfraModule.AppInjector.get(CommonService).selectedCallHistoryDetails;
    if (this.selectedCallHistoryDetails !== undefined) {
      this.callNotes = this.selectedCallHistoryDetails['notes'];
      delete this.selectedCallHistoryDetails['notes'];
    }
    this.workRequestList = OcInfraModule.AppInjector.get(CommonService).workRequestList;
// var obj1 = [];
// for(let prop in this.selectedCallHistoryDetails){
// 	if(typeof this.selectedCallHistoryDetails[prop] === 'object')
//   {
//     for(let innerProp in this.selectedCallHistoryDetails[prop]){
//         obj1.push(innerProp + ':' + this.selectedCallHistoryDetails[prop][innerProp]);
//     }
//   }
//   else {
//   		obj1.push(prop + ':' + this.selectedCallHistoryDetails[prop]);
//   }
// }
// this.selectedCallHistoryDetailsArray = obj1;
  }

  checkType(item){
    if(typeof item.value === 'object'){
      if(this.objArray.indexOf(item.value) == -1){
        this.objArray.push(item.value);
      }
      return true;
    }
    else{
      return false;
    }

  }

  checkLabel(item) {
    let val;
    let namesObj = { "loanInfo": "LOAN", "fundInfo": "FUNDS", 'policySuspense':'SUSPENSE',"selectedCoverage": "RIDERS", "policyValues": "VALUES", "policyRevolvingError":"REVOLVING","selectedNote":"NOTES" }
    for (let key in namesObj) {
      if (item == namesObj[key]) {
        val = true;
        break;
      }
      else {
        val = false;
      }
    }
    return val;
  }

  getFund(item) {
    this.fundObj = {};
    item.key == "Message" ? this.fundObj[item.key] =  item.value : this.fundObj[item.value.substring(0, item.value.indexOf(':'))] = item.value.split(':')[1];
    return true;
  }

  checkEffectiveDate(effectiveDate, item, key) {
    if (effectiveDate === 'Effective Date' && key != "RIDERS") {
      this.objLength = Object.keys(item).length;
      return  key == "SUSPENSE" || key == "REVOLVING" ? false : true;
    } else {
      return false;
    }
  }
}
