import { throwError as observableThrowError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { share, map, catchError,finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http } from "@angular/http";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OcInfraModule, APICallerService, Configuration, ResourceService, IAlias, ResourceDirectoryService } from '@diaas/ux-web';
import { MatDialog } from '@angular/material';
import { DialogComponent, DialogConfig } from '../components/dialog.component/dialog-component';
import { ConfirmdialogComponent , confirmDialogConfig} from './../components/confirmdialog/confirmdialog.component';
import { WithdrawalSurrenderAction } from '../components/withdrawlsSurrender/withdrawalSurrenderAction';
import * as _ from 'lodash';

@Injectable()
export class CommonService {
    public selectedWorkItemType: string;
    public selectedWorkItemValue: string;
    public selectedOther: boolean;
    public selectedRole: string;
    public authorizedRole: string;
    public workRequestList: string;
    public showBilling: boolean;
    public showLoan: boolean;
    public authsucess: boolean = false;
    public notesMsg: boolean;
    public corporateOwner: boolean;
    public otherRoleAuthsucess: boolean = false;
    public selectedLanguage: object;
    public selectedReason: string = "";
    public otherRole: '';
    public policyHistoryUrl: string;
    public selectdFilter: string;
    public deathClaimProperties: object;
    public selectedCallHistoryDetails: object;
    public historicalCoiTransactionType: any;
    public outGoingMoneyStatus: any;
    public outGoingMoneyTransactionType: any;
    public paymentHistoryTransactionType: any;
    public paymentHistoryStatus: any;
    public showHistoricalCoiFilter: boolean = true;
    public agentPolicyDetailAlert: boolean = false;
    public showPaymentHistoryFilter: boolean = true;
    public showOutGoingMoneyFilter: boolean = true;
    public showFundsFilter: boolean = true;
    public historicalCoiTransactionTypeFilter: any;
    public outGoingMoneyTransactionTypeFilter: any;
    public outGoingMoneystatusFilter: any;
    public fundsType: any;
    public fundsTypeFilter: any;
    public paymentHistoryTransactionTypeFilter: any;
    public paymentHistorystatusFilter: any;
    public hostKey: any;
    public showOtherRoleSection: boolean;
    public authResponse: any;
    public deathClaimRequiredProperties: Array<String>;
    public selectedCoverages = [];
    public billingHtml: string = '';
    public detailsHtml: string = '';
    public quoteValuesHtml: string = '';
    public coverageHtml: string = '';
    public notesHtml: string = '';
    public quoteDate: string = '';
    public loanDate: string = '';
    public billing: boolean;
    public funds: boolean;
    public incontactId: string = '';
    public awdUserId: string = '';
    public selectdCurrencyFields = [];
    public suspenseMsg: boolean;
    public suspenseHtml: string = '';
    public revolvingHtml: string = '';
    public checkedRevolvingError = [];
    public checkedSuspenseHistory = [];
    public loanHtml: string = '';
    public loanFinalHtml: string = '';
    public fundHtml: string = '';
    public productInfo: any;
    public bannerAlertArray = [];
    public newBannerAlertAarray = [];
    public partyEmailInformation: any;
    public partyPhoneNumberInformation: any;
    public emailHref: string = '';
    public phoneNumberhref: string = '';
    public billingMethod: boolean = false;
    public result123: boolean;
    public personURL: string = '';
    public quoteValuesFinalHtml: string = '';
    public loanMesseageHtml: string = '';
    public valuesMesseage: any ;
    public loanMesseage: any='' ;
    public quoteMesseageHtml: any='' ;
    public fundMessageHtml: string = '';
    public fundFinalHtml: string ='';
    public quoteDetailsHtml: string ='';
    public paramDetails: any ;
    public selectedWorkItem: string =''; ;
    public partyResponse: any;
    public claimDetails: any;
    public isAnnuity: boolean;
    public callBoadData: boolean = true;
    public avgCallHandleTime: boolean = true;
    public backButton: boolean = false;
    public additionalPolicies: any; 
    public selectedSourceForFax = []; 
    public formsList: any; 
    public filterProperties = []; 
    public role: string =''; 
    public correspondenceDoc: []; 
    public businessArea: string = ''; 
    public escalateWorkRequest: boolean = false;
    public confirmationNo: boolean = false;
    public selectedWorkItemParams: any;
    public currentPolicy: any;
    public oAmount: any;
    public otherKey: boolean = false;
    public deliveryMethod: boolean = false;
    public disableTermandFia: boolean = false;
    public csrUser: boolean = false;
    public olsUser: boolean = false;
    public agentTable: boolean = true;
    public customerTable: boolean = true;
    public termAndFia = ['T1', 'A2'];
    public selectedAgentSummary :any;
    public aSearchValue: any;
    public searchAgentUrl: any;
    public customerDetails: any = {};
    public agentRoleDetails: any = {};
    public customerRoleDetails: any; 
    public searchResults: any;
    public searchContractsValue: any = '';
    public aobVisible: boolean = false;
    public userRole: any;
    public attachedFiles: any;
    public loanInterestRate: any;
    public currentRoute: any;
    public tir2Ticket: boolean = false;
    public isOlsAnnuity: boolean = false;
    public isOlsIul: boolean = false;
    public customerBanner: boolean = true;
    public agentBanner: boolean = true;
    public confirmation: boolean = false;
    public agentBannerAlertMessage:any;
    public otherRoleCallerName: string = '';
    public instanceParams :any;
    public policyNumber:any;
    public allTransactions: {};
    public fullSurrenderFields: {};
    public preFillIndicator: boolean = false;
    public quoteValues: any;
    public withdrawalSurrenderAction: WithdrawalSurrenderAction = new WithdrawalSurrenderAction();
    public surrenderWorkRequestHref: any;
    public surrenderWorkRequestPayload: any;
    public selectedWithdrawlSurrenderType: string = '';
    public createClaim: boolean = false;
    public claimAlert: boolean = true;
    commentsUrl: any;

    confirm$: Observable<boolean>;
    constructor(private http: Http, private httpClient: HttpClient, private router: Router, private dialog: MatDialog) {
        this.getSelectedLangJson("en");
        this.withdrawalSurrenderAction = new WithdrawalSurrenderAction();
    }

    showConfirmation(content, closeLabel, okLabel, title, width) {
        const dialog: DialogConfig = {
            title: title,
            content: content,
            close: closeLabel,
            ok: okLabel ? okLabel : '',
            width : width
        };
        const dialogRef = this.dialog.open(DialogComponent, { width: dialog.width, data: dialog, disableClose: true });
        return dialogRef;
    }

    public showConfirmationPopup(content, closeLabel, okLabel,title,width) {
        const dialog: confirmDialogConfig = {
            content: content,
            close: closeLabel,
            ok: okLabel,
            title: title,
            width : width
        };
        const dialogRef = this.dialog.open(ConfirmdialogComponent, { width: dialog.width, data: dialog });
        return dialogRef;
    }

    @Output() change: EventEmitter<boolean> = new EventEmitter();
    @Output() showHideBannerAlert: EventEmitter<object> = new EventEmitter();
    @Output() showHideCustomSpinner: EventEmitter<object> = new EventEmitter();
    public alertMessaggeHideShow = new BehaviorSubject([]);
    @Output() bannerAlertId: EventEmitter<any> = new EventEmitter();

    public postCallback(url: string, body: Object, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.post(url, body ? body : {}, { headers: headers }).pipe(
            map(response => {
                return response.json();
            }),
            share(),
            catchError(error =>
                observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)));
    };

    public getCallback(url: string, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.get(url, { headers: headers }).pipe(
            map(response => {
                return response.json();
            }),
            share(),
            catchError(error =>
                observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)));
    };

    public patchCallback(url: string, body: Object, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.patch(url, body ? body : {}, { headers: headers }).pipe(
            map(response => {
                return response.json();
            }),
            share(),
            catchError(error =>
                observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)));
    };

    public optionCallback(url: string, headers: any) {
        this.showHideSpinner({ showSpinner: true, edit: true });
        return this.http.options(url, { headers: headers }).pipe(
            map(response => {
                return response.json();
            }),
            share(),
            catchError(error =>
                observableThrowError(error.json())),
            finalize(() =>
                setTimeout(() => {
                    this.showHideSpinner({ showSpinner: false, edit: true });
                }, 5000)));
    };

    startCall() {
        this.change.emit(true);
    };

    getSelectedLangJson(lang) {
        this.http.get("../../assets/i18n/" + lang + ".json").subscribe(response => {
            this.selectedLanguage = response.json();
        });
    }

    routerService() {
        return this.router
    }

    resetPolicyDetails() {
        OcInfraModule.AppInjector.get(CommonService).csrUser ? this.delteResources(['policyDetails','selectedRoles']) : null;
        // this.delteResources(['policyDetails','selectedRoles'])
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ role: null, remote_user: null, incontact_user: null });
        this.authsucess = false;
        this.otherRoleAuthsucess = false;
        this.selectedOther = false;
        this.notesMsg = false;
        this.suspenseMsg = false;
        this.showOtherRoleSection = false;
        this.selectedReason = '';
        this.billingHtml = '';
        this.quoteValuesHtml = '';
        this.detailsHtml = '';
        this.coverageHtml = '';
        this.loanHtml = '';
        this.loanFinalHtml = '';
        this.fundHtml = '';
        this.notesHtml = '';
        this.selectedCoverages = [];
        this.selectdCurrencyFields = [];
        this.quoteDate = '';
        this.loanDate = '';
        this.suspenseHtml = '';
        this.billing = false;
        this.funds = false;
        this.selectedRole = '';
        this.authorizedRole = '';
        this.checkedRevolvingError = [];
        this.checkedSuspenseHistory = [];
        this.revolvingHtml = '';
        this.productInfo = [];
        this.partyEmailInformation = [];
        this.partyPhoneNumberInformation = [];
        this.emailHref = '';
        this.phoneNumberhref = '';
        this.billingMethod = false;
        this.valuesMesseage = '';
        this.quoteValuesFinalHtml = '';
        this.quoteMesseageHtml = '';
        this.loanMesseageHtml = '';
        this.fundMessageHtml = '';
        this.fundFinalHtml = '';
        this.selectedWorkItem = '';
        this.filterProperties = [];
        this.role = '';
        this.correspondenceDoc = [];
        this.businessArea = '';
        this.escalateWorkRequest = false;
        this.confirmationNo = false;
        this.deliveryMethod = false;
        this.oAmount = '';
        this.otherKey = false;
        this.agentRoleDetails = {};
        this.otherRole='';
        this.attachedFiles= [];
        this.tir2Ticket = false;
        this.customerBanner = true; 
        this.agentBanner = true; 
        this.otherRoleCallerName = ''; 
        this.instanceParams = [];
        this.authResponse = {};
        this.commentsUrl = '';
        this.agentBannerAlertMessage = '';
        this.quoteValues = {};
        this.quoteDetailsHtml = '';
        this.surrenderWorkRequestHref = '';
        this.surrenderWorkRequestPayload = '';
        localStorage.clear();
        this.resetSelectdRoles();
    }

    deleteResources(aliasList){
        let resource: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
        if(resource){
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        resource.forEach(alias => {
            for (let i = 0; i <= aliasList.length; i++){
                alias.alias == aliasList[i] ? resourceDirectoryService.deleteElementResourceDirectory(alias.href) :'';
            }
        });
    }
}
    setHeaderByAliasName(alias, newHeader) {
        Configuration.config.headers["multiHostUrlHeader"].forEach(currentHeaders => {
            if (currentHeaders.alias === alias) {
                currentHeaders.headers = Object.assign(currentHeaders.headers, newHeader);
            }
        });
    }

    getHeaderByAliasName(alias) {
        const filteredHeader = Configuration.config.headers["multiHostUrlHeader"].filter(headerObj => { return headerObj.alias === alias });
        return filteredHeader[0] ? filteredHeader[0].headers : Configuration.config.headers.defaultHeader;
    }

    public changeHeaders(newHeader) {
        const configHeader: any = Configuration.config.headers;
        let localHeaders = configHeader.defaultHeader;
        Object.keys(newHeader).forEach(function (key, value) {
            if (newHeader[key] === null) {
                delete localHeaders[key];
            } else {
                localHeaders[key] == newHeader['key']
            }
        });
    }

    public showEditIcon(alias, visibility) {
        this[alias] = visibility;
    }

    public getAllUrlOfPolicyDetails() {
        let policyDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails._links) {
            return policyDetails._links;
        } else {
            return false;
        }
    }

    public getFinalHtml() {
        return this.detailsHtml + this.coverageHtml + this.billingHtml + this.quoteValuesHtml + this.loanFinalHtml + this.fundFinalHtml + this.suspenseHtml + this.revolvingHtml + this.notesHtml + this.quoteDetailsHtml;
    }

    public showLoanFinalDetails() {
        if (OcInfraModule.AppInjector.get(CommonService).loanHtml != '' ) {
            OcInfraModule.AppInjector.get(CommonService).loanFinalHtml = '<dl class="pad-lAr-10px">'+' <span style="font-size:15px">LOAN</span><dt class="pad-lAr-10px">' + OcInfraModule.AppInjector.get(CommonService).loanHtml + '<dt>' + '</dl>';
        } else {
            OcInfraModule.AppInjector.get(CommonService).loanFinalHtml = '';
        }
    }

    public showfundFinalDetails() {
        if (OcInfraModule.AppInjector.get(CommonService).fundHtml != ''  || this.fundMessageHtml ) {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml = ' <span class="pad-lAr-10px" style="font-size:15px;font-weight: 200;" class="">FUNDS</span>' + OcInfraModule.AppInjector.get(CommonService).fundMessageHtml + OcInfraModule.AppInjector.get(CommonService).fundHtml;
        } else {
            OcInfraModule.AppInjector.get(CommonService).fundFinalHtml = '';
        }
    }

    public showRequestedDetails() {
        let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
        let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
        requestedDetailSectionBilling.innerHTML = finalHtml;
    }

    public convertToCurrency(amount) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(amount)
    }

    public checkFormatCustomComponent(event, field, apiName, columnName) {
        let check = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        var URL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
        let getResourceByHref = OcInfraModule.AppInjector.get(APICallerService).get(URL[apiName].href);
        if (event.checked) {
            if (getResourceByHref.value.response._options.properties[columnName] && getResourceByHref.value.response._options.properties[columnName].format && getResourceByHref.value.response._options.properties[columnName].format == 'currency') {
                check.push(field);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = check;
            }
        }
    }

    openSourceDocument(sourceURL, selectedWorkRequestHeaders) {
        if (sourceURL && selectedWorkRequestHeaders) {
            this.httpClient.get(sourceURL, { headers: selectedWorkRequestHeaders, responseType: 'blob' }).subscribe(response => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(response);
                a.download = 'sourceDocument';
                a.click();
                a.remove();
            });
        }
    }

    myCallHistory() {
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const aliasToDelete: IAlias = resourceService.getAliasByAliasName('callHistory');
        if (aliasToDelete) {
            resourceDirectoryService.deleteElementResourceDirectory(aliasToDelete.href);
        }
        resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
        let callHistoryUrl = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'callHistory' });
        let url :string = callHistoryUrl[0].url + OcInfraModule.AppInjector.get(CommonService).incontactId
        OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('callHistory',url,true);
    }

    resetSelectdRoles() {
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const aliasToDelete: IAlias = resourceService.getAliasByAliasName('selectedRoles');
        if (aliasToDelete) {
            resourceDirectoryService.deleteElementResourceDirectory(aliasToDelete.href);
        }
    }

    pushBannerAlertMessage(statusReport) {
        if(statusReport.messages && statusReport.messages.length){
            this.bannerAlertArray.push(statusReport);
            this.showHideBannerAlert.emit(this.bannerAlertArray);
        }
    }

    pushAlertBannerMessage(statusReport) {
        if (statusReport.messages && statusReport.messages.length){
            this.newBannerAlertAarray.push(statusReport);
            this.alertMessaggeHideShow.next(this.newBannerAlertAarray);
        }
    }

    getMessages(){
        return this.alertMessaggeHideShow.asObservable();
    }

    public formatDate(date) {
        let newdate = new Date(date);
        return (newdate.getMonth() + 1) + '/' + newdate.getDate() + '/' + newdate.getFullYear();
    }
    
    public showHideSpinner(params) {        
        this.showHideCustomSpinner.emit(params);
    }
    
    public get(url): Observable<any> {
        return this.http.get(url);
    };

    public delteResources(alias : Array <string> ){
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        alias.forEach(alias => {
          const workRequestHref = resourceService.getHrefByAliasName(alias);
          resourceDirectoryService.deleteElementResourceDirectory(workRequestHref);
        });
    }

    public clearWithdrawalSurrenderAction() {
        this.withdrawalSurrenderAction = new WithdrawalSurrenderAction();
    }

    public getWithdrawalSurrenderAction() {
        return this.withdrawalSurrenderAction;
    }
}