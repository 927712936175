export const initialPartialSurrender = [
  {
    id: 'gross_cash_value_amount',
    checked: true,
    label: 'Gross Cash Value',
    value: '',
    disabled: false
  },
  {
    id: 'free_withdrawal_amount',
    checked: false,
    label: 'Free Withdrawal',
    value: '',
    disabled: false
  },
  {
    id: 'free_amount_gain',
    checked: false,
    label: 'Free Amount Gain',
    value: '',
    disabled: false
  },
  {
    id: 'free_amount_premium',
    checked: false,
    label: 'Free Amount Premium',
    value: '',
    disabled: false
  },
   {
    id: 'gross_surrender_amount',
    checked: false,
    label: 'Gross Surrender',
    value: '',
    disabled: false
  },
  {
    id: 'surrender_penalty',
    checked: false,
    label: 'Surrender Penalty',
    value: '',
    disabled: false
  },
  {
    id: 'total_mva_amount',
    checked: false,
    label: 'Total MVA',
    value: '',
    disabled: false
  },
  {
    id: 'net_check_amount',
    checked: false,
    label: 'Net Check',
    value: '',
    disabled: false
  }
];
export const surrenderConfirmationValues = [
  {
    id: 'effective_date',
    label: 'Transaction Effective Date:',
    value: ''
  },
  {
    id: 'confirmationNumber',
    label: 'Confirmation Number:',
    value: 'XX1234'
  },
  {
    id: 'transaction_type',
    label: 'Transaction Type:',
    value: ''
  },
  {
    id: 'transaction_level_view_value',
    label: 'Transaction Level:',
    value: ''
  },
  {
    id: 'disbursement',
    label: 'Disbursement:',
    value: ''
  },
  {
    id: 'surrender_amount',
    label: 'Transaction Amount/Percent:',
    value: ''
  },
  {
    id: 'surrender_percent',
    label: 'Transaction Amount/Percent:',
    value: ''
  },
  {
    id: 'federal_withholding_amount',
    label: 'Federal Withholding Amount:',
    value: ''
  },
  {
    id: 'federal_withholding_percentage',
    label: 'Federal Withholding Percent:',
    value: ''
  },
  {
    id: 'state_withholding_amount',
    label: 'State Withholding Amount:',
    value: ''
  },
  {
    id: 'state_withholding_percentage',
    label: 'State Withholding Percent:',
    value: ''
  },
  {
    id: 'surrender_penalty',
    label: 'Penalties:',
    value: ''
  },
  {
    id: 'administrative_fee',
    label: 'Admin Fee:',
    value: ''
  },
  {
    id: 'gmib_fee_amount',
    label: 'GMIB Fee:',
    value: ''
  },
  {
    id: 'edb_fee_amount',
    label: 'EDB Fee:',
    value: ''
  },
  {
    id: 'gwb_lwg_fee_amount',
    label: 'GWB/LWG Fee:',
    value: ''
  },
  {
    id: 'odb_fee_amount',
    label: 'ODB Fee:',
    value: ''
  },
  {
    id: 'gmab_fee_amount',
    label: 'GMAB Fee:',
    value: ''
  },
  {
    id: 'total_mva_amount',
    label: 'MVA Fee:',
    value: ''
  }
];
export const eftInformation = [
  {
      id: '',
      label: 'Bank Name',
      value: 'XXXXX',
  },
  {
      id: '',
      label: 'Account Type',
      value: 'XXXXX',
  },
  {
      id: '',
      label: 'Account Number',
      value: 'XXXXX',
  },
  {
      id: '',
      label: 'Routing/Transit Number',
      value: 'XXXXX',
  }
];
export const initialFullSurrender = [
  {
    id: 'accumulated_cash_value',
    checked: false,
    label: 'Accumulated Cash Value',
    value: '',
    disabled: false
  },
  {
    id: 'administrative_fee',
    checked: false,
    label: 'Admin Fee',
    value: '',
    disabled: false
  },
  {
    id: 'surrender_penalty',
    checked: false,
    label: 'Surrender Penalty',
    value: '',
    disabled: false
  },
  {
    id: 'gmib_fee_amount',
    checked: false,
    label: 'GMIB Fee',
    value: '',
    disabled: false
  },
  {
    id: 'total_mva_amount',
    checked: false,
    label: 'MVA',
    value: '',
    disabled: false
  },
  {
    id: 'odb_fee_amount',
    checked: false,
    label: 'ODB Fee',
    value: '',
    disabled: false
  },
  {
    id: 'edb_fee_amount',
    checked: false,
    label: 'EDB Fee',
    value: '',
    disabled: false
  },
  {
    id: 'gwb_lwg_fee_amount',
    checked: false,
    label: 'GWB/LWG Fee',
    value: '',
    disabled: false
  },  
  {
    id: 'gmab_fee_amount',
    checked: false,
    label: 'GMAB Fee',
    value: '',
    disabled: false
  },
  {
    id: 'gross_check_amount',
    checked: false,
    label: 'Gross Check',
    value: '',
    disabled: false
  },
  {
    id: 'max_net_partial_surrender_amount',
    checked: false,
    label: 'Net Check',
    value: '',
    disabled: false
  },
  {
    id: 'federal_withhold_amount',
    checked: false,
    label: 'Federal Withholding',
    value: '',
    disabled: false
  },
  {
    id: 'state_withholding_amt',
    checked: false,
    label: 'State Withholding',
    value: '',
    disabled: false
  },
  {
    id: 'pre_tefra_tamra_amount',
    checked: false,
    label: 'Pre TEFRA/TAMRA',
    value: '',
    disabled: false
  },
  {
    id: 'post_tefra_tamra_amount',
    checked: false,
    label: 'Post TEFRA/TAMRA',
    value: '',
    disabled: false
  }
];
export const Payload = {
  "effective_date": "",
  "transaction_level": "",
  "transaction_type": "",
  "surrender_amount": "",
  "surrender_percent": "",
  "net_gross_indicator": "",
  "state_withholding_override": "",
  "state_withholding_amount": "",
  "federal_withholding_override": "",
  "federal_withholding_amount": "",
  "federal_withholding_percentage": "",
  "agent_recommend_or_authorized" : "",
  "state_withholding_percentage" : "",
  "eft_id": "",
  "overnight": "",
  "account_type": "",
  "bank_name": "",
  "account_number": "",
  "transit_number": "",
  "distribution_code": "",
  "payee_firstname": "",
  "payee_lastname": "",
  "payee_address1": "",
  "payee_address2": "",
  "payee_address3": "",
  "payee_city": "",
  "payee_state": "",
  "payee_zip": "",
  "payee_country": "",
  "confirm_override": "",
  "passed_enhanced_security": "",
  "firm_id": "",
  "Requested_Date":""
}
export const partialCallNoteTransaction = {
"Requested_Date":"Requested Date",
"effective_date":"Effective Date",
"surrender_amount": "Amount",
"surrender_percent":"Percent",
"federal_withholding_override":"Fed w/h",
"state_withholding_override":"State w/h",
"Surrender_Fee":"Surrender Fee",
 "transaction_level": "Transaction level",
  "transaction_type": "Transaction type",
  "payment_method":"Payment Method"
}
export const fullSurrenderCallNoteTransaction= {
  "Requested_Date":"Requested Date",
  "effective_date" :"Effective Date",
  "federal_withholding_override" :"Fed w/h",
  "state_withholding_override" : "State w/h",
  "administrative_fee" : "Admin Fee ",
  "surrender_penalty" : "Surrender Penalty",
  "gmib_fee_amount" :"GMIB Fee Amount ",
  "edb_fee_amount" : "EDB Fee Amount ",
  "gwb_lwg_fee_amount" : "GWB/LWG Fee Amount ",
  "odb_fee_amount" : "ODB Fee Amount ",
  "gmab_fee_amount" : "GMAB Fee Amount ",
  "total_mva_amount" : "MVA Amount ", 
  "payment_method" : "Payment Method"  
}
export const systematicWithdrawalNoteTransaction= {
  "effective_date":"Effective Date",
  "next_date":"Start Date",
  "transaction_level":"Transaction Level" ,
  "withdrawal_amount":  "Amount",
  "payout_percent": "Percent",
  "frequency":"Frequency",
  "federal_withholding_override":"Fed w/h",
  "state_withholding_override":"State w/h",  
  "payment_method":    "Payment Method" ,  
}
export const systematicWithdrawalRemoveTransaction= {
  "effective_date":"Effective Date",
  "transaction_type": "Transaction type", 
  "Requested_Date":"Requested Date", 
}

export const mockResponse = {
    "net_check_amount": "236813.01",
    "_links": {
      "self": {
        "href": "http://localhost:8080/wma-rest/contracts/UGFO0KXw5m9F02fyBdMsXR8CBAyCEvtH3ZQVqHIbVRs/partialsurrenderquote?effectiveDate=07/13/2020&amountReq=0.0&netGrossInd=G&valaStWithhOrInd=false&valaIrsOr=false&rfeeOverride=false"
      },
      "up": {
        "href": "http://localhost:8080/wma-rest/contracts/UGFO0KXw5m9F02fyBdMsXR8CBAyCEvtH3ZQVqHIbVRs"
      }
    }
  }

export const partialMockResponse = {
  "net_check_amount": "236813.01",
  "_links": {
    "self": {
      "href": "http://localhost:8080/wma-rest/contracts/UGFO0KXw5m9F02fyBdMsXR8CBAyCEvtH3ZQVqHIbVRs/partialsurrenderquote?effectiveDate=07/13/2020&amountReq=0.0&netGrossInd=G&valaStWithhOrInd=false&valaIrsOr=false&rfeeOverride=false"
    },
    "up": {
      "href": "http://localhost:8080/wma-rest/contracts/UGFO0KXw5m9F02fyBdMsXR8CBAyCEvtH3ZQVqHIbVRs"
    }
  },
  "total_mva_amount": "0.00",
  "_options": {
    "links": [
      {
        "schema": {
          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:PolicyValuesAnnuity",
          "type": "object",
          "properties": {
            "gwb_lwg_fee_amount": {
              "format": "currency",
              "type": "number"
            },
            "earned_loan_interest": {
              "format": "currency",
              "type": "number"
            },
            "total_mva_amount": {
              "format": "currency",
              "type": "number"
            },
            "total_check_amount": {
              "format": "currency",
              "type": "number"
            },
            "_options": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
              "type": "object",
              "properties": {
                "links": {
                  "type": "array",
                  "items": {
                    "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
                    "type": "object",
                    "properties": {
                      "schema": {
                        "type": "object",
                        "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                      },
                      "targetSchema": {
                        "additionalProperties": {
                          "type": "string"
                        },
                        "type": "object"
                      },
                      "method": {
                        "type": "string"
                      },
                      "methods": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "rel": {
                        "type": "string"
                      },
                      "mediaType": {
                        "type": "string"
                      },
                      "href": {
                        "type": "string"
                      },
                      "title": {
                        "type": "string"
                      },
                      "required": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "example": {
                        "type": "string"
                      }
                    }
                  }
                },
                "properties": {
                  "additionalProperties": {
                    "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
                    "type": "object",
                    "properties": {
                      "disallow": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "$schema": {
                        "type": "string"
                      },
                      "readonly": {
                        "type": "boolean"
                      },
                      "extends": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "description": {
                        "type": "string"
                      },
                      "id": {
                        "type": "string"
                      },
                      "$ref": {
                        "type": "string"
                      },
                      "required": {
                        "type": "boolean"
                      }
                    }
                  },
                  "type": "object"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                }
              }
            },
            "max_gross_partial_surrender_amount": {
              "format": "currency",
              "type": "number"
            },
            "free_amount_gain": {
              "format": "currency",
              "type": "number"
            },
            "post_tefra_tamra_in": {
              "format": "currency",
              "type": "number"
            },
            "surrender_penalty": {
              "format": "currency",
              "type": "number"
            },
            "pre_tefra_tamra_amount": {
              "format": "currency",
              "type": "number"
            },
            "surrender_schedule": {
              "type": "string"
            },
            "cash_surrender_value": {
              "format": "currency",
              "type": "number"
            },
            "administrative_fee": {
              "format": "currency",
              "type": "number"
            },
            "municipal_withholding": {
              "format": "currency",
              "type": "number"
            },
            "post_tefra_tamra_amount": {
              "format": "currency",
              "type": "number"
            },
            "_embedded": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
              "type": "object",
              "properties": {
                "status_report": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
                  "type": "object",
                  "properties": {
                    "_links": {
                      "additionalProperties": {
                        "type": "any"
                      },
                      "type": "object"
                    },
                    "messages": {
                      "type": "array",
                      "items": {
                        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                        "type": "object",
                        "properties": {
                          "severity": {
                            "type": "string"
                          },
                          "message": {
                            "type": "string"
                          }
                        }
                      }
                    },
                    "consistent": {
                      "type": "boolean"
                    }
                  }
                }
              }
            },
            "gmab_fee_amount": {
              "format": "currency",
              "type": "number"
            },
            "free_withdrawal_amount": {
              "format": "currency",
              "type": "number"
            },
            "links": {
              "type": "array",
              "items": {
                "id": "urn:jsonschema:org:springframework:hateoas:Link",
                "type": "object",
                "properties": {
                  "hreflang": {
                    "type": "string"
                  },
                  "rel": {
                    "type": "string"
                  },
                  "href": {
                    "type": "string"
                  },
                  "media": {
                    "type": "string"
                  },
                  "title": {
                    "type": "string"
                  },
                  "type": {
                    "type": "string"
                  },
                  "deprecation": {
                    "type": "string"
                  }
                }
              }
            },
            "edb_fee_amount": {
              "format": "currency",
              "type": "number"
            },
            "net_check_amount": {
              "format": "currency",
              "type": "number"
            },
            "max_net_partial_surrender_amount": {
              "format": "currency",
              "type": "number"
            },
            "gmib_fee_amount": {
              "format": "currency",
              "type": "number"
            },
            "max_loanable_value": {
              "format": "currency",
              "type": "number"
            },
            "fav_code": {
              "type": "string"
            },
            "min_guaranteed_int_rate": {
              "type": "number"
            },
            "gross_check_amount": {
              "format": "currency",
              "type": "number"
            },
            "state_withholding_amt": {
              "format": "currency",
              "type": "number"
            },
            "gross_surrender_amount": {
              "format": "currency",
              "type": "number"
            },
            "quote_effective_date": {
              "format": "date",
              "type": "string"
            },
            "accumulated_cash_value": {
              "format": "currency",
              "type": "number"
            },
            "premium_deposit_fund": {
              "format": "currency",
              "type": "number"
            },
            "gross_cash_value_amount": {
              "format": "currency",
              "type": "number"
            },
            "federal_withhold_amount": {
              "format": "currency",
              "type": "number"
            },
            "pre_tefra_tamra_in": {
              "format": "currency",
              "type": "number"
            },
            "free_amount_premium": {
              "format": "currency",
              "type": "number"
            },
            "odb_fee_amount": {
              "format": "currency",
              "type": "number"
            }
          }
        },
        "method": "GET",
        "rel": "fetch",
        "href": "http://localhost:8080/wma-rest/contracts/UGFO0KXw5m9F02fyBdMsXR8CBAyCEvtH3ZQVqHIbVRs/financialvalues",
        "title": "fetch policy financial values"
      }
    ],
    "properties": {
      "gwb_lwg_fee_amount": {
        "format": "currency",
        "type": "number"
      },
      "earned_loan_interest": {
        "format": "currency",
        "type": "number"
      },
      "total_mva_amount": {
        "format": "currency",
        "type": "number"
      },
      "total_check_amount": {
        "format": "currency",
        "type": "number"
      },
      "_options": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
        "type": "object",
        "properties": {
          "links": {
            "type": "array",
            "items": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
              "type": "object",
              "properties": {
                "schema": {
                  "type": "object",
                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                },
                "targetSchema": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "method": {
                  "type": "string"
                },
                "methods": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "rel": {
                  "type": "string"
                },
                "mediaType": {
                  "type": "string"
                },
                "href": {
                  "type": "string"
                },
                "title": {
                  "type": "string"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "example": {
                  "type": "string"
                }
              }
            }
          },
          "properties": {
            "additionalProperties": {
              "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
              "type": "object",
              "properties": {
                "disallow": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "$schema": {
                  "type": "string"
                },
                "readonly": {
                  "type": "boolean"
                },
                "extends": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "description": {
                  "type": "string"
                },
                "id": {
                  "type": "string"
                },
                "$ref": {
                  "type": "string"
                },
                "required": {
                  "type": "boolean"
                }
              }
            },
            "type": "object"
          },
          "required": {
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      },
      "max_gross_partial_surrender_amount": {
        "format": "currency",
        "type": "number"
      },
      "free_amount_gain": {
        "format": "currency",
        "type": "number"
      },
      "post_tefra_tamra_in": {
        "format": "currency",
        "type": "number"
      },
      "surrender_penalty": {
        "format": "currency",
        "type": "number"
      },
      "pre_tefra_tamra_amount": {
        "format": "currency",
        "type": "number"
      },
      "surrender_schedule": {
        "type": "string"
      },
      "cash_surrender_value": {
        "format": "currency",
        "type": "number"
      },
      "administrative_fee": {
        "format": "currency",
        "type": "number"
      },
      "municipal_withholding": {
        "format": "currency",
        "type": "number"
      },
      "post_tefra_tamra_amount": {
        "format": "currency",
        "type": "number"
      },
      "_embedded": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
        "type": "object",
        "properties": {
          "status_report": {
            "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
            "type": "object",
            "properties": {
              "_links": {
                "additionalProperties": {
                  "type": "any"
                },
                "type": "object"
              },
              "messages": {
                "type": "array",
                "items": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                  "type": "object",
                  "properties": {
                    "severity": {
                      "type": "string"
                    },
                    "message": {
                      "type": "string"
                    }
                  }
                }
              },
              "consistent": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "gmab_fee_amount": {
        "format": "currency",
        "type": "number"
      },
      "free_withdrawal_amount": {
        "format": "currency",
        "type": "number"
      },
      "links": {
        "type": "array",
        "items": {
          "id": "urn:jsonschema:org:springframework:hateoas:Link",
          "type": "object",
          "properties": {
            "hreflang": {
              "type": "string"
            },
            "rel": {
              "type": "string"
            },
            "href": {
              "type": "string"
            },
            "media": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "type": {
              "type": "string"
            },
            "deprecation": {
              "type": "string"
            }
          }
        }
      },
      "edb_fee_amount": {
        "format": "currency",
        "type": "number"
      },
      "net_check_amount": {
        "format": "currency",
        "type": "number"
      },
      "max_net_partial_surrender_amount": {
        "format": "currency",
        "type": "number"
      },
      "gmib_fee_amount": {
        "format": "currency",
        "type": "number"
      },
      "max_loanable_value": {
        "format": "currency",
        "type": "number"
      },
      "fav_code": {
        "type": "string"
      },
      "min_guaranteed_int_rate": {
        "type": "number"
      },
      "gross_check_amount": {
        "format": "currency",
        "type": "number"
      },
      "state_withholding_amt": {
        "format": "currency",
        "type": "number"
      },
      "gross_surrender_amount": {
        "format": "currency",
        "type": "number"
      },
      "quote_effective_date": {
        "format": "date",
        "type": "string"
      },
      "accumulated_cash_value": {
        "format": "currency",
        "type": "number"
      },
      "premium_deposit_fund": {
        "format": "currency",
        "type": "number"
      },
      "gross_cash_value_amount": {
        "format": "currency",
        "type": "number"
      },
      "federal_withhold_amount": {
        "format": "currency",
        "type": "number"
      },
      "pre_tefra_tamra_in": {
        "format": "currency",
        "type": "number"
      },
      "free_amount_premium": {
        "format": "currency",
        "type": "number"
      },
      "odb_fee_amount": {
        "format": "currency",
        "type": "number"
      }
    }
  },
  "free_amount_gain": "0.00",
  "surrender_penalty": "0.00",
  "state_withholding_amt": "0.00",
  "_embedded": {
    "status_report": {
      "messages": [
        {
          "severity": "INFO",
          "message": "W078-SEC MAX LOADS EXCEEDED"
        },
        {
          "severity": "INFO",
          "message": "W261-WITHHOLD AMT USES CURR VALUE"
        }
      ],
      "consistent": true
    }
  },
  "gross_surrender_amount": "252236.68",
  "quote_effective_date": "07/13/2020",
  "gross_cash_value_amount": "254236.68",
  "free_withdrawal_amount": "0.00",
  "federal_withhold_amount": "15423.67",
  "free_amount_premium": "0.00"
}

export const fullMockResponse = {
  "gwb_lwg_fee_amount": "0.00",
  "_links": {
    "self": {
      "href": "http://localhost:8080/wma-rest/contracts/dWiRmUFfFB_31WeG93h7ptqbxyrw2akWJR4ntmtx30I/values/fullSurrender?effectiveDate=07/24/2020"
    },
    "up": {
      "href": "http://localhost:8080/wma-rest/contracts/dWiRmUFfFB_31WeG93h7ptqbxyrw2akWJR4ntmtx30I"
    }
  },
  "total_mva_amount": "0.00",
  "_options": {
    "links": [
      {
        "schema": {
          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:PolicyFinancialValues",
          "type": "object",
          "properties": {
            "_embedded": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
              "type": "object",
              "properties": {
                "status_report": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
                  "type": "object",
                  "properties": {
                    "_links": {
                      "additionalProperties": {
                        "type": "any"
                      },
                      "type": "object"
                    },
                    "messages": {
                      "type": "array",
                      "items": {
                        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                        "type": "object",
                        "properties": {
                          "severity": {
                            "type": "string"
                          },
                          "message": {
                            "type": "string"
                          }
                        }
                      }
                    },
                    "consistent": {
                      "type": "boolean"
                    }
                  }
                }
              }
            },
            "quote_effective_date": {
              "format": "date",
              "type": "string"
            },
            "_options": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
              "type": "object",
              "properties": {
                "links": {
                  "type": "array",
                  "items": {
                    "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
                    "type": "object",
                    "properties": {
                      "schema": {
                        "type": "object",
                        "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                      },
                      "targetSchema": {
                        "additionalProperties": {
                          "type": "string"
                        },
                        "type": "object"
                      },
                      "method": {
                        "type": "string"
                      },
                      "methods": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "rel": {
                        "type": "string"
                      },
                      "mediaType": {
                        "type": "string"
                      },
                      "href": {
                        "type": "string"
                      },
                      "title": {
                        "type": "string"
                      },
                      "required": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "example": {
                        "type": "string"
                      }
                    }
                  }
                },
                "properties": {
                  "additionalProperties": {
                    "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
                    "type": "object",
                    "properties": {
                      "disallow": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "$schema": {
                        "type": "string"
                      },
                      "readonly": {
                        "type": "boolean"
                      },
                      "extends": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "description": {
                        "type": "string"
                      },
                      "id": {
                        "type": "string"
                      },
                      "$ref": {
                        "type": "string"
                      },
                      "required": {
                        "type": "boolean"
                      }
                    }
                  },
                  "type": "object"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                }
              }
            },
            "links": {
              "type": "array",
              "items": {
                "id": "urn:jsonschema:org:springframework:hateoas:Link",
                "type": "object",
                "properties": {
                  "hreflang": {
                    "type": "string"
                  },
                  "rel": {
                    "type": "string"
                  },
                  "href": {
                    "type": "string"
                  },
                  "media": {
                    "type": "string"
                  },
                  "title": {
                    "type": "string"
                  },
                  "type": {
                    "type": "string"
                  },
                  "deprecation": {
                    "type": "string"
                  }
                }
              }
            }
          }
        },
        "method": "GET",
        "rel": "fetch",
        "href": "http://localhost:8080/wma-rest/contracts/dWiRmUFfFB_31WeG93h7ptqbxyrw2akWJR4ntmtx30I/values/fullSurrender?effectiveDate=07%252F24%252F2020",
        "title": "fetch policy Full Surrender Quote values"
      }
    ],
    "properties": {
      "_embedded": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
        "type": "object",
        "properties": {
          "status_report": {
            "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
            "type": "object",
            "properties": {
              "_links": {
                "additionalProperties": {
                  "type": "any"
                },
                "type": "object"
              },
              "messages": {
                "type": "array",
                "items": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                  "type": "object",
                  "properties": {
                    "severity": {
                      "type": "string"
                    },
                    "message": {
                      "type": "string"
                    }
                  }
                }
              },
              "consistent": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "quote_effective_date": {
        "format": "date",
        "type": "string"
      },
      "_options": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
        "type": "object",
        "properties": {
          "links": {
            "type": "array",
            "items": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
              "type": "object",
              "properties": {
                "schema": {
                  "type": "object",
                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                },
                "targetSchema": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "method": {
                  "type": "string"
                },
                "methods": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "rel": {
                  "type": "string"
                },
                "mediaType": {
                  "type": "string"
                },
                "href": {
                  "type": "string"
                },
                "title": {
                  "type": "string"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "example": {
                  "type": "string"
                }
              }
            }
          },
          "properties": {
            "additionalProperties": {
              "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
              "type": "object",
              "properties": {
                "disallow": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "$schema": {
                  "type": "string"
                },
                "readonly": {
                  "type": "boolean"
                },
                "extends": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "description": {
                  "type": "string"
                },
                "id": {
                  "type": "string"
                },
                "$ref": {
                  "type": "string"
                },
                "required": {
                  "type": "boolean"
                }
              }
            },
            "type": "object"
          },
          "required": {
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      },
      "links": {
        "type": "array",
        "items": {
          "id": "urn:jsonschema:org:springframework:hateoas:Link",
          "type": "object",
          "properties": {
            "hreflang": {
              "type": "string"
            },
            "rel": {
              "type": "string"
            },
            "href": {
              "type": "string"
            },
            "media": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "type": {
              "type": "string"
            },
            "deprecation": {
              "type": "string"
            }
          }
        }
      }
    }
  },
  "max_gross_partial_surrender_amount": "0.00",
  "surrender_penalty": "0.00",
  "pre_tefra_tamra_amount": "0.00",
  "cash_surrender_value": "103302.01",
  "administrative_fee": "30.00",
  "municipal_withholding": "0.00",
  "post_tefra_tamra_amount": "0.00",
  "_embedded": {
    "status_report": {
      "messages": [
        {
          "severity": "INFO",
          "message": "WB59-POLICY IS TERMINATED. VALUES MAY NOT BE ACCURATE."
        }
      ],
      "consistent": true
    }
  },
  "gmab_fee_amount": "0.00",
  "free_withdrawal_amount": "0.00",
  "edb_fee_amount": "379.10",
  "max_net_partial_surrender_amount": "97870.72",
  "gmib_fee_amount": "631.82",
  "fav_code": "000130",
  "gross_check_amount": "103302.01",
  "state_withholding_amt": "0.00",
  "gross_surrender_amount": "0.00",
  "quote_effective_date": "07/24/2020",
  "accumulated_cash_value": "104342.93",
  "federal_withhold_amount": "5431.29",
  "free_amount_premium": "50000.00",
  "odb_fee_amount": "0.00"
};

export const fundTableDataMock = [
  { "id": 311, "name": "Metlife Index portfoilo", "amount": "$12121.11", "inputval": '' },
  { "id": 311, "name": "Metlife Index portfoilo", "amount": "$12121.11", "inputval": '' },
  { "id": 311, "name": "Metlife Index portfoilo", "amount": "$12121.11", "inputval": '' },
  { "id": 311, "name": "Metlife Index portfoilo", "amount": "$12121.11", "inputval": '' },
  { "id": 311, "name": "Metlife Index portfoilo", "amount": "$12121.11", "inputval": '' },
];

export const withdrawalsMockResponse = {
  "payee_zip": "63040",
  "fundValues": [
    {
        "fund_value": 51312.79,
        "surrender_percent": "0.0",
        "fund_number": 3201,
        "surrender_amt": "0.00",
        "fund_name": "6-Year Russell 2000 Annual Sum with Cap Rate"
    },
    {
        "fund_value": 61950.28,
        "surrender_percent": "0.0",
        "fund_number": 9001,
        "surrender_amt": "0.00",
        "fund_name": "1-Year Fixed Interest Term"
    }
  ],
  "account_number": "5244060675917",
  "account_type": "2-Checking",
  "transaction_level": "",
  "payee_country": "US",
  "_links": {
      "status_report": {
          "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/partial/statusreport"
      },
      "self": {
          "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/partial"
      },
      "up": {
          "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A"
      }
  },
  "payee_firstname": "MARLO D",
  "_options": {
      "links": [
          {
              "schema": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:PolicyPartialWithdrawalAnnuity",
                  "type": "object",
                  "properties": {
                      "payee_zip": {
                          "type": "string"
                      },
                      "account_number": {
                          "type": "string"
                      },
                      "account_type": {
                          "oneOf": [
                              {
                                  "title": "1-Savings",
                                  "enum": [
                                      "1"
                                  ]
                              },
                              {
                                  "title": "2-Checking",
                                  "enum": [
                                      "2"
                                  ]
                              },
                              {
                                  "title": "3-Credit Card",
                                  "enum": [
                                      "3"
                                  ]
                              },
                              {
                                  "title": "4-Debit Card",
                                  "enum": [
                                      "4"
                                  ]
                              },
                              {
                                  "title": "5-Brokerage",
                                  "enum": [
                                      "5"
                                  ]
                              }
                          ],
                          "type": "string"
                      },
                      "fundValues": {
                          "type": "array",
                          "items": {
                              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:FundWithdrawlInfo",
                              "type": "object",
                              "properties": {
                                  "fund_value": {
                                      "type": "number"
                                  },
                                  "surrender_percent": {
                                      "type": "any"
                                  },
                                  "fund_number": {
                                      "type": "integer"
                                  },
                                  "surrender_amt": {
                                      "type": "any"
                                  },
                                  "fund_name": {
                                      "type": "string"
                                  }
                              }
                          }
                      },
                      "transaction_level": {
                          "oneOf": [
                              {
                                  "title": "Removed from specified funds",
                                  "enum": [
                                      "F"
                                  ]
                              },
                              {
                                  "title": "Removed Pro Rata basis",
                                  "enum": [
                                      "P"
                                  ]
                              }
                          ],
                          "type": "string"
                      },
                      "payee_country": {
                          "type": "string"
                      },
                      "payee_firstname": {
                          "type": "string"
                      },
                      "_options": {
                          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
                          "type": "object",
                          "properties": {
                              "links": {
                                  "type": "array",
                                  "items": {
                                      "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
                                      "type": "object",
                                      "properties": {
                                          "schema": {
                                              "type": "object",
                                              "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                                          },
                                          "targetSchema": {
                                              "additionalProperties": {
                                                  "type": "string"
                                              },
                                              "type": "object"
                                          },
                                          "method": {
                                              "type": "string"
                                          },
                                          "methods": {
                                              "type": "array",
                                              "items": {
                                                  "type": "string"
                                              }
                                          },
                                          "rel": {
                                              "type": "string"
                                          },
                                          "mediaType": {
                                              "type": "string"
                                          },
                                          "href": {
                                              "type": "string"
                                          },
                                          "title": {
                                              "type": "string"
                                          },
                                          "required": {
                                              "type": "array",
                                              "items": {
                                                  "type": "string"
                                              }
                                          },
                                          "example": {
                                              "type": "string"
                                          }
                                      }
                                  }
                              },
                              "properties": {
                                  "additionalProperties": {
                                      "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
                                      "type": "object",
                                      "properties": {
                                          "disallow": {
                                              "type": "array",
                                              "items": {
                                                  "type": "object",
                                                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                                              }
                                          },
                                          "$schema": {
                                              "type": "string"
                                          },
                                          "readonly": {
                                              "type": "boolean"
                                          },
                                          "extends": {
                                              "type": "array",
                                              "items": {
                                                  "type": "object",
                                                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                                              }
                                          },
                                          "description": {
                                              "type": "string"
                                          },
                                          "id": {
                                              "type": "string"
                                          },
                                          "$ref": {
                                              "type": "string"
                                          },
                                          "required": {
                                              "type": "boolean"
                                          }
                                      }
                                  },
                                  "type": "object"
                              },
                              "required": {
                                  "type": "array",
                                  "items": {
                                      "type": "string"
                                  }
                              }
                          }
                      },
                      "eft_id": {
                          "type": "integer"
                      },
                      "payee_address1": {
                          "type": "string"
                      },
                      "payee_address2": {
                          "type": "string"
                      },
                      "payeeName": {
                          "type": "string"
                      },
                      "policy_number": {
                          "type": "string"
                      },
                      "_embedded": {
                          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
                          "type": "object",
                          "properties": {
                              "status_report": {
                                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
                                  "type": "object",
                                  "properties": {
                                      "_links": {
                                          "additionalProperties": {
                                              "type": "any"
                                          },
                                          "type": "object"
                                      },
                                      "messages": {
                                          "type": "array",
                                          "items": {
                                              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                                              "type": "object",
                                              "properties": {
                                                  "severity": {
                                                      "type": "string"
                                                  },
                                                  "message": {
                                                      "type": "string"
                                                  }
                                              }
                                          }
                                      },
                                      "consistent": {
                                          "type": "boolean"
                                      }
                                  }
                              }
                          }
                      },
                      "payee_city": {
                          "type": "string"
                      },
                      "payee_state": {
                          "type": "string"
                      },
                      "passed_enhanced_security": {
                          "type": "string"
                      },
                      "effective_date": {
                          "format": "date",
                          "type": "string"
                      },
                      "bank_name": {
                          "type": "string"
                      },
                      "links": {
                          "type": "array",
                          "items": {
                              "id": "urn:jsonschema:org:springframework:hateoas:Link",
                              "type": "object",
                              "properties": {
                                  "hreflang": {
                                      "type": "string"
                                  },
                                  "rel": {
                                      "type": "string"
                                  },
                                  "href": {
                                      "type": "string"
                                  },
                                  "media": {
                                      "type": "string"
                                  },
                                  "title": {
                                      "type": "string"
                                  },
                                  "type": {
                                      "type": "string"
                                  },
                                  "deprecation": {
                                      "type": "string"
                                  }
                              }
                          }
                      },
                      "federal_withholding_override": {
                          "type": "string"
                      },
                      "state_withholding_override": {
                          "type": "string"
                      },
                      "federal_withholding_percentage": {
                          "type": "number"
                      },
                      "payment_method": {
                          "oneOf": [
                              {
                                  "title": " ",
                                  "enum": [
                                      " "
                                  ]
                              },
                              {
                                  "title": "Cash",
                                  "enum": [
                                      "C"
                                  ]
                              },
                              {
                                  "title": "Check",
                                  "enum": [
                                      "D"
                                  ]
                              },
                              {
                                  "title": "DTCC",
                                  "enum": [
                                      "Z"
                                  ]
                              },
                              {
                                  "title": "EFT",
                                  "enum": [
                                      "E"
                                  ]
                              },
                              {
                                  "title": "Group",
                                  "enum": [
                                      "G"
                                  ]
                              },
                              {
                                  "title": "MFC",
                                  "enum": [
                                      "M"
                                  ]
                              },
                              {
                                  "title": "No Check",
                                  "enum": [
                                      "N"
                                  ]
                              },
                              {
                                  "title": "Suspense",
                                  "enum": [
                                      "S"
                                  ]
                              },
                              {
                                  "title": "TCA",
                                  "enum": [
                                      "T"
                                  ]
                              },
                              {
                                  "title": "Wire Transfer",
                                  "enum": [
                                      "W"
                                  ]
                              }
                          ],
                          "type": "string"
                      },
                      "state_withholding_percentage": {
                          "type": "number"
                      },
                      "net_gross_indicator": {
                          "oneOf": [
                              {
                                  "title": "Gross Amount",
                                  "enum": [
                                      "G"
                                  ]
                              },
                              {
                                  "title": "Net Amount",
                                  "enum": [
                                      "N"
                                  ]
                              },
                              {
                                  "title": "Net Net Amount",
                                  "enum": [
                                      "A"
                                  ]
                              }
                          ],
                          "type": "string"
                      },
                      "state_withholding_amount": {
                          "format": "currency",
                          "type": "number"
                      },
                      "distribution_code": {
                          "type": "string"
                      },
                      "transaction_type": {
                          "oneOf": [
                              {
                                  "title": "Amount",
                                  "enum": [
                                      "A"
                                  ]
                              },
                              {
                                  "title": "Percent",
                                  "enum": [
                                      "P"
                                  ]
                              }
                          ],
                          "type": "string"
                      },
                      "federal_withholding_amount": {
                          "format": "currency",
                          "type": "number"
                      },
                      "payee_lastname": {
                          "type": "string"
                      },
                      "surrender_percent": {
                          "type": "number"
                      },
                      "quote_effective_date": {
                          "format": "date",
                          "type": "string"
                      },
                      "surrender_amount": {
                          "format": "currency",
                          "type": "number"
                      },
                      "transit_number": {
                          "type": "string"
                      },
                      "state_enabled": {
                          "type": "string"
                      }
                  }
              },
              "method": "GET",
              "rel": "fetch",
              "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/partial",
              "title": "fetch policy surrender"
          }
      ],
      "properties": {
          "payee_zip": {
              "type": "string"
          },
          "account_number": {
              "type": "string"
          },
          "account_type": {
              "oneOf": [
                  {
                      "title": "1-Savings",
                      "enum": [
                          "1"
                      ]
                  },
                  {
                      "title": "2-Checking",
                      "enum": [
                          "2"
                      ]
                  },
                  {
                      "title": "3-Credit Card",
                      "enum": [
                          "3"
                      ]
                  },
                  {
                      "title": "4-Debit Card",
                      "enum": [
                          "4"
                      ]
                  },
                  {
                      "title": "5-Brokerage",
                      "enum": [
                          "5"
                      ]
                  }
              ],
              "type": "string"
          },
          "fundValues": {
              "type": "array",
              "items": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:FundWithdrawlInfo",
                  "type": "object",
                  "properties": {
                      "fund_value": {
                          "type": "number"
                      },
                      "surrender_percent": {
                          "type": "any"
                      },
                      "fund_number": {
                          "type": "integer"
                      },
                      "surrender_amt": {
                          "type": "any"
                      },
                      "fund_name": {
                          "type": "string"
                      }
                  }
              }
          },
          "transaction_level": {
              "oneOf": [
                  {
                      "title": "Removed from specified funds",
                      "enum": [
                          "F"
                      ]
                  },
                  {
                      "title": "Removed Pro Rata basis",
                      "enum": [
                          "P"
                      ]
                  }
              ],
              "type": "string"
          },
          "payee_country": {
              "type": "string"
          },
          "payee_firstname": {
              "type": "string"
          },
          "_options": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
              "type": "object",
              "properties": {
                  "links": {
                      "type": "array",
                      "items": {
                          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
                          "type": "object",
                          "properties": {
                              "schema": {
                                  "type": "object",
                                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                              },
                              "targetSchema": {
                                  "additionalProperties": {
                                      "type": "string"
                                  },
                                  "type": "object"
                              },
                              "method": {
                                  "type": "string"
                              },
                              "methods": {
                                  "type": "array",
                                  "items": {
                                      "type": "string"
                                  }
                              },
                              "rel": {
                                  "type": "string"
                              },
                              "mediaType": {
                                  "type": "string"
                              },
                              "href": {
                                  "type": "string"
                              },
                              "title": {
                                  "type": "string"
                              },
                              "required": {
                                  "type": "array",
                                  "items": {
                                      "type": "string"
                                  }
                              },
                              "example": {
                                  "type": "string"
                              }
                          }
                      }
                  },
                  "properties": {
                      "additionalProperties": {
                          "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
                          "type": "object",
                          "properties": {
                              "disallow": {
                                  "type": "array",
                                  "items": {
                                      "type": "object",
                                      "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                                  }
                              },
                              "$schema": {
                                  "type": "string"
                              },
                              "readonly": {
                                  "type": "boolean"
                              },
                              "extends": {
                                  "type": "array",
                                  "items": {
                                      "type": "object",
                                      "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                                  }
                              },
                              "description": {
                                  "type": "string"
                              },
                              "id": {
                                  "type": "string"
                              },
                              "$ref": {
                                  "type": "string"
                              },
                              "required": {
                                  "type": "boolean"
                              }
                          }
                      },
                      "type": "object"
                  },
                  "required": {
                      "type": "array",
                      "items": {
                          "type": "string"
                      }
                  }
              }
          },
          "eft_id": {
              "type": "integer"
          },
          "payee_address1": {
              "type": "string"
          },
          "payee_address2": {
              "type": "string"
          },
          "payeeName": {
              "type": "string"
          },
          "policy_number": {
              "type": "string"
          },
          "_embedded": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
              "type": "object",
              "properties": {
                  "status_report": {
                      "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
                      "type": "object",
                      "properties": {
                          "_links": {
                              "additionalProperties": {
                                  "type": "any"
                              },
                              "type": "object"
                          },
                          "messages": {
                              "type": "array",
                              "items": {
                                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                                  "type": "object",
                                  "properties": {
                                      "severity": {
                                          "type": "string"
                                      },
                                      "message": {
                                          "type": "string"
                                      }
                                  }
                              }
                          },
                          "consistent": {
                              "type": "boolean"
                          }
                      }
                  }
              }
          },
          "payee_city": {
              "type": "string"
          },
          "payee_state": {
              "type": "string"
          },
          "passed_enhanced_security": {
              "type": "string"
          },
          "effective_date": {
              "format": "date",
              "type": "string"
          },
          "bank_name": {
              "type": "string"
          },
          "links": {
              "type": "array",
              "items": {
                  "id": "urn:jsonschema:org:springframework:hateoas:Link",
                  "type": "object",
                  "properties": {
                      "hreflang": {
                          "type": "string"
                      },
                      "rel": {
                          "type": "string"
                      },
                      "href": {
                          "type": "string"
                      },
                      "media": {
                          "type": "string"
                      },
                      "title": {
                          "type": "string"
                      },
                      "type": {
                          "type": "string"
                      },
                      "deprecation": {
                          "type": "string"
                      }
                  }
              }
          },
          "federal_withholding_override": {
              "type": "string"
          },
          "state_withholding_override": {
              "type": "string"
          },
          "federal_withholding_percentage": {
              "type": "number"
          },
          "payment_method": {
              "oneOf": [
                  {
                      "title": " ",
                      "enum": [
                          " "
                      ]
                  },
                  {
                      "title": "Cash",
                      "enum": [
                          "C"
                      ]
                  },
                  {
                      "title": "Check",
                      "enum": [
                          "D"
                      ]
                  },
                  {
                      "title": "DTCC",
                      "enum": [
                          "Z"
                      ]
                  },
                  {
                      "title": "EFT",
                      "enum": [
                          "E"
                      ]
                  },
                  {
                      "title": "Group",
                      "enum": [
                          "G"
                      ]
                  },
                  {
                      "title": "MFC",
                      "enum": [
                          "M"
                      ]
                  },
                  {
                      "title": "No Check",
                      "enum": [
                          "N"
                      ]
                  },
                  {
                      "title": "Suspense",
                      "enum": [
                          "S"
                      ]
                  },
                  {
                      "title": "TCA",
                      "enum": [
                          "T"
                      ]
                  },
                  {
                      "title": "Wire Transfer",
                      "enum": [
                          "W"
                      ]
                  }
              ],
              "type": "string"
          },
          "state_withholding_percentage": {
              "type": "number"
          },
          "net_gross_indicator": {
              "oneOf": [
                  {
                      "title": "Gross Amount",
                      "enum": [
                          "G"
                      ]
                  },
                  {
                      "title": "Net Amount",
                      "enum": [
                          "N"
                      ]
                  },
                  {
                      "title": "Net Net Amount",
                      "enum": [
                          "A"
                      ]
                  }
              ],
              "type": "string"
          },
          "state_withholding_amount": {
              "format": "currency",
              "type": "number"
          },
          "distribution_code": {
              "type": "string"
          },
          "transaction_type": {
              "oneOf": [
                  {
                      "title": "Amount",
                      "enum": [
                          "A"
                      ]
                  },
                  {
                      "title": "Percent",
                      "enum": [
                          "P"
                      ]
                  }
              ],
              "type": "string"
          },
          "federal_withholding_amount": {
              "format": "currency",
              "type": "number"
          },
          "payee_lastname": {
              "type": "string"
          },
          "surrender_percent": {
              "type": "number"
          },
          "quote_effective_date": {
              "format": "date",
              "type": "string"
          },
          "surrender_amount": {
              "format": "currency",
              "type": "number"
          },
          "transit_number": {
              "type": "string"
          },
          "state_enabled": {
              "type": "string"
          }
      },
      "required": [
          "transaction_type",
          "net_gross_indicator",
          "effective_date",
          "payment_method",
          "passed_enhanced_security",
          "transaction_level"
      ]
  },
  "distribution_code": "U-IRA Transfer Distribution",
  "eft_id": 1,
  "transaction_type": "",
  "payee_address1": "108 SAIL PORT CT",
  "payee_address2": "",
  "payeeName": "GEORGE                             MARLO D",
  "payee_lastname": "GEORGE",
  "surrender_percent": "0.0",
  "payee_city": "WILDWOOD",
  "payee_state": "MO",
  "surrender_amount": "0.00",
  "effective_date": "07/16/2020",
  "bank_name": "WELLS FARGO BANK, NA",
  "transit_number": "063107513",
  "state_enabled": "Y",
  "payment_method": [
      "Check",
      "DTCC",
      "EFT"
  ]
}

export const fullWithdrawalsMockResponse = {
  "state_withholding_percentage": "0.0",
  "_links": {
    "status_report": {
      "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/full/statusreport"
    },
    "self": {
      "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/full"
    },
    "up": {
      "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A"
    }
  },
  "state_withholding_amount": "0.00",
  "effective_date": "07/14/2020",
  "_options": {
    "links": [
      {
        "schema": {
          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:PolicyFullWithdrawalAnnuity",
          "type": "object",
          "properties": {
            "payee_zip": {
              "type": "string"
            },
            "account_number": {
              "type": "string"
            },
            "account_type": {
              "type": "string"
            },
            "fundValues": {
              "type": "array",
              "items": {
                "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:FundWithdrawlInfo",
                "type": "object",
                "properties": {
                  "fund_value": {
                    "type": "number"
                  },
                  "surrender_percent": {
                    "type": "any"
                  },
                  "fund_number": {
                    "type": "integer"
                  },
                  "surrender_amt": {
                    "type": "any"
                  },
                  "fund_name": {
                    "type": "string"
                  }
                }
              }
            },
            "payee_country": {
              "type": "string"
            },
            "payee_firstname": {
              "type": "string"
            },
            "_options": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
              "type": "object",
              "properties": {
                "links": {
                  "type": "array",
                  "items": {
                    "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
                    "type": "object",
                    "properties": {
                      "schema": {
                        "type": "object",
                        "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                      },
                      "targetSchema": {
                        "additionalProperties": {
                          "type": "string"
                        },
                        "type": "object"
                      },
                      "method": {
                        "type": "string"
                      },
                      "methods": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "rel": {
                        "type": "string"
                      },
                      "mediaType": {
                        "type": "string"
                      },
                      "href": {
                        "type": "string"
                      },
                      "title": {
                        "type": "string"
                      },
                      "required": {
                        "type": "array",
                        "items": {
                          "type": "string"
                        }
                      },
                      "example": {
                        "type": "string"
                      }
                    }
                  }
                },
                "properties": {
                  "additionalProperties": {
                    "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
                    "type": "object",
                    "properties": {
                      "disallow": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "$schema": {
                        "type": "string"
                      },
                      "readonly": {
                        "type": "boolean"
                      },
                      "extends": {
                        "type": "array",
                        "items": {
                          "type": "object",
                          "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                        }
                      },
                      "description": {
                        "type": "string"
                      },
                      "id": {
                        "type": "string"
                      },
                      "$ref": {
                        "type": "string"
                      },
                      "required": {
                        "type": "boolean"
                      }
                    }
                  },
                  "type": "object"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                }
              }
            },
            "eft_id": {
              "type": "integer"
            },
            "payee_address1": {
              "type": "string"
            },
            "payee_address2": {
              "type": "string"
            },
            "policy_number": {
              "type": "string"
            },
            "_embedded": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
              "type": "object",
              "properties": {
                "status_report": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
                  "type": "object",
                  "properties": {
                    "_links": {
                      "additionalProperties": {
                        "type": "any"
                      },
                      "type": "object"
                    },
                    "messages": {
                      "type": "array",
                      "items": {
                        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                        "type": "object",
                        "properties": {
                          "severity": {
                            "type": "string"
                          },
                          "message": {
                            "type": "string"
                          }
                        }
                      }
                    },
                    "consistent": {
                      "type": "boolean"
                    }
                  }
                }
              }
            },
            "payee_city": {
              "type": "string"
            },
            "payee_state": {
              "type": "string"
            },
            "passed_enhanced_security": {
              "type": "string"
            },
            "effective_date": {
              "format": "date",
              "type": "string"
            },
            "bank_name": {
              "type": "string"
            },
            "links": {
              "type": "array",
              "items": {
                "id": "urn:jsonschema:org:springframework:hateoas:Link",
                "type": "object",
                "properties": {
                  "hreflang": {
                    "type": "string"
                  },
                  "rel": {
                    "type": "string"
                  },
                  "href": {
                    "type": "string"
                  },
                  "media": {
                    "type": "string"
                  },
                  "title": {
                    "type": "string"
                  },
                  "type": {
                    "type": "string"
                  },
                  "deprecation": {
                    "type": "string"
                  }
                }
              }
            },
            "federal_withholding_override": {
              "type": "string"
            },
            "state_withholding_override": {
              "type": "string"
            },
            "federal_withholding_percentage": {
              "type": "number"
            },
            "payment_method": {
              "type": "string"
            },
            "state_withholding_percentage": {
              "type": "number"
            },
            "state_withholding_amount": {
              "format": "currency",
              "type": "number"
            },
            "distribution_code": {
              "type": "string"
            },
            "federal_withholding_amount": {
              "format": "currency",
              "type": "number"
            },
            "payee_lastname": {
              "type": "string"
            },
            "quote_effective_date": {
              "format": "date",
              "type": "string"
            },
            "transit_number": {
              "type": "string"
            }
          }
        },
        "method": "GET",
        "rel": "fetch",
        "href": "http://localhost:8090/wma-rest/contracts/b6ly-cW1H-RwSCBUwgf8SPkSOPzGg5SLIc6eUAaWb9A/surrender/full",
        "title": "fetch policy surrender"
      }
    ],
    "properties": {
      "payee_zip": {
        "type": "string"
      },
      "account_number": {
        "type": "string"
      },
      "account_type": {
        "type": "string"
      },
      "fundValues": {
        "type": "array",
        "items": {
          "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:FundWithdrawlInfo",
          "type": "object",
          "properties": {
            "fund_value": {
              "type": "number"
            },
            "surrender_percent": {
              "type": "any"
            },
            "fund_number": {
              "type": "integer"
            },
            "surrender_amt": {
              "type": "any"
            },
            "fund_name": {
              "type": "string"
            }
          }
        }
      },
      "payee_country": {
        "type": "string"
      },
      "payee_firstname": {
        "type": "string"
      },
      "_options": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:Options",
        "type": "object",
        "properties": {
          "links": {
            "type": "array",
            "items": {
              "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:hal:model:OptionLink",
              "type": "object",
              "properties": {
                "schema": {
                  "type": "object",
                  "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                },
                "targetSchema": {
                  "additionalProperties": {
                    "type": "string"
                  },
                  "type": "object"
                },
                "method": {
                  "type": "string"
                },
                "methods": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "rel": {
                  "type": "string"
                },
                "mediaType": {
                  "type": "string"
                },
                "href": {
                  "type": "string"
                },
                "title": {
                  "type": "string"
                },
                "required": {
                  "type": "array",
                  "items": {
                    "type": "string"
                  }
                },
                "example": {
                  "type": "string"
                }
              }
            }
          },
          "properties": {
            "additionalProperties": {
              "id": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema",
              "type": "object",
              "properties": {
                "disallow": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "$schema": {
                  "type": "string"
                },
                "readonly": {
                  "type": "boolean"
                },
                "extends": {
                  "type": "array",
                  "items": {
                    "type": "object",
                    "$ref": "urn:jsonschema:com:fasterxml:jackson:module:jsonSchema:JsonSchema"
                  }
                },
                "description": {
                  "type": "string"
                },
                "id": {
                  "type": "string"
                },
                "$ref": {
                  "type": "string"
                },
                "required": {
                  "type": "boolean"
                }
              }
            },
            "type": "object"
          },
          "required": {
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      },
      "eft_id": {
        "type": "integer"
      },
      "payee_address1": {
        "type": "string"
      },
      "payee_address2": {
        "type": "string"
      },
      "policy_number": {
        "type": "string"
      },
      "_embedded": {
        "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:Embedded",
        "type": "object",
        "properties": {
          "status_report": {
            "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:StatusReport",
            "type": "object",
            "properties": {
              "_links": {
                "additionalProperties": {
                  "type": "any"
                },
                "type": "object"
              },
              "messages": {
                "type": "array",
                "items": {
                  "id": "urn:jsonschema:com:csc:fsg:life:wma:rest:model:EmbeddedMessages",
                  "type": "object",
                  "properties": {
                    "severity": {
                      "type": "string"
                    },
                    "message": {
                      "type": "string"
                    }
                  }
                }
              },
              "consistent": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "payee_city": {
        "type": "string"
      },
      "payee_state": {
        "type": "string"
      },
      "passed_enhanced_security": {
        "type": "string"
      },
      "effective_date": {
        "format": "date",
        "type": "string"
      },
      "bank_name": {
        "type": "string"
      },
      "links": {
        "type": "array",
        "items": {
          "id": "urn:jsonschema:org:springframework:hateoas:Link",
          "type": "object",
          "properties": {
            "hreflang": {
              "type": "string"
            },
            "rel": {
              "type": "string"
            },
            "href": {
              "type": "string"
            },
            "media": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "type": {
              "type": "string"
            },
            "deprecation": {
              "type": "string"
            }
          }
        }
      },
      "federal_withholding_override": {
        "type": "string"
      },
      "state_withholding_override": {
        "type": "string"
      },
      "federal_withholding_percentage": {
        "type": "number"
      },
      "payment_method": {
        "type": "string"
      },
      "state_withholding_percentage": {
        "type": "number"
      },
      "state_withholding_amount": {
        "format": "currency",
        "type": "number"
      },
      "distribution_code": {
        "type": "string"
      },
      "federal_withholding_amount": {
        "format": "currency",
        "type": "number"
      },
      "payee_lastname": {
        "type": "string"
      },
      "quote_effective_date": {
        "format": "date",
        "type": "string"
      },
      "transit_number": {
        "type": "string"
      }
    },
    "required": [
      "effective_date",
      "passed_enhanced_security"
    ]
  },
  "eft_id": 0,
  "state_withholding_override": "Y"
}

export const systematicWithdrawlMockResponse = {
  "effective_date":"",
  "start_date":"",
  "effective_date_check":false,
  "start_date_check":false,
  "end_date_check":false, 
  "option":"",
  "frequency":"",
  "withdrawal_amount":"",
  "transaction_level":"",
  "payout_percentage":"",
  "federal_withholding_override":false,
  "federal_withholding_percentage":"",
  "state_withholding_override":false,
  "state_withholding_percent":"",
  "gross_distribution":""
}

export const systematicWithdralFormModal = [
  {
    id: 'effective_date',
    type: "date",
    label: 'Effective Date',
    value: '',
    disabled: false,
    isCallNoteField: true,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'next_date',
    type: "date",
    label: 'Start/Next Date',
    value: '',
    disabled: false,
    isCallNoteField: true,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'passed_enhanced_security',
    type: "checkbox",
    label: 'Caller Passed Enhanced Security',
    value: '',
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'option',
    type: "select",
    label: 'Option',
    value: '',
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-4',
    values: [{
      "label":"option1",
      "value":"option1"
    },
    {
      "label":"Option2",
      "value":"Option2"
    }]
  },
  {
    id: 'frequency',
    type: "select",
    label: 'Frequency',
    value: '',
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-4',
    values: []    
  },
  {
    id: 'end_date',
    type: "date",
    label: 'End Date',
    value: '',
    disabled: false,
    isCallNoteField: true,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'withdrawal_amount',
    type: "text",
    label: 'Withdrawal Amount',
    value: '',
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'transaction_level',
    type: "radio",
    label: 'Transaction Level',
    value: '',
    disabled: false,
    isCallNoteField: false,    
    required:true,
    widthClass:'col-md-4',
    values: [{
      "label":"Prorata",
      "value":"P"
    },
    {
      "label":"Fund Specific",
      "value":"F"
    }]
  },
  {
    id: 'payout_percent',
    type: "text",
    label: 'Payout Percentage',
    value: '',
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-4'
  },
  {
    id: 'fundValues',
    type: 'custom',
    label: '',
    value: '',
    values:[
      {
        'surrender_unit':'',
        'fund_value': '',
        'surrender_percent': '',
        'fund_number': '',
        'surrender_amt': '',
        'fund_name': ''
      }
    ],
    disabled: false,
    isCallNoteField: false,
    required:false,
    widthClass:'col-md-12'
  },
  {
    id: 'no_federal_withholding',
    type: "checkbox",
    label: 'No Federal Withholding',
    value: 'N',
    disabled: false,
    isCallNoteField: false,
    required:false,
    widthClass:'col-md-4'
  },
 {
    id: 'federal_withholding_override',
    type: "checkbox",
    label: 'Federal Withholding Override',
    value: 'N',
    disabled: false,
    isCallNoteField: false,
    required:false,
    widthClass:'col-md-4'
  },
   {
      id: 'federal_withholding_percentage',
      type: "text",
      label: 'Federal Withholding Percentage',
      value: '',
      disabled: false,
      isCallNoteField: false,
      required:false,
      widthClass:'col-md-4'
    },
    {
      id: 'no_state_withholding',
      type: "checkbox",
      label: 'No State Withholding',
      value: 'N',
      disabled: false,
      isCallNoteField: false,
      required:false,
      widthClass:'col-md-4'
    },
    {
       id: 'state_withholding_override',
       type: "checkbox",
       label: 'State Withholding Override',
       value: 'N',
       disabled: false,
       isCallNoteField: false,
       required:false,
       widthClass:'col-md-4'
     },
    {
       id: 'state_withholding_percentage',
       type: "text",
       label: 'State Withholding Percentage',
       value: '',
       disabled: false,
       isCallNoteField: false,
       required:false,
       widthClass:'col-md-4'
     },
  {
    id: 'net_gross_indicator',
    type: "radio",
    label: 'Distribution',
    value: '',
    values: [{
      "label":"Gross",
      "value":"G"
    }],
    disabled: false,
    isCallNoteField: false,
    required:true,
    widthClass:'col-md-12'
  }
]
