import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ResourceService, APICallerService } from '@diaas/ux-web';
import { CommonService } from '../services/common.service';
import { OcInfraModule } from '@diaas/ux-web';

export class CustomFileUploader extends FileUploader {

    public constructor(options: FileUploaderOptions) {
        super(options);
    }

    protected _xhrTransport(item: FileItem): any {
        const that = this;
        const xhr = item._xhr = new XMLHttpRequest();
        let sendable: any;
        this._onBeforeUploadItem(item);

        if (typeof item._file.size !== 'number') {
            throw new TypeError('The file specified is no longer valid');
        }
        if (!this.options.disableMultipart) {
            sendable = this.uploadFile();
            this._onBuildItemForm(item, sendable);

            const appendFile = () => sendable.append(item.alias, item._file, item.file.name);
            if (!this.options.parametersBeforeFiles) {
                appendFile();
            }

            // For AWS, Additional Parameters must come BEFORE Files
            if (this.options.additionalParameter !== undefined) {
                Object.keys(this.options.additionalParameter).forEach((key: string) => {
                    let paramVal = this.options.additionalParameter[key];
                    // Allow an additional parameter to include the filename
                    if (typeof paramVal === 'string' && paramVal.indexOf('{{file_name}}') >= 0) {
                        paramVal = paramVal.replace('{{file_name}}', item.file.name);
                    }
                    sendable.append(key, paramVal);
                });
            }
            if (this.options.parametersBeforeFiles) {
                appendFile();
            }
        } else {
            sendable = this.options.formatDataFunction(item);
        }

        xhr.upload.onprogress = (event: any) => {
            const progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
            this._onProgressItem(item, progress);
        };
        xhr.onload = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());
            const response = this._transformResponse(xhr.response, headers);
            const gist = this._isSuccessCode(xhr.status) ? 'Success' : 'Error';
            const method = '_on' + gist + 'Item';
            (this as any)[method](item, response, xhr.status, headers);

            this.additionalCalls(item, response);
            this._onCompleteItem(item, response, xhr.status, headers);
        };
        xhr.onerror = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());
            const response = this._transformResponse(xhr.response, headers);
            this._onErrorItem(item, response, xhr.status, headers);
            this._onCompleteItem(item, response, xhr.status, headers);
        };
        xhr.onabort = () => {
            const headers = this._parseHeaders(xhr.getAllResponseHeaders());
            const response = this._transformResponse(xhr.response, headers);
            this._onCancelItem(item, response, xhr.status, headers);
            this._onCompleteItem(item, response, xhr.status, headers);
        };
        xhr.open(item.method, item.url);
        // xhr.withCredentials = item.withCredentials;
        if (this.options.headers) {
            for (const header of this.options.headers) {
                xhr.setRequestHeader(header.name, header.value);
            }
        }
        if (item.headers.length) {
            for (const header of item.headers) {
                xhr.setRequestHeader(header.name, header.value);
            }
        }

        if (this.authToken) {
            xhr.setRequestHeader(this.authTokenHeader, this.authToken);
        }
        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                that.response.emit(xhr.responseText);
            }
        };
        if (this.options.formatDataFunctionIsAsync) {
            sendable.then(
                (result: any) => xhr.send(JSON.stringify(result))
            );
        } else {
            xhr.send(sendable);
        }
        this._render();
    }

    private additionalCalls(item: FileItem, response: string) {
        const alias = OcInfraModule.AppInjector.get(ResourceService).getAliasByAliasName('selectedWorkRequestSource');
        const optionParams: Object = {
            'alias' : "selectedWorkRequestSource",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequestSource")
        } 
        OcInfraModule.AppInjector.get(APICallerService).refresh(alias.href, optionParams).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }


    private uploadFile() {
        const resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('selectedWorkRequestSource');
        if (resource) {
            const myForm = new FormData();
            return myForm;
        }
    }
}