import { Component, OnInit, EventEmitter, Output, ElementRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';
import { ResourceService, OcInfraModule, APICallerService, OptionParams } from '@diaas/ux-web';

@Component({
  selector: 'fund-table',
  templateUrl: './fundTable.component.html',
})

export class FundTableComponent implements OnInit {

  displayedColumns: string[] = ['name','fund_type_desc','allocation_percent','interest_rate','fund_value'];
  selectedDate: Object = {};
  fundList:  any[] = [];
  noRecords: boolean = true;
  statusMsg: string="";
  message: boolean = false;
  isAnnuity: boolean = false;
  fundsObj: any = {};
  olsPolicyfund: boolean = false;
  csrPolicyfund: boolean = false;
  isOlsAnnuity: boolean = false;

  constructor(public elementRef: ElementRef) {

  }

  ngOnInit() {
    this.isOlsAnnuity =OcInfraModule.AppInjector.get(CommonService).isOlsAnnuity;
    var loanHistoryURL = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (loanHistoryURL["funds"] && loanHistoryURL["funds"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("partyRoles");
      OcInfraModule.AppInjector.get(APICallerService).get(loanHistoryURL["funds"].href,optionParams).subscribe(response => {
        let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
        if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
            this.isAnnuity = true;
        }
        console.log("funds response", response.response._links.item);
        this.fundList = response.response._links.item;
        this.noRecords = this.fundList && !this.fundList.length;
        if (response.response._embedded && response.response._embedded.status_report.messages) {
          response.response._embedded.status_report.messages.forEach(msgObj => {
            this.statusMsg += msgObj.message;
            this.message = true;
          });
        }
      });
    }
    this.olsPolicyfund = this.elementRef.nativeElement.id == 'olsFundTable'?true:false;
    this.csrPolicyfund = this.elementRef.nativeElement.id == 'fundTable'?true:false;

  } 

  testChange(event, data, selectedValue, selectedKey, columnName,valueType){
    let apiName = 'funds';
    let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
    selectedValue= valueType=='currency'? '$' + selectedValue.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : selectedValue;

    let selectedVal:any;
    if(this.selectedDate[data.number] && columnName !== 'name'){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        event.checked ? this.selectedDate[data.number].push(selectedKey+':'+ selectedVal) : this.selectedDate[data.number].splice(this.selectedDate[data.number].indexOf(selectedKey+':'+ selectedVal), 1)    
    }else{
      if(!this.selectedDate[data.number]){
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.selectedDate[data.number] = new Array(selectedKey+':'+ selectedVal);
      }
    }
    if(this.selectedDate[data.number].length == 1 && this.selectedDate[data.number][0].indexOf('Name') !== -1 && !event.checked){
     delete this.selectedDate[data.number];
    } else {
      if (this.selectedDate[data.number][0].indexOf('Name') === -1) {
        this.selectedDate[data.number].unshift("Name:" + data.name);
      }
    }

  let html =  '';
  if( this.selectedDate[data.number]){
  for (var i = 0; i < this.selectedDate[data.number].length; i++) {
    var split = this.selectedDate[data.number][i].split(':');
    this.fundsObj[split[0].trim()] = split[1].trim();
  }
}

  if(Object.keys(this.selectedDate).length > 0){
    html +=  '<dl class="pad-lAr-10px">';
    Object.keys(this.selectedDate).forEach(key => {
      this.selectedDate[key].forEach(element => {
        if (currencyFields.includes(element.split(":")[0])) {
          html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;"  >&nbsp;'+ OcInfraModule.AppInjector.get(CommonService).convertToCurrency(element.split(":")[1]) + '</dd>';
        } else {
          html += '<dt class="pull-left">' +element.split(":")[0]  + ':</dt><dd style = "font-weight: 200;"  >&nbsp;'+element.split(":")[1] + '</dd>';
        }
      });
    });
    html += '</dl>';
  }


    localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
    OcInfraModule.AppInjector.get(CommonService).fundHtml = html;
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    let finalHtml = OcInfraModule.AppInjector.get(CommonService).getFinalHtml();
    let requestedDetailSectionBilling = document.getElementsByClassName("requested_details_section_billing")[0];
    requestedDetailSectionBilling.innerHTML = finalHtml;
  }

  checkedMesseage(event, selectedValue, selectedKey) {
    let html = '';
    if (event.checked) {
      this.selectedDate["annuity"] = new Array(selectedKey + ':' + selectedValue);
      localStorage.setItem('fundInfo', JSON.stringify(this.selectedDate));
      html = '<dl class=""> <dt class="pull-left">' + selectedKey + ':</dt><dd style="font-weight: 200;">&nbsp;' + selectedValue + '</dd>' + '</dl>';
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = html;
    } else {
      localStorage.removeItem('fundInfo');
      OcInfraModule.AppInjector.get(CommonService).fundMessageHtml = '';
    }
    
    OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
}

}