import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonFactory } from './commonFactory';
import { CommonService } from '../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

export class PolicyDetailsFactory extends CommonFactory {

    public commonService = OcInfraModule.AppInjector.get(CommonService);

    isNotAnnuity() {
        return !this.isAnnuity();
    }

    isAnnuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A')) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    isPolicyAvailable() {
        if (this.commonService.getAllUrlOfPolicyDetails()) {
            return true;
        }
    }

    getValueByClassName = (className) => {
        return document.querySelector('.' + className)['value']
    }

    setValueByClassName = (className, value) => {
        return document.querySelector('.' + className)['value'] = value;
    }

    sendFaxWorkrequest(params) {
        let resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let selectedWorkRequest = resourceService.getResourceFromAliasName('selectedWorkRequest');
        const faxUrl = selectedWorkRequest._links.fax.href;
        const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
        let headers = this.commonService.getHeaderByAliasName("selectedWorkRequest");
        headers.faxType = "source";
        let faxPayload = {
            "business_area": this.commonService.businessArea,
            "policy_number": policyDetails.data.policy_number,
            "company": policyDetails.data.statutory_company_code,
            "region": this.commonService.productInfo.REGION,
            "fax_utility_client": "CSR_Portal",
            "agent_number": "",
            "domain_user": "",
            "awd_user": this.commonService.awdUserId,
            "awdobjectid": selectedWorkRequest.id,
            "subject": this.getValueByClassName('fax-form-subject'),
            "comment": this.getValueByClassName('fax-form-comments'),
            "sources": {
                "source": this.commonService.selectedSourceForFax
            },
            "recipient": {
                "fax": this.getValueByClassName('fax-form-fax'),
                "first-name": this.getValueByClassName('fax-form-first-name'),
                "last-name": this.getValueByClassName('fax-form-last-name'),
            },
            "coversheet": {
                "type": "server",
                "path": "Generic.cse"
            }
        }
        this.commonService.showHideSpinner({ showSpinner: true, edit: false });
        this.commonService.postCallback(faxUrl, faxPayload, headers).subscribe(response => {
            if (response && response.messages[0] && response.messages[0].message) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.messages[0].message, 'Success');
            }
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => { });
            this.commonService.showHideSpinner({ showSpinner: false });
            this.commonService.selectedSourceForFax = [];
            document.querySelector('.source-fax-checkbox .mat-checkbox').classList.remove('mat-checkbox-checked')
            document.querySelector('.source-fax-checkbox .mat-checkbox-input')['checked'] = false;
            this.setValueByClassName('source-fax-checkbox .mat-checkbox-input', 'off');
            this.setValueByClassName('fax-form-subject', '');
            this.setValueByClassName('fax-form-comments', '');
            this.setValueByClassName('fax-form-fax', '');
            this.setValueByClassName('fax-form-first-name', '');
            this.setValueByClassName('fax-form-last-name', '');
        }, error => {
            if (error.messages && error.messages[0].message) {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.messages[0].message, 'Error', 0);
            } else {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
            }
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => { });

            this.commonService.showHideSpinner({ showSpinner: false });
        });
    }

    onCheckSourceFax(event) {
        let resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let workRequestSourceList = resourceService.getResourceFromAliasName('selectedWorkRequestSource');
        if (workRequestSourceList && workRequestSourceList.data) {
            let source = workRequestSourceList.response._links.item.filter((obj) => {
                return event.href === obj.href;
            })
            if (event.value) {
                this.commonService.selectedSourceForFax.push({ "id": source[0].summary.id, "select_pages": "all" });
            } else {
                this.commonService.selectedSourceForFax = this.commonService.selectedSourceForFax.filter((obj) => {
                    return source[0].summary.id !== obj.id
                });
            }
        }
    }

    enableFaxFields() {
        return this.commonService.selectedSourceForFax && this.commonService.selectedSourceForFax.length ? true : false;
    }

    disableSourceFaxButton() {
        let sendFaxButton = document.querySelector('.source-send-fax-button button');
        let sourceCheckWrapper = document.querySelectorAll('#workRequestFaxSourceTable .mat-checkbox')
        let sourceCheckBox = document.querySelectorAll('#workRequestFaxSourceTable .mat-checkbox-input')
        if (sendFaxButton && sourceCheckWrapper && sourceCheckBox ) {
            if (!(this.commonService.authsucess || this.commonService.otherRoleAuthsucess)) {
                sendFaxButton['title'] = 'Caller not authorized';
                Array.from(sourceCheckWrapper).forEach(checkBoxWrapper => {
                    checkBoxWrapper['title'] = 'Caller not authorized';
                    checkBoxWrapper['classList'].add('mat-checkbox-disabled')
                });
                Array.from(sourceCheckBox).forEach(checkBox => { checkBox['disabled'] = true; });
                return false
            }
            else if (this.getValueByClassName('fax-form-fax') && this.getValueByClassName('fax-form-first-name') && this.getValueByClassName('fax-form-last-name')) {
                if (this.commonService.selectedSourceForFax && this.commonService.selectedSourceForFax.length) {
                    sendFaxButton['title'] = '';
                    return true
                }
            } else {
                sendFaxButton['title'] = 'Please fill out required fields';
                return false
            }
        } else {
            return false;
        }

    }

    closeDialog(params) {
        params.modal.dialogRef.close();
    }

    onCheckEscalate(params) {
        OcInfraModule.AppInjector.get(CommonService).escalateWorkRequest = params.value;
    }
}