import { Component, OnInit, Input } from '@angular/core';
import { ResourceService, Configuration, ServerResponse, UpdateMethodResponse, OcInfraModule, APICallerService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import * as _ from 'lodash';

interface Type {
  value: String;
  viewValue: String;
}

interface Level {
  value: String;
  viewValue: String;
}

@Component({
  selector: 'withdrawl-surrender-surrender',
  templateUrl: './surrenderComponent.html',
  styleUrls: ['./surrenderComponent.scss']
})

export class WithdrawlSurrenderSurrender implements OnInit {
  withdrawalEffectiveDatePicker: any;
  withdrawalEffectiveMaxDate: any;
  netGrossIndicator: any;
  withdrawlNetGrossIndicator: any;
  withdrawalTransactionType: any;
  withdrawalTransactionLevel: any;
  federalWithHolding: any;
  noFederalWithHolding: any;
  noStateWithHolding: any;
  disableFederalOverdueAmount: any;
  disableFederalOverduePercent: any;
  disableStateWithHoldingAmount: any;
  disableStateWithHoldingPercent: any;
  surrenderOrRemainingAmount: any;
  surrenderPercent: string;
  withdrawalEffectiveDate: any;
  transactionTypes: Type[] = [];
  transactionLevels: Level[] = [];
  fundTableData: any;
  stateWithHolding: any;
  federalOverrideAmount: string;
  federalOverridePercent: string;
  stateOverrideAmount: string;
  stateOverridePercent: string;
  amountRequested: any;
  requiredFields: any[];
  stateEnabled: any;
  responseOptions: any;
  transactionType: any;
  transactionLevel: any;
  fundValues: any;
  specialConfirm: any;
  passedEnhancedSecurity: any;
  agentRecommend: any;

  @Input() withdrawlSurrenderType: any;
  @Input() policyDetails: Object;
  @Input() surrenderDetails: any;
  @Input() quoteDetails: any;

  ngOnInit() {
    this.resetData();
    this.getSurrenderDetails();
  }

  resetData() {
    this.disableFederalOverdueAmount = true;
    this.disableFederalOverduePercent = true;
    this.disableStateWithHoldingAmount = true;
    this.disableStateWithHoldingPercent = true;
    this.withdrawalEffectiveDatePicker = new Date();
    this.surrenderOrRemainingAmount = "";
  }

  handleCopyFromQuote(event) {
    if (event.checked && this.quoteDetails) {
      this.surrenderOrRemainingAmount = this.quoteDetails.amountRequested;
      this.withdrawlNetGrossIndicator = this.quoteDetails.netGrossIndicator;
      this.withdrawalTransactionType = "Amount";
    }
  }

  handleHoldings(type) {
    if (type == 'federalHoldings') {
      if (this.federalWithHolding) {
        this.disableFederalOverdueAmount = false;
        this.disableFederalOverduePercent = false;
        if (this.stateOverrideAmount || this.stateOverridePercent) {
          this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
          this.disableFederalOverduePercent = this.stateOverridePercent ? false : true;
        }
      } else {
        this.resetFederalHoldings();
      }
    } else {
      if (this.stateWithHolding) {
        this.disableStateWithHoldingAmount = false;
        this.disableStateWithHoldingPercent = false;
        if (this.federalOverrideAmount || this.federalOverridePercent) {
          this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
          this.disableStateWithHoldingPercent = this.federalOverridePercent ? false : true;
        }
      } else {
        this.resetStateWithHoldings();
      }
    }
  }

  resetFederalHoldings() {
    this.disableFederalOverdueAmount = true;
    this.disableFederalOverduePercent = true;
    this.federalOverrideAmount = "";
    this.federalOverridePercent = "";
  }

  resetStateWithHoldings() {
    this.disableStateWithHoldingAmount = true;
    this.disableStateWithHoldingPercent = true;
    this.stateOverrideAmount = "";
    this.stateOverridePercent = "";
  }

  onFederalOverrideChange(type) {
    if (type == 'amount') {
      this.disableFederalOverduePercent = this.federalOverrideAmount ? true : false;
      this.handleChange('federal');
    } else {
      this.disableFederalOverdueAmount = this.federalOverridePercent ? true : false;
      this.handleChange('federal');
    }
  }

  onStateOverrideChange(type) {
    if (type == 'amount') {
      this.disableStateWithHoldingPercent = this.stateOverrideAmount ? true : false;
      this.handleChange('state');
    } else {
      this.disableStateWithHoldingAmount = this.stateOverridePercent ? true : false;
      this.handleChange('state');
    }
  }

  handleChange(type) {
    if (type == 'federal') {
      if (this.stateOverrideAmount || this.stateOverridePercent) {
        this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
        this.disableFederalOverduePercent = this.stateOverridePercent ? false : true;
      } else if (this.stateWithHolding) {
        if (this.federalOverrideAmount || this.federalOverridePercent) {
          this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
          this.disableStateWithHoldingPercent = this.federalOverridePercent ? false : true;
        } else {
          this.disableStateWithHoldingAmount = false;
          this.disableStateWithHoldingPercent = false;
        }
      }
    } else {
      if (this.federalOverrideAmount || this.federalOverridePercent) {
        this.disableStateWithHoldingAmount = this.federalOverrideAmount ? false : true;
        this.disableStateWithHoldingPercent = this.federalOverridePercent ? false : true;
      } else if (this.federalWithHolding) {
        if (this.stateOverrideAmount || this.stateOverridePercent) {
          this.disableFederalOverdueAmount = this.stateOverrideAmount ? false : true;
          this.disableFederalOverduePercent = this.stateOverridePercent ? false : true;
        } else {
          this.disableFederalOverdueAmount = false;
          this.disableFederalOverduePercent = false;
        }
      }
    }
  }
  handleTransactionChange() {
    if(this.withdrawalTransactionLevel == 'Removed Pro Rata basis' ) {
      if(this.withdrawalTransactionType == 'Amount') {
        this.surrenderPercent = this.formatPercent('', 'blur', this.surrenderDetails.surrender_percent);
      } else {
        this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount);
      }
    } else {
      this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', this.surrenderDetails.surrender_amount);
      this.surrenderPercent = this.formatPercent('', 'blur', this.surrenderDetails.surrender_percent);
    }
  }

  /** AMOUNT AND PERCENT FORMATING  */
  formatNumber(n, type) {
    if (type == 'currency') {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return n.replace(/\D/g, "");
    }
  }

  formatCurrency(input, blur, respValue) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    }
    if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      decimal_pos = decimal_pos > 15 ? 15 : decimal_pos;
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'currency');
      right_side = this.formatNumber(right_side, 'currency');
      if (blur === "blur") {
        right_side += "00";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);
      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = input_val.substring(0, 15);
      input_val = this.formatNumber(input_val, 'currency');
      input_val = "$" + input_val;
      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }
  }

  formatPercent(input, blur, respValue) {
    var input_val = "";
    if (respValue == '' || respValue == undefined) {
      input_val = input.target.value;
    } else {
      input_val = respValue;
    } if (input_val === "") { return; }
    var original_len = input_val.length;
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
      left_side = this.formatNumber(left_side, 'percent');
      right_side = this.formatNumber(right_side, 'percent');
      if (blur === "blur") {
        right_side += "0";
      }
      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 1);
      // join number by .
      input_val = left_side + "." + right_side + "%";
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val, 'percent');
      if (blur === "blur") {
        input_val += ".0";
      }
      input_val = input_val + "%";
    }
    if (respValue == '' || respValue == undefined) {
      input.target["value"] = input_val;
    } else {
      return input_val;
    }
  }

  inputChange(input, i, isAmount) {
    var input_val = "";
    input_val = input.target.value.replace(/,|\s/g, "").replace("%", "").replace("$", "");
    if(i == 'surrenderPercent') {
     this.surrenderPercent = this.formatPercent('','blur',input_val);
    } else if(i == 'surrenderAmount') {
      this.surrenderOrRemainingAmount = this.formatCurrency('','blur',input_val);
    } else if(i == 'federalAmount') {
      this.federalOverrideAmount = this.formatCurrency('','blur',input_val);
    } else if(i == 'federalPercent') {
      this.federalOverridePercent = this.formatPercent('','blur',input_val);
    } else if(i == 'stateAmount') {
      this.stateOverrideAmount = this.formatCurrency('','blur',input_val);
    } else if(i == 'statePercent') {
      this.stateOverridePercent = this.formatPercent('','blur',input_val);
    } else {
      isAmount ? this.fundTableData[i].surrender_amt = this.formatCurrency('','blur',input_val) : this.fundTableData[i].surrender_percent =  this.formatPercent('','blur',input_val);
    }
  }

  getSurrenderDetails() {
    let response = this.surrenderDetails;
    this.responseOptions = response._options;
    this.requiredFields = response._options && response._options.required || [];
    this.withdrawalEffectiveDate = response.effective_date ? new Date(response.effective_date) : '';
    this.withdrawalEffectiveMaxDate = this.withdrawalEffectiveDate;
    this.noFederalWithHolding = response.no_federal_withholding == 'Y' ? true : false;
    this.noStateWithHolding = response.no_state_withholding == 'Y' ? true : false;
    if (this.withdrawlSurrenderType == 'PARTIAL_SURRENDER') {
      //mapping fundvalues
      if (response && response.fundValues) {
        this.fundTableData = response.fundValues;
      }

      this.transactionLevels = [];
      if (this.responseOptions.properties.transaction_level && this.responseOptions.properties.transaction_level.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.transaction_level.oneOf.length; i++) {
          let viewValue = this.responseOptions.properties.transaction_level.oneOf[i].title;
          viewValue = viewValue == 'Removed from specified funds' ? 'Fund Specific' : viewValue == 'Removed Pro Rata basis' ? 'Prorata' : viewValue;
          this.transactionLevels.push({ value: this.responseOptions.properties.transaction_level.oneOf[i].title, viewValue: viewValue })
        }
      }

      this.transactionTypes = [];
      if (this.responseOptions.properties.transaction_type && this.responseOptions.properties.transaction_type.oneOf) {
        for (let i = 0; i < this.responseOptions.properties.transaction_type.oneOf.length; i++) {
          this.transactionTypes.push({ value: this.responseOptions.properties.transaction_type.oneOf[i].title, viewValue: this.responseOptions.properties.transaction_type.oneOf[i].title })
        }
      }

      this.surrenderOrRemainingAmount = this.formatCurrency('', 'blur', response.surrender_amount);
      this.surrenderPercent = this.formatPercent('', 'blur', response.surrender_percent);
      this.stateEnabled = response.state_enabled;
      this.specialConfirm = response.special_confirm;
    }
  }

  isStepComplete() {
    return this.checkRequiredFields() == 'Y' ? true : false;
  }

  getSurrenderValues() {
    if (this.responseOptions.properties.transaction_level && this.responseOptions.properties.transaction_level.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.transaction_level.oneOf.length; i++) {
        if(this.responseOptions.properties.transaction_level.oneOf[i].title == this.withdrawalTransactionLevel) {
          this.transactionLevel = Object.assign({title: this.withdrawalTransactionLevel, enum: this.responseOptions.properties.transaction_level.oneOf[i].enum[0]});
       }
      }
    }
    if (this.responseOptions.properties.transaction_type && this.responseOptions.properties.transaction_type.oneOf) {
      for (let i = 0; i < this.responseOptions.properties.transaction_type.oneOf.length; i++) {
        if(this.responseOptions.properties.transaction_type.oneOf[i].title == this.withdrawalTransactionType) {
          this.transactionType = Object.assign({title: this.withdrawalTransactionType, enum: this.responseOptions.properties.transaction_type.oneOf[i].enum[0]});
       }
      }
    }
    this.fundValues = [];
    if (this.fundTableData && this.fundTableData.length) {
      for (let i = 0; i < this.fundTableData.length; i++) {
        if (this.withdrawalTransactionType == "Amount") {
          this.fundValues.push({ fund_number: this.fundTableData[i].fund_number, surrender_amt: this.fundTableData[i].surrender_amt });
        } else {
          this.fundValues.push({ fund_number: this.fundTableData[i].fund_number, surrender_percent: this.fundTableData[i].surrender_percent });
        }
      }
    }

    return Object.assign({
      effective_date: this.surrenderDetails.effective_date,
      transaction_type: this.transactionType,
      transaction_level: this.transactionLevel,
      transaction_level_view_value: this.withdrawlSurrenderType == "PARTIAL_SURRENDER" ? this.getViewValue() : undefined,
      surrender_amount: this.surrenderOrRemainingAmount,
      surrender_percent: this.surrenderPercent,
      federal_withholding_amount: this.federalOverrideAmount ? this.federalOverrideAmount : '$0.00',
      federal_withholding_percentage: this.federalOverridePercent ? this.federalOverridePercent : '0.0%',
      state_withholding_amount: this.stateOverrideAmount ? this.stateOverrideAmount : '$0.00',
      state_withholding_percentage: this.stateOverridePercent ? this.stateOverridePercent : '0.0%',
      net_gross_indicator: this.withdrawlNetGrossIndicator,
      federal_withholding_override: this.federalWithHolding ? 'Y': 'N',
      state_withholding_override: this.stateWithHolding ? 'Y' : 'N',
      fundValues: this.fundValues,
      passed_enhanced_security: this.passedEnhancedSecurity ? 'Y' : 'N',
      agent_recommend_or_authorized: this.agentRecommend ? 'Y' : 'N',
      required_fields: this.checkRequiredFields()
      }, this.quoteDetails);
  }

  getViewValue() {
    return this.transactionLevels.find(obj => obj.value == this.withdrawalTransactionLevel).viewValue.toUpperCase();
  }

  checkRequiredFields(){
    if(this.withdrawlSurrenderType == 'FULL_SURRENDER') {
      return this.withdrawalEffectiveDate && this.passedEnhancedSecurity ? 'Y' :'N';   
    } else {
       if(this.withdrawalEffectiveDate && this.passedEnhancedSecurity && this.withdrawlNetGrossIndicator
         && this.withdrawalTransactionType && this.withdrawalTransactionLevel) {
         if(this.withdrawalTransactionLevel == 'Removed Pro Rata basis' && this.withdrawalTransactionType == 'Amount') {
           return this.surrenderOrRemainingAmount ? 'Y' : 'N';
         } else if(this.withdrawalTransactionLevel == 'Removed Pro Rata basis' && this.withdrawalTransactionType == 'Percent') {
           return this.surrenderPercent ? 'Y' : 'N';
         } else if(this.withdrawalTransactionLevel == 'Removed from specified funds' && this.withdrawalTransactionType == 'Amount') {
           for(let i=0; i < this.fundTableData.length; i++) {
             if(!this.fundTableData[i].surrender_amt){
               return 'N';
             }
           }
         } else if (this.withdrawalTransactionLevel == 'Removed from specified funds' && this.withdrawalTransactionType == 'Percent') {
           for(let i=0; i < this.fundTableData.length; i++) {
             if(!this.fundTableData[i].surrender_percent){
               return 'N';
             }
           }
         }
         return 'Y';
       } 
    }
    return 'N';
   }
}
