import { OcInfraModule, NavigationService, ResourceService, APICallerService } from'@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';

export class CommonFactory {

    public navigateTo(params) {
        OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/' + params.url, params.link, params.alias);
    }

    public getAction(params) {
        OcInfraModule.AppInjector.get(APICallerService).get(params.url, params.optionParams).subscribe(postResponse => {
        });
    }

    public createAction(params) {
        OcInfraModule.AppInjector.get(APICallerService).post(params.url, params.body, params.alias).subscribe(postResponse => {
            console.log('New resource has been added');
        });
    }

    public deleteAction(params) {
        OcInfraModule.AppInjector.get(APICallerService).delete(params.url, params.alias).subscribe(deleteResponse => {
        });
    }

    public refreshAction(params) {
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.url, params.optionParams).subscribe(refreshResponse => {
        });
    }

    public patchAction(params) {
        params.forEach(payloadObj => {
            OcInfraModule.AppInjector.get(APICallerService).patch(payloadObj.url, payloadObj.payload).subscribe(response => {
                if (params.message) {
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(params.message, 'Success');
                }
            });
        });
    }
}
