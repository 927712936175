import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, ResourceService, APICallerService } from '@diaas/ux-web';
import * as _ from 'lodash';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'correspondence-fax',
  templateUrl: './correspondenceFax.component.html',
})

export class CorrespondenceFaxComponent implements OnInit {

  constructor(private commonService: CommonService) { }
  faxNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  subject: string = '';
  comments: string = '';
  authsucess: any = OcInfraModule.AppInjector.get(CommonService).authsucess;
  otherRoleSucess: any = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  disableSendFax: boolean = true;
  hoverMessage: string = '';
  length : string;
  faxClass: boolean = true;
  firstNameClass: boolean = true;
  lastNameClass: boolean = true;
  ngOnInit() {
    this.authsucess || this.otherRoleSucess ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = 'Caller was not authorized';
   setTimeout(() =>{
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
      }, 1000);
  }

  numberOnly(e): boolean {
    {if(e.key == 'Control' || e.key == 'v'){
      e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
      this.faxNumberChange(e);
    }
     else if (e.keyCode > 31 && ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105))) {
       if((e.keyCode > 64 && e.keyCode < 123) && (e.key == 'Control' && e.keyCode != 67)){
        e.target["value"].length > 13 ? '' : e.target["value"] = e.target["value"].substring(0, e.target["value"].length-1);
        this.faxNumberChange(e);
       }
      e.preventDefault();  
      } else {
        e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
        if(e.target["value"].length > 13 ){
          e.target["value"] = e.target["value"].substring(0, e.target["value"].length-1);
          e.preventDefault();    
        }  
        this.faxNumberChange(e);
         e.target["value"].length < 13  ?  this.length = '12' : this.length = '0';
        return true;
      }
    }
  }

  faxNumberChange(e) {
    e.target.value != '' && e.target.value.length == 12 && this.firstName != '' && this.lastName != '' && (this.authsucess || this.otherRoleSucess) ? this.disableSendFax = false : this.disableSendFax = true;
    e.target.value != '' && e.target.value.length == 12 ? this.faxClass = false : this.faxClass = true;
    this.authsucess || this.otherRoleSucess ? this.firstName == '' && this.lastName == '' ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = 'Caller was not authorized';
  }

  firstNameChange(e) {
    let faxNumber = this.faxNumber.indexOf("-") == -1 ? this.faxNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-') : this.faxNumber;
    e.target.value != '' && this.lastName != '' && faxNumber.length == 12 && (this.authsucess || this.otherRoleSucess) ? this.disableSendFax = false : this.disableSendFax = true;
    e.target.value != '' ? this.firstNameClass = false : this.firstNameClass = true;
    this.authsucess || this.otherRoleSucess ? this.firstName == '' && this.lastName == '' ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = 'Caller was not authorized';
  }

  lastNameChange(e) {
    let faxNumber = this.faxNumber.indexOf("-") == -1 ? this.faxNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-') : this.faxNumber ;
    e.target.value != '' && this.firstName != '' && faxNumber.length == 12 && (this.authsucess || this.otherRoleSucess) ? this.disableSendFax = false : this.disableSendFax = true;
    e.target.value != '' ? this.lastNameClass = false : this.lastNameClass = true;
    this.authsucess || this.otherRoleSucess ? this.firstName == '' && this.lastName == '' ? this.hoverMessage = 'Enable button by completing required items' : this.hoverMessage = '' : this.hoverMessage = 'Caller was not authorized';
  }

  sendFax(e) {
    OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles'); 
    let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
    let headers = this.commonService.getHeaderByAliasName("selectedWorkRequest");
    const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
    headers.statuaory_company_code = policyDetails.data.statutory_company_code;
    headers.faxType = "correspondence"; 
    let payload =
    {
      "business_area": this.commonService.businessArea ? this.commonService.businessArea :'',
      "policy_number":  this.otherRoleSucess ? policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber,
      "company": policyDetails.data.statutory_company_code,
      "region": this.commonService.productInfo.REGION,
      "fax_utility_client": "CSR_Portal",
      "agent_number": "",
      "domain_user": "",
      "awd_user": this.commonService.awdUserId,
      "subject": this.subject,
      "comment": this.comments,
      "sources": {
      },
      "documents": OcInfraModule.AppInjector.get(CommonService).correspondenceDoc,
      "recipient": {
        "fax": this.faxNumber.replace(/[^a-zA-Z0-9]/g, ''),
        "first-name": this.firstName,
        "last-name": this.lastName,
        "company": ""
      },
      "coversheet": {
        "type": "server",
        "path": "Generic.cse"
      }
    }
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestDetails._links.fax.href, payload, headers).subscribe(response => {
      if (response && response.messages[0] && response.messages[0].message) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.messages[0].message, 'Success');
        let correspondenceCheckWrapper = document.querySelectorAll('#correspondenceTable .mat-checkbox');
        let correspondenceFaxBtn = document.querySelectorAll('#correspondenceFaxBtnC');
        Array.from(correspondenceCheckWrapper).forEach(corres => {
          corres['classList'].remove('mat-checkbox-checked')
        });
        Array.from(correspondenceFaxBtn).forEach(faxBtn => {
          faxBtn['classList'].add('disabled')
          faxBtn['disabled'] = true;
          faxBtn[' ng-reflect-disabled'] = true;
        });
        document.getElementById("modal__cross").click();
        OcInfraModule.AppInjector.get(CommonService).correspondenceDoc = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        setTimeout(() => {
          OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          });
        }, 5000);
      } else {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        document.getElementById("modal__cross").click();
      }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.messages ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.messages[0].message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
    })
  }
}
