import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../services/common.service';
import { AuthenticationService } from './../../services/authentication.service';
import { OcInfraModule, Configuration, NavigationService, StoreService, APICallerService, IAlias, ResourceService, ResourceDirectoryService } from '@diaas/ux-web';;
import { Router, NavigationEnd } from '@angular/router';
import { AdvGrowlService } from 'primeng-advanced-growl';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public visible: Boolean;
  public pageTitle: string;
  public olsTitle: boolean = false;
  public tittle: any = {
    "/screen/home": "Customer service Portal - Home",
    "/screen/policyDetails": "Customer service Portal - Policy",
    "/screen/agentSearch": " Ols Customer service Portal - search",
    "/screen/agentDetails": "Ols Customer service Portal - Agent",
    "/screen/partySearch": "Customer service Portal - search",
    "/screen/customerDetails": "Ols Customer service Portal - Customer",
    "/screen/olsPolicyDetails": "Ols Customer service Portal - Policy Details"
  }
  public olsHomeTitle :any = "Ols Customer service Portal - Home"
  public text: string;
  public isOlsRoute = false;
  constructor(private commonService: CommonService, private authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    localStorage.clear();

    this.authenticationService.getHeaderProperties
      .subscribe(response => {
        this.setVisble(response)
      });
    this.handleSearchBar();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        OcInfraModule.AppInjector.get(CommonService).currentRoute = event.url;
        this.pageTitle = this.tittle[event.url];
        if(event.url === "/screen/olsPolicyDetails"){
          this.isOlsRoute = true;
        }else{
          this.isOlsRoute = false;
        }
        if (event.url === "/screen/home") {
          OcInfraModule.AppInjector.get(CommonService).olsUser ? this.pageTitle = this.olsHomeTitle : '';
          OcInfraModule.AppInjector.get(CommonService).aSearchValue = '';
          OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        }
      }
    });
    this.versionNumber();
  }

  private versionNumber() {
    this.commonService.get('/assets/images/CHANGELOG.html').subscribe(res => {
      console.log(res);
      var element = document.createElement('html');
      element.innerHTML = res._body;
      const header = element.getElementsByTagName('h2');
      // console.log(header);
      let splitDate = header[0].innerText.split(" ");
      const _date = new Date(splitDate[1].slice(1, -1));
      let dateFormat = (_date.getMonth() + 1) + '/' + _date.getDate() + '/' + _date.getFullYear();
      this.text = splitDate[0] + " (" + dateFormat + ")";
    });
  }


  private setVisble(isVisble) {
    this.visible = isVisble;
  }

  /**
   * Navigate to logout page if existrs
   */
  goToLogout(): void {
    let resource: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
    if(resource){
    const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
    resource.forEach(alias => {
      resourceDirectoryService.deleteElementResourceDirectory(alias.href);
    });
  }
    OcInfraModule.AppInjector.get(StoreService).clearSession();    
    OcInfraModule.AppInjector.get(NavigationService).navigateTo(Configuration.config.logoutPage);
  }

    /**
   * Navigate to search page
   */
  goToHome(): void {
    OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
    OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null,company_code:null,region_code: null});
    OcInfraModule.AppInjector.get(CommonService).myCallHistory();
    OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
  }

  goBack(): void {
   let route = OcInfraModule.AppInjector.get(CommonService).aobVisible ? '/screen/agentDetails' : '/screen/customerDetails';
    this.router.navigate([route]);
  }

  handleSearchBar() {
    document.addEventListener('keypress', function(e){
      if(e.target["classList"].contains("formatPhoneNumber") || e.target["classList"].contains("formatPostalCode")){
        if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
          e.preventDefault();          
        }else{
          if(e.target["classList"].contains("formatPhoneNumber")){
            e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            return true;
          }else if (e.target["classList"].contains("formatPostalCode")){
            e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
            return true;
          }
        }
      }
    }, false);
   

    document.addEventListener('click', function (e) {
      if (e.target["classList"].contains("csr-search-bar-icon")) {
        document.getElementById("btnfilter").click();
      }
      if (!OcInfraModule.AppInjector.get(CommonService).createClaim && e.target["parentElement"]["classList"].contains("create-claim-pop-up") && e.target["id"] == "modal__cross") {
        if (OcInfraModule.AppInjector.get(CommonService).claimAlert) {
          e.stopPropagation();
          let content = 'Are you sure?  You have unsaved changes in the Death Claim Window. Do you wish to Continue?';
          let title = 'Alert';
          let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmationPopup(content, 'No', 'Yes', title, '340px')
          dialogRef.afterClosed().subscribe(dialogResponse => {
            if (dialogResponse) {
              OcInfraModule.AppInjector.get(CommonService).claimAlert = false;
              document.getElementById("modal__cross").click();
            }
          })
        }
      }
      if (e.target["classList"].contains("mat-checkbox-inner-container")) {
        e.stopPropagation();
      }
    }, true);

    document.addEventListener('keydown', function (e) {
      if (e.target["classList"].contains("formatCurrency")) {
        if (e.keyCode == 9 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 46 || e.keyCode == 190) {
          if (e.keyCode == 9) {
            let val = event.target["value"].replace(/,/g, "");
            event.target["value"] = Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          }
        } else if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
          e.preventDefault();
        }
      }
    }, false);

    document.addEventListener('mousemove', function () {
      let billingAmount = document.getElementById("workitems-schema-billing-amount-text") ? document.getElementById("workitems-schema-billing-amount-text")["value"] : undefined;
      if (billingAmount) {
        let val = billingAmount.replace(/,/g, "");
        document.getElementById("workitems-schema-billing-amount-text")["value"] = Number(val).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    }, false);
  }
 
  IsAuthorized() : Boolean{
    return OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
  }

  

}
