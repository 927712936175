import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogConfig {
    title?: string,
    content?: any,
    ok?: string,
    close: string,
    width: string,
}

@Component({
  templateUrl:'./dialog-component.html',
  styleUrls: ['dialog-component.scss'],
 
})
export class DialogComponent {

  get dialog(): DialogConfig {
      return this.data;
  }

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
      public dialogRef: MatDialogRef<DialogComponent>
  ) {}

}
