import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { OcInfraModule, NavigationService, Configuration, APICallerService, StoreService } from '@diaas/ux-web';
import { LoginComponent } from './../login.component/login.component';

@Component({
    'template': '',
    'entryComponents': [LoginComponent]
})
export class LogoutComponent implements OnInit {

    ngOnInit() {
        OcInfraModule.AppInjector.get(AuthenticationService).executeLogout();
    }
    
}
