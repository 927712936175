﻿import { OcInfraConfig } from '@diaas/ux-web';
import { FactoryLoader } from '@diaas/ux-web';
import { CustomFactoryLoader } from './../app/factories/custom-factory.loader';
import { hostURL } from "./host-url";

export function CustomFactoryLoaderFunction() {
  return new CustomFactoryLoader();
}
export function GetCognitoDetails() {
  return {
    userPoolId: 'us-east-1_2rOM6tY4b',
    clientId: '1dldoljj65aqg31bks1p8i57pc'
  }
}
export const ocInfraConfig: OcInfraConfig = {

  hostURL: hostURL,
  debug: true,
  metamodelBaseURL: window.location.origin + '/assets/metamodel/',
  modifiedHeaderTag: 'X-GraphTalk-Modified',
  deletedHeaderTag: 'X-Delete',
  apiDateFormat: 'yyyy-mm-dd',
  dateFormat: 'dd/mm/yyyy',
  apiDateSeparator: '-',
  custom: {
    provide: FactoryLoader,
    useFactory: CustomFactoryLoaderFunction
  },
  headers: {
    defaultHeader: {
      'content-type': 'application/json',
      'accept': 'application/hal+json',
      "accept-language": 'en'
    },
    multiHostUrlHeader: [
      {
        'alias': 'avgCallHandleTime',
        'headers': {
          'accept': 'application/hal+json',
          'user_id': '',
          'skill_id': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'authRes',
        'headers': {
          'incontact_user': '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'reqDetails',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '106',
          'region_code': 'BHF1',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'authorize',
        'headers': {
          'incontact_user': '',
          'region_code': null,
          'role_name': null,
          'product_type': null,
          'manreview': null,
          'user_id': null,
          'remote_user': null,
          'statuaory_company_code': null,
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'closeCall',
        'headers': {
          'remote_user': '',
          'incontact_user': '',
          'region_code': null,
          'role_name': null,
          'product_type': null,
          'manreview': null,
          'statuaory_company_code': null,
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'callBoard',
        'headers': {
          'accept': 'application/hal+json',
          'skill_id': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'roles',
        'headers': {
          'user_role': '',
          "caller_role": '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'user_role': '',
          "caller_role": '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'callHistory',
        'headers': {
          'accept': 'application/hal+json',
          'skill_id': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'types1',
        'headers': {
          'statuaory_company_code': '',
          'region_code': '',
          'role_name': 'owner',
          'remote_user': '',
          'product_type': "",
          'manreview': "false",
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'types',
        'headers': {
          "role_name": '',
          "statuaory_company_code": '',
          "product_type": '',
          'region_code': '',
          'user_role': '',
          "manreview": "false",
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'workitemsSchema',
        'headers': {
          "role_name": '',
          "statuaory_company_code": '',
          'remote_user': '',
          "product_type": '',
          'region_code': '',
          "manreview": "false",
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'createClaim',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'claimList',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'instances',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'workRequestHistory',
        'headers': {
          'accept': 'application/hal+json',
          'wma_user': '',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequest',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequestDetailsHistory',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'addComments',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'statuaory_company_code': '',
          'region_code': '',
          'role': null,
          'product_type': null,
          'manreview': null,
          'role_name': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequestComments',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedWorkRequestSource',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': '',
          'region_code': '',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'payments',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'Authorization': ""
        }
      },
      {
        'alias': 'refreshPolicyDetails',
        'headers': {
          'accept': 'application/hal+json',
          'remote_user': '',
          'role': null,
          'region_code': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'userProfile',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'person',
        'headers': {
          'accept': 'application/hal+json',
          'role': null,
          'remote_user': null,
          'region_code': null,
          'product_type': null,
          'manreview': null,
          'role_name': null,
          'incontact_user': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedCallHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'transactions',
        'headers': {
          'remote_user': '',
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'callHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'policyCallHistory',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedRoles',
        'headers': {
          'accept': 'application/hal+json',
          'statuaory_company_code': null,
          'Authorization': ""
        }
      },
      {
        'alias': 'search',
        'headers': {
          'accept': 'application/hal+json',
          'Authorization': ""
        }
      },
      {
        'alias': 'policyDetails',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'billingInfo',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policySuspense',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policyRevolvingError',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policyNotes',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policyCoverage',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'selectedCoverage',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policyFunds',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'loanValues',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'policyValues',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'loanTransactionHistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'annuityListing',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'partyRoles',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'historicalcoi',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'paymenthistory',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'outgoingmoney',
        'headers': {
          'accept': 'application/hal+json',
          "wma_user": "",
          'Authorization': ""
        }
      },
      {
        'alias': 'refreshtoken',
        'headers': {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-AMZ-TARGET': 'AWSCognitoIdentityProviderService.InitiateAuth',
        }
      }
    ]
  },
  typeAheadMinChar: 2,
  printErrors: false,
  defaultLanguage: 'en',
  recaptchaSiteKey: '',
  loginPage: '/',
  logoutPage: '/logout',
  landingPage: 'home',
  dateOptions: {
    dateFormat: 'dd/mm/yyyy',
  }
};