import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { PartySearchFactory } from '../../factories/partySearchFactory';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',

})
export class MessageComponent implements OnInit {

  
  public message: string;
  public type: string;
  public showMessage: boolean;
  public msgArray: any = [];
  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) {
  }
  ngOnInit() {

    this.commonService.showHideBannerAlert
      .subscribe(response => {
        this.showHideMessageEvent(response)
      });
  }



  public showHideMessageEvent(messageEvent) {
    this.message = messageEvent.message;
    this.type = messageEvent.type;
    this.showMessage = messageEvent.visiblity;
    if (!(this.msgArray.indexOf(this.message) > -1)){
    this.msgArray.push({"message":this.message,"type":this.type,"showMessage":this.showMessage});
    }
  }

}
